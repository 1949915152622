import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { ADD_AVAILABLE_VARIANT, ADD_UNAVAILABLE_VARIANT } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from '../../utils/utilFunctions';
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const initialState = {
    productQuantity: "",
    reason: "",
    errors: {
        productQuantity: "",
        reason: "",
    },
    apiErr: "",
}

const UpdateProductInventory = (props) => {
    const { updateInventoryQtyDialog, onClickSubmit, onClickCancel } = props;

    const [dialogState, setDialogState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (updateInventoryQtyDialog.showDialog) {
            setDialogState({
                ...initialState,
                productQuantity: updateInventoryQtyDialog.productQuantity
            });
            inputRef.current.focus();
        }
    }, [updateInventoryQtyDialog.showDialog])

    const inputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    const handleIntegerInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9]/g, '');
        setDialogState({
            ...dialogState,
            [e.target.name]: newValue
        })
    };

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(dialogState.productQuantity)) {
            hasErrors = true;
            errors.productQuantity = "Please enter the quantity"
        }

        if (updateInventoryQtyDialog.isUnavailableDialog && isEmptyVariable(dialogState.reason)) {
            hasErrors = true;
            errors.reason = "Please enter the reason"
        }

        setDialogState({
            ...dialogState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = async () => {
        if (!validateForm()) {
            try {
                if (updateInventoryQtyDialog.isUnavailableDialog) {
                    await UpdateUnavailableQuantity();
                } else {
                    await UpdateAvailableQuantity();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setDialogState({
                    ...dialogState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    setDialogState({
                        ...dialogState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setDialogState({
                    ...dialogState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const UpdateAvailableQuantity = async () => {
        const diff = dialogState.productQuantity - updateInventoryQtyDialog.productQuantity;
        if(diff !== 0){
            await callAuthApi(ADD_AVAILABLE_VARIANT, {
                productOptionValueMapId: updateInventoryQtyDialog.productOptionValueMapId,
                productQuantity: diff
            }, user, logout)
        }else{
            
        }
    }

    const UpdateUnavailableQuantity = async () => {
        const diff = dialogState.productQuantity - updateInventoryQtyDialog.productQuantity;
        if(diff !== 0){
            await callAuthApi(ADD_UNAVAILABLE_VARIANT, {
                productOptionValueMapId: updateInventoryQtyDialog.productOptionValueMapId,
                productQuantity: diff,
                reason: dialogState.reason
            }, user, logout);
        } else {

        }
    }

    return <>
        <CustomModal show={updateInventoryQtyDialog.showDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{updateInventoryQtyDialog.isUnavailableDialog ? "Update Unavailable" : "Update Available"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <label>Product Quantity</label>
                        <input
                            value={dialogState.productQuantity}
                            name="productQuantity"
                            onChange={handleIntegerInputChange}
                            autoFocus
                            ref={inputRef}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productQuantity) &&
                            <span className="err-span m-b-10">{dialogState.errors.productQuantity}</span>
                        }
                    </Col>

                    {
                        updateInventoryQtyDialog.isUnavailableDialog &&
                        <Col md="12">
                            <label>Reason</label>
                            <input
                                value={dialogState.reason}
                                name="reason"
                                onChange={inputChange}
                                onKeyDown={(e) => handleEnterKeyPress(e)}
                            />
                            {
                                !isEmptyVariable(dialogState.errors?.reason) &&
                                <span className="err-span m-b-10">{dialogState.errors.reason}</span>
                            }
                        </Col>
                    }
                </Row>
                {
                    !isEmptyVariable(dialogState.apiErr) &&
                    <span className="err-span m-b-10">{dialogState.apiErr}</span>
                }
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>

    </>
}

export default UpdateProductInventory;