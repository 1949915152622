
const themeColorPrimary = "#333333";
const themeColorPrimaryHover = "#545454";
const logoColorSecondary = "#23236D";

const headingColor = "#282E38";
const paragraphColor = "#282E38";

const linkTextColor = "#282E38";

const inputBorderColor = "#EBEBEB";
const inputBgColor = "#FFFFFF";
const inputTextColor = "#282E38";

const greyColor = "#555555";
const whiteColor = "#FFFFFF";
const blackColor = "#000000";
const commonBorderColor = "#DDDDDD";
const LightGreyCOlor = "#FAFAFA"

const Colors = {
    //Common Colors
    themeColorPrimary: themeColorPrimary,
    themeColorPrimaryHover: themeColorPrimaryHover,
    logoColorSecondary: logoColorSecondary,
    greyColor: greyColor, 
    LightGreyCOlor: LightGreyCOlor, 
    whiteColor: whiteColor, 
    blackColor: blackColor, 

    headingColor: headingColor,
    paragraphColor: paragraphColor,
    linkTextColor: linkTextColor,

    inputBorderColor: inputBorderColor,
    inputBgColor: inputBgColor,
    inputTextColor: inputTextColor,
    inputDisabledBgColor: "#eeeeee",

    buttonBgColor: themeColorPrimary,
    buttonHoverBgColor: themeColorPrimaryHover,
    buttonTextColor: whiteColor,

    buttonIconColor: themeColorPrimary,
    buttonIconHoverBgColor: themeColorPrimary+"18",

    commonBorderColor: commonBorderColor, 

    //MAIN CONTENT COLORS
    MainContentBgColor: "#FFFFFF",

    //MAIN SIDEBAR COLORS
    mainSidebarBorderColor: "#EBEBEB",
    mainSidebarLogoBottomBorderColor: "#EBEBEB",
    mainSidebarIconColor: "#555555",
    mainSidebarTextColor: "#222222",
    notificationBadgeBgColor: "#666666",
    notificationBadgeTextColor: "#FFFFFF",
    accordionHeaderTextColor: "#222222",
    accordionHeaderIconColor: "#555555",
    newFolderButtonBgColor: "#F5F5F5",
    newFolderButtonBgHoverColor: "#eaeaea",
    newFolderButtonTextColor: "#555555",
    folderFirstLetterBgColor: "#555555",

    //ADD/EDIT FORM HEADER
    backButtonIconColor: themeColorPrimary,
    backButtonHoverBg: themeColorPrimary + "11",

    //ALL TABLE COLORS
    tableHeaderColor: "#F8F9FB",
    tableBorderColor: "#EBEBEB",

    profileIconGreyColor: "#d5d6d7",
    topbarDropdownGreyColor: "#f3f3f3",
    bottombarActiveColor: "#39397e",

    //TABLE DROPDOWN
    tableDropdownTopBorder: "#eaeaea",
    tableDropdownDeleteTextColor: "#FF0000",

    //STATUS CHANGE DROPDOWN
    statusDropdownBtnBg: themeColorPrimary,
    statusDropdownBtnBgHover: themeColorPrimary,
    statusDropdownBtnTextColor: "#FFFFFF",
    //DROPDOWN COLORS 
    dropdownBtnBg: "#FFFFFF",
    dropdownBtnBgHover: "#F5F5F5",
    dropdownBtnBorderColor: "#EBEBEB",
    dropdownBtnTextColor: "#222222",

    dropdownBorderColor: "#eeeeee",
    dropdownLogicBorderColor: "#dddddd",
    dropdownBoxShadowColor: "0px 0px 10px 1px #EEEEEE",

    dropdownItemBg: "#FFFFFF",
    dropdownItemBgHover: "#FAFAFA",
    dropdownItemBgSelected: "#F5F5F5",
    dropdownItemTextColor: "#222222",

    //DIALOG DROPDOWNS
    dropdownDialogBtnBg: "#FFFFFF",
    dropdownDialogBtnBgHover: "#F5F5F5",
    dropdownDialogBtnBorderColor: "#D0D0D0",
    dropdownDialogBtnTextColor: "#222222",

    dropdownDialogBorderColor: "#eeeeee",
    dropdownDialogLogicBorderColor: "#dddddd",
    dropdownDialogBoxShadowColor: "0px 0px 10px 1px #EEEEEE",

    dropdownDialogItemBg: "#FFFFFF",
    dropdownDialogItemBgHover: "#FAFAFA",
    dropdownDialogItemBgSelected: "#F5F5F5",
    dropdownDialogItemTextColor: "#222222",

    //TABLE SEARCH 
    searchBackgroundColor: "#FFFFFF",
    searchInputBorderColor: "#EBEBEB",

    //Modal element colors
    modalCancelBtnBgColor: "#CCCCCC",
    modalCancelBtnBgHover: "#DDDDDD",
    modalSubmitBtnBgColor: themeColorPrimary,
    modalSubmitBtnBgHover: themeColorPrimaryHover,
    modalInputBorderColor: "#D0D0D0",

    //Pagination
    paginationSelectedColor: themeColorPrimary,
    paginationBorderColor: "#D0D0D0",
    paginationDisabledColor: "#fafafa",
    paginationHoverColor: themeColorPrimaryHover + "11",
    paginationTextColor: "#333333",

    //Alert Dialog Colors
    alertDialogCancelButtonColor: "#595959",
    alertDialogSubmitButtonColor: "#FF5500",

    //Topbar First Menu Colors
    topbarFirstMenuColor: "#2d2e2e",

    //Topbar Center Menu Colors
    topbarCenterMenuColor: "#2d2e2e",

    //Preview Button Colors
    previewButtonBgColor: "#e6e3e3",
    previewButtonBorderColor: "#aba9a9",
    previewButtonTextColor: "#333",

    //Image Library Colors
    uploadButtonBorder: "2px dashed #aaa",
    uploadButtonBorderHover: "2px dashed #333",
    displayImageTickIconColor: "#fff",
    displayImageTickIconBackgroundColor: "#333",
    displayImageSelectedImageBorder: "2px solid #333",
    displayImageListScrollbarColor: "#f1f1f1",
    displayImageListScrollbarThumbColor: "#888",
    displayImageListScrollbarHoverColor: "#555",
    displayImageOverlayBgColor: "#ffffff88",
    selectedImageDeleteButtonColor: "#FF0000",

    addImageBackgroundColor:"#fafafa",
    addImageBackgroundHoverColor:"#fbfbfb",
    displayImageLayoutBtnBackgroundColor:"#000000aa",
    displayImageLayoutBtnBackgroundHoverColor:"#ffffffaa",
    displayImageLayoutBtnTextColor:"#fff",
    displayImageLayoutBtnHoverTextColor:"#000",
    displayImageLayoutBorder:"1px solid #eeeeee",

    statusSpanActiveBackgroundColor:"#61b33bcc",
    statusSpanActiveTextColor:"#fff",
    statusSpanInActiveBackgroundColor:"#d61a2ddd",
    statusSpanInActiveTextColor:"#fff",
    statusSpanDefaultBackgroundColor:"#f7ce14",
    statusSpanDefaultTextColor:"#fff",

    datePickerCalenderDayColor: paragraphColor,
    datePickerButtonBgColorHover: "#eeeeee",
}

export { Colors };