import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { DELETE_PRODUCT_TYPE, GET_PRODUCT_TYPES, UPDATE_PRODUCT_TYPE_STATUS } from "../../utils/apiEndPoints";
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    DELETE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateType from "./createUpdateProductType";
import { GetReducer } from "./reducer";

const ProductTypeList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [typeState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "producttypes", heading: "Types" })
        getTypeList();
    }, []);

    useEffect(() => {
        if (typeState.refreshFlag) {
            getTypeList();
        }

    }, [typeState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productTypeId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productTypeId, "Active");
                break;
            case "Delete":
                await deleteType(selectedItem.productTypeId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateTypeDialog = () => {
        dispatch({
            type: "SHOW_CREATE_TYPE_DIALOG"
        });
    }

    const onClickTypeDialogCancel = () => {
        dispatch({
            type: "RESET_TYPE_DIALOG"
        });
    }

    const onClickTypeDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_TYPE_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_TYPE_DIALOG",
                    createTypeDialog: {
                        showDialog: true,
                        isEdit: true,
                        typeObj: {
                            productTypeName: item.productTypeName,
                            productTypeId: item.productTypeId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Type",
                        message: "Are you sure you want to deactivate " + item.productTypeName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Type",
                        message: "Are you sure you want to activate " + item.productTypeName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Type",
                        message: "Are you sure you want to delete " + item.productTypeName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (typeState.search != typeState.searchApiKey) {
            dispatch({ type: "RELOAD_TYPE_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, typeState.sort, typeState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getTypeList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: typeState.search,
            sort: typeState.sort,
            sortDir: typeState.sortDir,
            resultSize: typeState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? typeState.pageNo : pageNo
        }

        if (typeState.status !== "All") {
            post.status = typeState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCT_TYPES, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productTypeId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_TYPE_STATUS, {
                productTypeId: productTypeId,
                productTypeStatus: status
            }, user, logout);

            getTypeList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteType = async (productTypeId) => {
        try {
            await callAuthApi(DELETE_PRODUCT_TYPE, {
                productTypeId: productTypeId,
            }, user, logout);

            getTypeList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            typeState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={typeState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search types..."
                            value={typeState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={typeState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateTypeDialog}><BsFillPlusCircleFill />Add Type</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            typeState.firstLoad &&
            <>
                {
                    !isEmptyArray(typeState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Type Name"}
                                    sort={typeState.sort}
                                    sortDir={typeState.sortDir}
                                    tableSortCol={"pt.productTypeName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Products"}
                                    sort={typeState.sort}
                                    sortDir={typeState.sortDir}
                                    tableSortCol={"ptcv.productCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={typeState.sort}
                                    sortDir={typeState.sortDir}
                                    tableSortCol={"pt.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={typeState.sort}
                                    sortDir={typeState.sortDir}
                                    tableSortCol={"pt.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                typeState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.productTypeName}
                                            <StatusSpan
                                                status={item.productTypeStatus}
                                            />
                                        </td>
                                        <td>{item.productCount}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.productTypeStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getTypeList(e.selected + 1)}
                            pageRangeDisplayed={typeState.resultSize}
                            pageCount={getPageCount(typeState.response.count, typeState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={typeState.pageNo - 1}
                            totalRowCount = {typeState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(typeState.response.result) &&
                    !typeState.isPageLoading &&
                    !isEmptyVariable(typeState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(typeState.response.result) &&
                    !typeState.isPageLoading &&
                    isEmptyVariable(typeState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...typeState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        {
            typeState.createTypeDialog?.showDialog &&
            <CreateUpdateType
                productTypeDialog={{
                    ...typeState.createTypeDialog,
                }}
                onClickCancel={onClickTypeDialogCancel}
                onClickSubmit={onClickTypeDialogSubmit}
            />
        }
    </ContentSection>
}

export default ProductTypeList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;