import { useReducer } from 'react';
import { FORM_VALIDATION_MESSAGE, INITIAL_ALERT_STATE } from '../../utils/constants'
import { getCurrentLocalDateAndTimeDateObj, getDateAndTimeObjFromStr, isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';

const INITIAL_ADDEDIT_CUSTOMER_PAGE_STATE = {
    discountTitle: "",
    isCoupon: "Y",
    couponCode: "",
    discountValueType: "Percentage",
    discountPercentage: "",
    discountFixedAmount: "",
    discountTo: "All Products",
    minimumRequirements: "No",
    minimumPurchaseAmount: "",
    allCustomers: "All",
    usageLimit: "Only Once",
    usageCount: "",
    startDateTime: getCurrentLocalDateAndTimeDateObj(),
    endDateTime: "",

    errors: {
        discountTitle: "",
        isCoupon: "",
        couponCode: "",
        discountValueType: "",
        discountPercentage: "",
        discountFixedAmount: "",
        discountTo: "",
        minimumRequirements: "",
        minimumPurchaseAmount: "",
        usageCount: "",
        startDateTime: "",
    },
    apiErr: "",
}

const initialState = {
    ...INITIAL_ADDEDIT_CUSTOMER_PAGE_STATE,

    isPageLoading: false,
    refreshFlag: false,

    showAddEditProductsflag: false,
    discountProducts: {},

    showAddEditProductGroupsflag: false,
    discountProductGroups: {},

    showAddEditCustomersflag: false,
    discountCustomers: {},

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "DISPLAY_PRODUCT_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductsflag: true,
            }
        }
        case "DISPLAY_PRODUCT_GROUP_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductGroupsflag: true,
            }
        }
        case "DISPLAY_CUSTOMER_LIST_DIALOG": {
            return {
                ...state,
                showAddEditCustomersflag: true,
            }
        }
        case "HIDE_PRODUCT_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductsflag: false,
            }
        }
        case "HIDE_PRODUCT_GROUP_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductGroupsflag: false,
            }
        }
        case "HIDE_CUSTOMER_LIST_DIALOG": {
            return {
                ...state,
                showAddEditCustomersflag: false,
            }
        }
        case "SUBMIT_PRODUCT_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductsflag: false,
                discountProducts: {
                    ...action.discountProducts
                }
            }
        }
        case "SUBMIT_PRODUCT_GROUP_LIST_DIALOG": {
            return {
                ...state,
                showAddEditProductGroupsflag: false,
                discountProductGroups: {
                    ...action.discountProductGroups
                }
            }
        }
        case "SUBMIT_CUSTOMER_LIST_DIALOG": {
            return {
                ...state,
                showAddEditCustomersflag: false,
                discountCustomers: {
                    ...action.discountCustomers
                }
            }
        }
        case "RESET_STATE": {
            return {
                ...initialState,
            }
        }
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_DISCOUNT_DETAILS": {
            const { discountDetails } = action;

            let discountProducts = {};
            !isEmptyArray(discountDetails.discountProductList) &&
            discountDetails.discountProductList.forEach(product => {
                discountProducts[product.productUniqueId] = {
                    productUniqueId: product.productUniqueId,
                    productName: product.productName
                }
            });

            let discountProductGroups = {};
            !isEmptyArray(discountDetails.discountProductGroupList) &&
            discountDetails.discountProductGroupList.forEach(productGroup => {
                discountProductGroups[productGroup.productGroupId] = {
                    productGroupId: productGroup.productGroupId,
                    productGroupName: productGroup.productGroupName
                }
            });

            let discountCustomers = {};
            !isEmptyArray(discountDetails.discountCustomerList) &&
            discountDetails.discountCustomerList.forEach(customer => {
                discountCustomers[customer.userId] = {
                    userId: customer.userId,
                    fullName: customer.fullName
                }
            });

            return {
                ...state,
                discountTitle: discountDetails.discountTitle,
                isCoupon: discountDetails.isCoupon,
                couponCode: discountDetails.couponCode,
                discountValueType: discountDetails.discountValueType,
                discountPercentage: discountDetails.discountPercentage,
                discountFixedAmount: discountDetails.discountFixedAmount,
                discountTo: discountDetails.discountTo,
                minimumRequirements: discountDetails.minimumRequirements,
                minimumPurchaseAmount: discountDetails.minimumPurchaseAmount,
                allCustomers: discountDetails.allCustomers === "Y" ? "All" : "Specific",
                usageLimit: discountDetails.usageLimit,
                usageCount: discountDetails.usageCount,
                startDateTime: getDateAndTimeObjFromStr(discountDetails.startDateTime),
                endDateTime: isEmptyVariable(discountDetails.endDateTime)?"":getDateAndTimeObjFromStr(discountDetails.endDateTime),
                discountProducts: discountProducts,
                discountProductGroups: discountProductGroups,
                discountCustomers: discountCustomers,
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "INPUT_CHANGE": {
            const { name, value } = action;
            return {
                ...state,
                [name]: value,
            }
        }
        case "SET_API_ERR": {
            return {
                ...state,
                apiErr: action.errMsg,
            }
        }
        case "SET_ERRORS": {
            return {
                ...state,
                errors: action.errors,
                apiErr: FORM_VALIDATION_MESSAGE
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};