import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { callApi, isEmptyVariable } from '../../utils/utilFunctions';
import { useNavigate, Link } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import GlobalStyle from '../../components/styles/global-styles';
import {Container, Row, Col} from 'react-bootstrap';
import {ForgotPassSection, ForgotPassWrapper, ForgotPassCol} from './forgotpassword.style';
import { FORGOT_PASSWORD } from '../../utils/apiEndPoints';

const INITIAL_STATE = {
    email: "",
    errors: {},
    isLoading: false,
    apiResponse: "",
}

export default function ForgotPassword() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [ state, setState ] = useState({ ...INITIAL_STATE })

    // useEffect(() => {
    //     if (user && user !== null) {
    //         navigate("/dashboard");
    //     }
    // }, []);

    const validate = () => {
        let hasErrors = false;
        let errors = {}

        if (isEmptyVariable(state.email)) {
            hasErrors = true;
            errors.emailErr = "Please enter the email"
        }

        if (!isEmptyVariable(state.email)) {
            var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!reg.test(state.email)) {
                hasErrors = true;
                errors.emailErr = "Please enter the valid email"
            }
        }

        if (hasErrors) {
            setState({
                ...state,
                errors: { ...errors }
            })
        }

        return hasErrors;
    }


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onForgotPasswordSubmit();
        }
    }

    const onForgotPasswordSubmit = async () => {
        if (!validate()) {
            setState({
                ...state,
                isLoading: true,
                apiResponse: "",
            })

            let response = await callApi(FORGOT_PASSWORD, {
                email: state.email,
                password: state.password
            });

            setState({
                ...state,
                isLoading: false
            })

            if (response.data.responseCode === 0) {
                setState({
                    ...state,
                    email:"",
                    apiResponse: response.data.responseMessage
                })
            } else {
                setState({
                    ...state,
                    apiResponse: "",
                    errors: { apiErr: response.data.responseMessage }
                })

                //clear the validation errros
                setTimeout(() => {
                    setState({
                        ...state,
                        errors: {}
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            //clear the validation errros
            setTimeout(() => {
                setState({
                    ...state,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    const onInputChange = (e) => {
        setState({
            ...state,
            email: e.target.value
        })
    }
    return (
        <ForgotPassSection>
            <GlobalStyle/>
            <ForgotPassWrapper>
                <Container>
                    <Row>
                        <Col md={12}>
                            <ForgotPassCol>
                                <h1>Forgot Password</h1>
                                <input
                                    placeholder='Email'
                                    value={state.email}
                                    onChange={e => onInputChange(e)}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                                {
                                    !isEmptyVariable(state.errors.emailErr) &&
                                    <span className="err-span">{state.errors.emailErr}</span>
                                }

                                <button onClick={onForgotPasswordSubmit} style={{ display: "flex" }} disabled={state.isLoading}>
                                    Submit
                                    {
                                        state.isLoading &&
                                        <ThreeDots
                                            height="40"
                                            width="40"
                                            radius="9"
                                            color="#4fa94d"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    }
                                </button>
                                {
                                    !isEmptyVariable(state.errors.apiErr) &&
                                    <span className="err-span">{state.errors.apiErr}</span>
                                }
                                {
                                    !isEmptyVariable(state.apiResponse) &&
                                    <span className="err-span">{state.apiResponse}</span>
                                }
                                <Link to={"/"}>Go to Login</Link>
                            </ForgotPassCol>
                        </Col>
                    </Row>
                </Container>
            </ForgotPassWrapper>
        </ForgotPassSection>
    )
}