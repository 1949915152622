import { useReducer } from 'react';
import { INITIAL_ALERT_STATE } from '../../utils/constants'
import { ifEmptyReturnStr } from '../../utils/utilFunctions';
import moment from 'moment';

const INITIAL_TYPE_STATE = {
    isPageLoading: false,
    firstLoad: false,
    privacyPolicyContent: "",
    termsOfUseContent: "",
    refundPolicyContent: "",

    errors: {

    }
}

const initialState = {
    ...INITIAL_TYPE_STATE,

    isPageLoading: false,
    refreshFlag: false,

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_RESPONSE": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                firstLoad: true,
                refreshFlag: false, // Refresh is done so set it to false
                isPageLoading: false,

                privacyPolicyContent: ifEmptyReturnStr(action.response?.privacyPolicyContent, ""),
                termsOfUseContent: ifEmptyReturnStr(action.response?.termsOfUseContent, ""),
                refundPolicyContent: ifEmptyReturnStr(action.response?.refundPolicyContent, ""),
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "SET_INPUT_VALUE": {
            return {
                ...state,
                [action.name]: action.value,
            }
        }
        case "SET_ERROR": {
            return {
                ...state,
                errors: {
                    [action.policyType]: action.error
                },
            }
        }
        case "RELOAD_LIST": {
            let message = "Policy updated successfully";
            if (action.policyType === "privacyPolicyContent") {
                message = "Privacy Policy updated successfully";
            } else if (action.policyType === "refundPolicyContent") {
                message = "Refund Policy updated successfully";
            } else if (action.policyType === "termsOfUseContent") {
                message = "Terms of use updated successfully";
            }

            return {
                ...state,
                refreshFlag: true,
                errors: {},

                showSnackbar: true,
                snackbarMessage: message,
                timeStamp: moment().valueOf()
            }
        }
        case "CLEAR_SNACKBAR": {
            return {
                ...state,
                refreshFlag: true,
                showSnackbar: false,
                snackbarMessage: ""
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};