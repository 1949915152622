import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { isEmptyVariable } from '../../utils/utilFunctions';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const initialState = {
    pageFieldName: "",
    pageFieldType: "",
    errors: {
        pageFieldName: "",
        pageFieldType: "",
    },
    apiErr: "",
}

const CreateUpdatePageField = (props) => {
    const { addEditPageFieldDialog, onClickSubmit, onClickCancel } = props;
    const [dialogState, setDialogState] = useState(initialState);
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditPageFieldDialog.showaddEditPageFieldDialog) {
            if (addEditPageFieldDialog.isFieldEdit) {
                setDialogState({
                    ...initialState,
                    ...addEditPageFieldDialog.pageFieldObj
                });
            } else {
                setDialogState(initialState);
            }
            inputRef.current.focus();
        }
    }, [addEditPageFieldDialog.showaddEditPageFieldDialog])

    const inputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    const pageFieldTypeDropdownItemClick = (option) => {
        setDialogState({
            ...dialogState,
            pageFieldType: option.pageFieldType
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(dialogState.pageFieldName)) {
            hasErrors = true;
            errors.pageFieldName = "Please enter the field name"
        }

        if (isEmptyVariable(dialogState.pageFieldType)) {
            hasErrors = true;
            errors.pageFieldType = "Please select the field type"
        }

        setDialogState({
            ...dialogState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const onClickSubmitBtn = () => {
        if (!validateForm()) {
            if (addEditPageFieldDialog.isFieldEdit) {
                onClickSubmit({
                    pageContentId: addEditPageFieldDialog.pageFieldObj.pageContentId,
                    pageFieldName: dialogState.pageFieldName,
                    pageFieldType: dialogState.pageFieldType,
                });
            } else {
                onClickSubmit({
                    pageFieldName: dialogState.pageFieldName,
                    pageFieldType: dialogState.pageFieldType,
                });
            }
        } else {
            setTimeout(() => {
                setDialogState({
                    ...dialogState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    const getFieldTypes = () => {
        if (addEditPageFieldDialog.isFieldEdit) {
            if (dialogState.pageFieldType === "Text") {
                return [
                    { pageFieldType: "Text" },
                    { pageFieldType: "Text Area" },
                    { pageFieldType: "Rich Text" },
                ]
            } else if (dialogState.pageFieldType === "Text Area") {
                return [
                    { pageFieldType: "Text Area" },
                    { pageFieldType: "Rich Text" },
                ]
            } else if (dialogState.pageFieldType === "Rich Text") {
                return [
                    { pageFieldType: "Rich Text" },
                ]
            } else if (dialogState.pageFieldType === "Image") {
                return [
                    { pageFieldType: "Image" },
                ]
            } else {
                return []
            }
        } else {
            return [
                { pageFieldType: "Text" },
                { pageFieldType: "Text Area" },
                { pageFieldType: "Rich Text" },
                { pageFieldType: "Image" },
            ]
        }
    }

    return <>
        <CustomModal show={addEditPageFieldDialog.showaddEditPageFieldDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{addEditPageFieldDialog.isFieldEdit ? "Update Field" : "Create Field"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <label>Field Name*</label>
                        <input
                            value={dialogState.pageFieldName}
                            name="pageFieldName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.pageFieldName) &&
                            <span className="err-span m-b-10">{dialogState.errors.pageFieldName}</span>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <label>Field Type*</label>
                        <BasicDropdown
                            placeholder={"Select Field Type"}
                            selectedValue={dialogState.pageFieldType}
                            label="pageFieldType"
                            options={getFieldTypes()}
                            dropdownItemClick={pageFieldTypeDropdownItemClick}
                            wrapperStyles={{ marginBottom: 15 }}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.pageFieldType) &&
                            <span className="err-span m-b-10">{dialogState.errors.pageFieldType}</span>
                        }
                    </Col>
                </Row>
                {
                    !isEmptyVariable(dialogState.apiErr) &&
                    <span className="err-span m-b-10">{dialogState.apiErr}</span>
                }
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>

    </>
}

export default CreateUpdatePageField;