import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { BiSolidOffer } from "react-icons/bi";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { PiPackageFill } from "react-icons/pi";
import { RiPagesFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { ATTACHMENT_PATH } from '../../utils/apiEndPoints';
import { isEmptyVariable } from '../../utils/utilFunctions';
import {
    AccordionBodyLayout,
    AccordionWrapper,
    DashBoardMenuBox,
    LogoBox, SidebarSection,
    SidebarWrap
} from "./sidebar.style";

const Sidebar = (props) => {
    const { selection } = props;
    const { user } = useAuth();

    const [activeKeyObj, setActiveKey] = useState({
        productActiveKey: "0",
        contentActiveKey: "",
        settingsActiveKey: "",
    })

    useEffect(() => {
        if (["products", "collections", "inventory", "reviews", "vendors", "producttypes", "categories"].includes(selection)) {
            setProductActiveKey("0");
        } else if (["pages", "blogs"].includes(selection)) {
            setContentActiveKey("1");
        } else if (["users", "policies", "customfields", "tags", "customerapis", "merchantdetails"].includes(selection)) {
            setSettingsActiveKey("2");
        } else {
            setProductActiveKey("")
        }
    }, [selection])

    const setProductActiveKey = (key) => {
        setActiveKey({
            productActiveKey: key,
            contentActiveKey: "",
            settingsActiveKey: "",
        })
    }

    const setContentActiveKey = (key) => {
        setActiveKey({
            productActiveKey: "",
            contentActiveKey: key,
            settingsActiveKey: "",
        })
    }

    const setSettingsActiveKey = (key) => {
        setActiveKey({
            productActiveKey: "",
            contentActiveKey: "",
            settingsActiveKey: key,
        })
    }

    const getSelectionClass = (linkName) => {
        if (selection === linkName) {
            return "item-selected";
        } else {
            return "";
        }
    }

    return <SidebarSection>
        <SidebarWrap>
            <LogoBox>
                {
                    isEmptyVariable(user.merchantLogoPath) ?
                        <p>{user.merchantName}</p> 
                        : <img src={ATTACHMENT_PATH + user.merchantLogoPath} alt="Merchant Logo" />
                }
            </LogoBox>
            <DashBoardMenuBox>
                <ul>
                    <li >
                        <Link to="/dashboard">
                            <MdDashboard />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/orders" className={getSelectionClass("orders")}>
                            <FaShoppingCart />
                            <span>Orders</span>
                        </Link>
                    </li>
                </ul>
            </DashBoardMenuBox>

            <AccordionWrapper>
                <Accordion activeKey={activeKeyObj.productActiveKey} onSelect={(key) => setProductActiveKey(key)}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div>
                                <PiPackageFill /><span>Product</span>
                            </div>
                        </Accordion.Header>

                        <Accordion.Body>
                            <AccordionBodyLayout>
                                <ul>
                                    <li className={getSelectionClass("products")}>
                                        <Link to="/products"><span>Listing</span></Link>
                                    </li>
                                    <li className={getSelectionClass("collections")}>
                                        <Link to="/collections"><span>Collections</span></Link>
                                    </li>
                                    <li className={getSelectionClass("inventory")}>
                                        <Link to="/inventory"><span>Inventory</span></Link>
                                    </li>
                                    <li className={getSelectionClass("reviews")}>
                                        <Link to="/reviews"><span>Reviews</span></Link>
                                    </li>
                                    <li className={getSelectionClass("vendors")}>
                                        <Link to="/vendors"><span>Vendors</span></Link>
                                    </li>
                                    <li className={getSelectionClass("producttypes")}>
                                        <Link to="/producttypes"><span>Product Types</span></Link>
                                    </li>
                                    {/* <li className={getSelectionClass("categories")}>
                                        <Link to="/categories"><span>Categories</span></Link>
                                    </li> */}
                                </ul>
                            </AccordionBodyLayout>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </AccordionWrapper>

            <DashBoardMenuBox>
                <ul>
                    <li>
                        <Link to="/customers" className={getSelectionClass("customers")}>
                            <FaUser />
                            <span>Customers</span>
                        </Link>
                    </li>
                </ul>
            </DashBoardMenuBox>
            <AccordionWrapper>
                <Accordion activeKey={activeKeyObj.contentActiveKey} onSelect={(key) => setContentActiveKey(key)}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <div>
                                <RiPagesFill /><span>Content</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AccordionBodyLayout>
                                <ul>
                                    <li className={getSelectionClass("pages")}>
                                        <Link to="/pages"><span>Pages</span></Link>
                                    </li>
                                    <li className={getSelectionClass("blogs")}>
                                        <Link to="/blogs"><span>Blogs</span></Link>
                                    </li>
                                </ul>
                            </AccordionBodyLayout>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </AccordionWrapper>

            <DashBoardMenuBox>
                <ul>
                    {/* <li>
                        <Link to="">
                            <BiSolidReport />
                            <span>Reports</span>
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/discounts" className={getSelectionClass("discounts")}>
                            <BiSolidOffer />
                            <span>Discounts</span>
                        </Link>
                    </li>
                </ul>
            </DashBoardMenuBox>

            <AccordionWrapper>
                <Accordion activeKey={activeKeyObj.settingsActiveKey} onSelect={(key) => setSettingsActiveKey(key)}>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <div>
                                <IoIosSettings /><span>Settings</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AccordionBodyLayout>
                                <ul>
                                    <li className={getSelectionClass("merchantdetails")}>
                                        <Link to="/merchant"><span>Merchant</span></Link>
                                    </li>
                                    <li className={getSelectionClass("users")}>
                                        <Link to="/staff"><span>Staff</span></Link>
                                    </li>
                                    <li className={getSelectionClass("policies")}>
                                        <Link to="/policies"><span>Policies</span></Link>
                                    </li>
                                    <li className={getSelectionClass("customfields")}>
                                        <Link to="/customfields"><span>Custom Fields</span></Link>
                                    </li>
                                    <li className={getSelectionClass("tags")}>
                                        <Link to="/tags"><span>Product Tags</span></Link>
                                    </li>
                                    <li className={getSelectionClass("customerapis")}>
                                        <Link to="/customerapis"><span>Apis</span></Link>
                                    </li>
                                </ul>
                            </AccordionBodyLayout>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </AccordionWrapper>
        </SidebarWrap>
    </SidebarSection>
}

export default Sidebar;