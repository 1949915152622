import styled from "styled-components";
import RichTextEditorField from "../../components/input/richTextEditor";

const ProductUdfWrapper = styled.div``;
const UdfWrapper = styled.div``;

const ProductUdf = (props) => {
    const { productUdf, udfValueMap, udfInputChange } = props;

    const inputChange = (e) => {
        udfInputChange(e.target.name, e.target.value);
    }


    const handleDecimalInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9.]/g, '');

        udfInputChange(e.target.name, newValue);
    };

    const handleIntegerInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers
        const newValue = inputValue.replace(/[^0-9]/g, '');

        udfInputChange(e.target.name, newValue);
    };

    const editorOnChange = (name, value) => {
        udfInputChange(name, value);
    }

    const getOnChangeFunction = (productUdfType) => {
        if (productUdfType === "Single Line") {
            return inputChange;
        } else if (productUdfType === "Integer") {
            return handleIntegerInputChange;
        } else if (productUdfType === "Decimal") {
            return handleDecimalInputChange;
        } else {

        }
    }

    return <ProductUdfWrapper>
        {
            productUdf.map((udf, idx) => {
                return <UdfWrapper key = {"udfw"+idx}>
                    <label>{udf.productUdfName}</label>
                    {
                        (udf.productUdfType === "Single Line" || udf.productUdfType === "Integer" 
                        || udf.productUdfType === "Decimal") &&
                        <input
                            placeholder={"Enter "+udf.productUdfName}
                            value={udfValueMap[udf.productUdfId]}
                            name={udf.productUdfId}
                            onChange={getOnChangeFunction(udf.productUdfType)}
                            className="m-b-20"
                        />
                    }
                    {
                        (udf.productUdfType === "Multi Line") &&
                        <textarea
                            placeholder={"Enter "+udf.productUdfName}
                            value={udfValueMap[udf.productUdfId]}
                            name={udf.productUdfId}
                            onChange={inputChange}
                            className="m-b-20"
                        />
                    }
                    {
                        (udf.productUdfType === "Rich Text") &&
                        <RichTextEditorField
                            value={udfValueMap[udf.productUdfId]}
                            editorOnChange={(value) => editorOnChange(udf.productUdfId, value)}
                            placeholder={"Enter "+udf.productUdfName}
                            wrapperStyles={{ marginBottom: 20 }}
                        />
                    }
                </UdfWrapper>
            })
        }
    </ProductUdfWrapper>
}

export default ProductUdf;