import { useReducer } from 'react';
import { INITIAL_ALERT_STATE } from '../../utils/constants'

const INITIAL_PAGE_FIELD_DIALOG_STATE = {
    showaddEditPageFieldDialog: false,
    isFieldEdit: false,
    fieldIdx: "",
    pageFieldObj: {

    }
}

const INITIAL_ADDEDIT_CUSTOMER_PAGE_STATE = {
    pageName: "",
    seoTitle: "",
    seoDescription: "",
    urlHandle: "",

    fields: [
    ],

    errors: {
        pageName: "",
        seoTitle: "",
        seoDescription: "",
        urlHandle: "",
    },

    showImageLibraryDialog: false,
    pageImageFieldIdx: "",
    apiErr: "",
}

const initialState = {
    ...INITIAL_ADDEDIT_CUSTOMER_PAGE_STATE,

    isPageLoading: false,
    refreshFlag: false,

    //DIALOG
    addEditPageFieldDialog: { ...INITIAL_PAGE_FIELD_DIALOG_STATE },

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET_STATE": {
            return {
                ...initialState,
            }
        }
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_PAGE_DETAILS": {
            const { pageDetails } = action;
            let fields = pageDetails.pageContent.map(content => {
                return {
                    pageFieldName: content.pageFieldName,
                    pageFieldType: content.pageFieldType,
                    pageContent: content.pageContent,
                    pageContentImageId: content.pageContentImageId,
                }
            })

            return {
                ...state,
                pageName: pageDetails.pageName,
                seoTitle: pageDetails.seoTitle,
                seoDescription: pageDetails.seoDescription,
                urlHandle: pageDetails.urlHandle,

                fields: pageDetails.pageContent.map(content => {
                    return {
                        pageFieldName: content.pageFieldName,
                        pageFieldType: content.pageFieldType,
                        pageContent: content.pageContent,
                        pageContentImageId: content.pageContentImageId,
                    }
                })
            }
        }
        case "RESET_PAGE_FIELD_DIALOG": {
            return {
                ...state,
                addEditPageFieldDialog: { ...INITIAL_PAGE_FIELD_DIALOG_STATE },
                refreshFlag: action.refreshFlag ? true : false
            }
        }
        case "SET_PAGE_FIELD_DIALOG": {
            return {
                ...state,
                addEditPageFieldDialog: { ...action.addEditPageFieldDialog },
            }
        }
        case "SHOW_CREATE_PAGE_FIELD_DIALOG": {
            return {
                ...state,
                addEditPageFieldDialog: {
                    ...INITIAL_PAGE_FIELD_DIALOG_STATE,
                    showaddEditPageFieldDialog: true,
                }
            }
        }
        case "ADD_FIELD_ITEM": {
            const { item } = action;
            return {
                ...state,
                fields: [
                    ...state.fields,
                    { ...item }
                ],
                addEditPageFieldDialog: {
                    ...INITIAL_PAGE_FIELD_DIALOG_STATE
                },
            }
        }
        case "EDIT_FIELD_ITEM": {
            const { item } = action;
            const fieldIdx = state.addEditPageFieldDialog.fieldIdx;
            let field = { ...state.fields[fieldIdx] };
            field = {
                ...field,
                pageFieldName: item.pageFieldName,
                pageFieldType: item.pageFieldType,
            }
            return {
                ...state,
                fields: [
                    ...state.fields.slice(0, fieldIdx),
                    { ...field },
                    ...state.fields.slice(fieldIdx + 1)
                ],
                addEditPageFieldDialog: {
                    ...INITIAL_PAGE_FIELD_DIALOG_STATE
                },
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "INPUT_CHANGE": {
            const { name, value } = action;
            return {
                ...state,
                [name]: value,
            }
        }
        case "SET_FIELD_VALUE": {
            const { fieldIdx, value } = action;
            let field = { ...state.fields[fieldIdx] };
            field.pageContent = value;
            return {
                ...state,
                fields: [
                    ...state.fields.slice(0, fieldIdx),
                    { ...field },
                    ...state.fields.slice(fieldIdx + 1),
                ]
            }
        }
        case "DELETE_FIELD": {
            const { fieldIdx } = action;
            return {
                ...state,
                fields: [
                    ...state.fields.slice(0, fieldIdx),
                    ...state.fields.slice(fieldIdx + 1),
                ],
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "IMAGE_LIBRARY_SUBMIT": {
            const { productAttachmentPath, productAttachmentId } = action;
            let field = { ...state.fields[state.pageImageFieldIdx] };
            field.pageContent = productAttachmentPath;
            field.pageContentImageId = productAttachmentId;

            return {
                ...state,
                fields: [
                    ...state.fields.slice(0, state.pageImageFieldIdx),
                    { ...field },
                    ...state.fields.slice(state.pageImageFieldIdx + 1),
                ],
                showImageLibraryDialog: false,
                pageImageFieldIdx: ""
            }
        }

        case "CLEAR_IMAGE_FIELD": {
            let field = { ...state.fields[state.pageImageFieldIdx] };
            field.pageContent = "";
            field.pageContentImageId = "";

            return {
                ...state,
                fields: [
                    ...state.fields.slice(0, state.pageImageFieldIdx),
                    { ...field },
                    ...state.fields.slice(state.pageImageFieldIdx + 1),
                ],
            }
        }

        case "SHOW_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: true,
                pageImageFieldIdx: action.pageImageFieldIdx
            }
        }

        case "HIDE_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: false,
                pageImageFieldIdx: ""
            }
        }
        case "SET_API_ERR": {
            return {
                ...state,
                apiErr: action.errMsg,
            }
        }
        case "SET_ERRORS": {
            return {
                ...state,
                errors: action.errors,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};