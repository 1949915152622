import * as Urls from './url';

export const ATTACHMENT_PATH = Urls.BASE_API_URL + "attachments/";

export const CHECK_LOGIN = Urls.BASE_API_URL + "checkmerchantlogin";
export const FORGOT_PASSWORD = Urls.BASE_API_URL + "forgotpasswordmerchant";
export const CHECK_FORGOT_PASSWORD_CODE = Urls.BASE_API_URL + "checkforgotpasswordcodemerchant";
export const RESET_PASSWORD = Urls.BASE_API_URL + "resetpasswordmerchant";

export const ADD_MERCHANT_USER = Urls.BASE_API_URL + "addmerchantuser";
export const UPDATE_MERCHANT_USER = Urls.BASE_API_URL + "updatemerchantuser";
export const UPDATE_MERCHANT_USER_STATUS = Urls.BASE_API_URL + "changemerchantuserstatus";
export const GET_MERCHANT_USERS = Urls.BASE_API_URL + "getmerchantusers";
export const GET_MERCHANT_DETAILS = Urls.BASE_API_URL + "getmerchantdetails";
export const UPDATE_MERCHANT_TAX_DETAILS = Urls.BASE_API_URL + "updatemerchanttaxdetails";

export const ADD_VENDOR = Urls.BASE_API_URL + "addvendor";
export const UPDATE_VENDOR = Urls.BASE_API_URL + "updateVendor";
export const UPDATE_VENDOR_STATUS = Urls.BASE_API_URL + "updatestatusvendor";
export const DELETE_VENDOR = Urls.BASE_API_URL + "deletevendor";
export const GET_VENDORS = Urls.BASE_API_URL + "getvendors";

export const ADD_TAG = Urls.BASE_API_URL + "addproducttag";
export const UPDATE_TAG = Urls.BASE_API_URL + "updateproducttag";
export const UPDATE_TAG_STATUS = Urls.BASE_API_URL + "";//NOT REQUIRED
export const DELETE_TAG = Urls.BASE_API_URL + "deleteproducttag";
export const GET_TAGS = Urls.BASE_API_URL + "getproducttags";

export const ADD_PRODUCT_TYPE = Urls.BASE_API_URL + "addproducttype";
export const UPDATE_PRODUCT_TYPE = Urls.BASE_API_URL + "updateproducttype";
export const UPDATE_PRODUCT_TYPE_STATUS = Urls.BASE_API_URL + "updatestatusproducttype";
export const DELETE_PRODUCT_TYPE = Urls.BASE_API_URL + "deleteproducttype";
export const GET_PRODUCT_TYPES = Urls.BASE_API_URL + "getproducttypes";

export const ADD_PRODUCT_GROUP = Urls.BASE_API_URL + "addproductgroup";
export const UPDATE_PRODUCT_GROUP = Urls.BASE_API_URL + "updateproductgroup";
export const UPDATE_PRODUCT_GROUP_STATUS = Urls.BASE_API_URL + "updatestatusproductgroup";
export const DELETE_PRODUCT_GROUP = Urls.BASE_API_URL + "deleteproductgroup";
export const GET_PRODUCT_GROUPS = Urls.BASE_API_URL + "getproductgroups";
export const GET_PRODUCT_GROUP_DETAILS = Urls.BASE_API_URL + "getproductgroupdetails";

export const GET_ATTACHMENTS = Urls.BASE_API_URL + "getattachments";
export const DELETE_ATTACHMENT = Urls.BASE_API_URL + "deleteattachment";
export const UPLOAD_ATTACHMENT = Urls.BASE_API_URL + "uploadattachment";

export const ADD_PRODUCT_UDF = Urls.BASE_API_URL + "addproductudf";
export const UPDATE_PRODUCT_UDF = Urls.BASE_API_URL + "updateproductudf";
export const UPDATE_PRODUCT_UDF_STATUS = Urls.BASE_API_URL + "updatestatusproductudf";
export const DELETE_PRODUCT_UDF = Urls.BASE_API_URL + "deleteproductudf";
export const GET_PRODUCT_UDFS = Urls.BASE_API_URL + "getproductudfs";

export const ADD_PRODUCT = Urls.BASE_API_URL + "addproduct";
export const UPDATE_PRODUCT = Urls.BASE_API_URL + "updateproduct";
export const UPDATE_PRODUCT_STATUS = Urls.BASE_API_URL + "updatestatusproduct";
export const DELETE_PRODUCT = Urls.BASE_API_URL + "deleteproduct";
export const GET_PRODUCTS = Urls.BASE_API_URL + "getproducts";
export const GET_ADD_PRODUCTS_MASTERDATA = Urls.BASE_API_URL + "addproductmasterdata";
export const GET_PRODUCT_DETAILS = Urls.BASE_API_URL + "getproductdetails";

export const ADD_BLOG = Urls.BASE_API_URL + "addblog";
export const UPDATE_BLOG = Urls.BASE_API_URL + "updateblog";
export const UPDATE_BLOG_STATUS = Urls.BASE_API_URL + "updatestatusblog";
export const DELETE_BLOG = Urls.BASE_API_URL + "deleteblog";
export const GET_BLOGS = Urls.BASE_API_URL + "getblogs";
export const GET_BLOG_MASTER_DATA = Urls.BASE_API_URL + "getblogmasterdata";
export const GET_BLOG_DETAILS = Urls.BASE_API_URL + "getblogdetails";

export const GET_API_MODULES = Urls.BASE_API_URL + "getapimodules";
export const CREATE_CUSTOMER_API = Urls.BASE_API_URL + "createcustomerapi";
export const UPDATE_CUSTOMER_API = Urls.BASE_API_URL + "updatecustomerapi";
export const GET_CUSTOMER_APIS = Urls.BASE_API_URL + "getcustomerapi";
export const DELETE_CUSTOMER_API = Urls.BASE_API_URL + "deleteCustomerApi";
export const UPDATE_CUSTOMER_API_STATUS = Urls.BASE_API_URL + "updatestatuscustomerapi";

export const CREATE_CUSTOMER_PAGE = Urls.BASE_API_URL + "createpage";
export const UPDATE_CUSTOMER_PAGE = Urls.BASE_API_URL + "updatepage";
export const GET_CUSTOMER_PAGES = Urls.BASE_API_URL + "getpages";
export const DELETE_CUSTOMER_PAGE = Urls.BASE_API_URL + "deletepage";
export const UPDATE_CUSTOMER_PAGE_STATUS = Urls.BASE_API_URL + "updatestatuspage";
export const CUSTOMER_PAGE_DETAILS = Urls.BASE_API_URL + "getpagedetails";

export const GET_INVENTORY = Urls.BASE_API_URL + "getinventory";
export const ADD_AVAILABLE_VARIANT = Urls.BASE_API_URL + "addavailablevariant";
export const ADD_UNAVAILABLE_VARIANT = Urls.BASE_API_URL + "addunavailablevariant";
export const INVENTORY_LOG = Urls.BASE_API_URL + "inventorylog";

export const GET_POLICIES = Urls.BASE_API_URL + "getpolicies";
export const UPDATE_POLICY = Urls.BASE_API_URL + "updatepolicy";

export const GET_REVIEWS = Urls.BASE_API_URL + "getreviewsmerchant";
export const DELETE_REVIEW = Urls.BASE_API_URL + "deletereview";
export const UPDATE_REVIEW_STATUS = Urls.BASE_API_URL + "updatereviewstatus";

export const GET_CUSTOMERS = Urls.BASE_API_URL + "getcustomers";
export const UPDATE_CUSTOMER_STATUS = Urls.BASE_API_URL + "updatestatuscustomer";

export const CHANGE_PASSWORD = Urls.BASE_API_URL + "changepasswordmerchant";
export const UPDATE_MERCHANT_USER_PROFILE_PIC = Urls.BASE_API_URL + "uploadmerchantuserprofilepic";
export const DELETE_MERCHANT_USER_PROFILE_PIC = Urls.BASE_API_URL + "deletemerchantuserprofilepic";
export const UPDATE_MERCHANT_LOGO = Urls.BASE_API_URL + "uploadmerchantlogo";
export const DELETE_MERCHANT_LOGO = Urls.BASE_API_URL + "deleteMerchantLogo";

export const CREATE_DISCOUNT = Urls.BASE_API_URL + "creatediscount";
export const UPDATE_DISCOUNT = Urls.BASE_API_URL + "updatediscount";
export const GET_DISCOUNTS = Urls.BASE_API_URL + "getdiscounts";
export const DELETE_DISCOUNT = Urls.BASE_API_URL + "deletediscount";
export const UPDATE_DISCOUNT_STATUS = Urls.BASE_API_URL + "updatestatusdiscount";
export const DISCOUNT_DETAILS = Urls.BASE_API_URL + "getdiscountdetails";

export const CREATE_PRODUCT_CATEGORY = Urls.BASE_API_URL + "createproductcategory";
export const UPDATE_PRODUCT_CATEGORY = Urls.BASE_API_URL + "updateproductcategory";
export const DELETE_PRODUCT_CATEGORY = Urls.BASE_API_URL + "deleteproductcategory";
export const GET_PRODUCT_CATEGORIES = Urls.BASE_API_URL + "getproductcategories";
export const UPDATE_PRODUCT_CATEGORY_STATUS = Urls.BASE_API_URL + "updatestatusproductcategory";

export const GET_ORDERS = Urls.BASE_API_URL + "getorders";
export const GET_ORDER_DETAILS = Urls.BASE_API_URL + "getorderdetails";
export const CHANGE_ORDER_STATUS = Urls.BASE_API_URL + "changeorderstatus";
export const DOWNLOAD_INVOICE = Urls.BASE_API_URL + "downloadinvoice";
export const GET_DASHBOARD = Urls.BASE_API_URL + "getdashboard";