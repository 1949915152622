import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import Loader from "../../components/randomComponents/loader";
import { Colors } from "../../components/styles/colors";
import { useAuth } from "../../hooks/useAuth";
import { DELETE_PRODUCT_CATEGORY, GET_PRODUCT_CATEGORIES, UPDATE_PRODUCT_CATEGORY_STATUS} from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateGroup from "./createUpdateProductCategory";
import { GetReducer } from "./reducer";
import ProductCategoryList from "./productCategoryListing";

const ProductCategory = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [categoryState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "categories", heading: "Categories" })
        getGroupList();
    }, []);

    useEffect(() => {
        if (categoryState.refreshFlag) {
            getGroupList();
        }

    }, [categoryState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productCategoryId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productCategoryId, "Active");
                break;
            case "Delete":
                await deleteGroup(selectedItem.productCategoryId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateGroupDialog = () => {
        dispatch({
            type: "SHOW_CREATE_GROUP_DIALOG"
        });
    }

    const onClickGroupDialogCancel = () => {
        dispatch({
            type: "RESET_GROUP_DIALOG"
        });
    }

    const onClickGroupDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_GROUP_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_GROUP_DIALOG",
                    addEditGroupComp: {
                        showAddEditComp: true,
                        isEdit: true,
                        groupObj: {
                            productCategoryId: item.productCategoryId,
                            productCategoryName: item.productCategoryName,
                            productCategoryDescription: item.productCategoryDescription,
                            productCategoryType: item.productCategoryType,
                            imageAttachmentObj: {
                                productAttachmentId: item.productAttachmentId,
                                productAttachmentType: item.productAttachmentType,
                                productAttachmentPath: item.productAttachmentPath
                            },
                            seoTitle: item.seoTitle,
                            seoDescription: item.seoDescription,
                            urlHandle: item.urlHandle,
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Category",
                        message: "Are you sure you want to deactivate " + item.productCategoryName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Category",
                        message: "Are you sure you want to activate " + item.productCategoryName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Category",
                        message: "Are you sure you want to delete " + item.productCategoryName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (categoryState.search != categoryState.searchApiKey) {
            dispatch({ type: "RELOAD_GROUP_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, categoryState.sort, categoryState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getGroupList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: categoryState.search,
            sort: categoryState.sort,
            sortDir: categoryState.sortDir,
            resultSize: categoryState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? categoryState.pageNo : pageNo
        }

        if (categoryState.status !== "All") {
            post.status = categoryState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCT_CATEGORIES, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productCategoryId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_CATEGORY_STATUS, {
                productCategoryId: productCategoryId,
                productCategoryStatus: status
            }, user, logout);

            getGroupList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteGroup = async (productCategoryId) => {
        try {
            await callAuthApi(DELETE_PRODUCT_CATEGORY, {
                productCategoryId: productCategoryId,
            }, user, logout);

            getGroupList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            categoryState.isPageLoading &&
            <Loader />
        }

        {
            !categoryState.addEditGroupComp?.showAddEditComp &&
            <ProductCategoryList
                categoryState={categoryState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                showCreateGroupDialog={showCreateGroupDialog}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getGroupList={getGroupList}
            />
        }

        {
            categoryState.addEditGroupComp?.showAddEditComp &&
            <CreateUpdateGroup
                addEditGroupComp={{
                    ...categoryState.addEditGroupComp
                }}
                onClickSubmit={onClickGroupDialogSubmit}
                onClickCancel={onClickGroupDialogCancel}
            />
        }

        <AlertDialog
            alertState={{ ...categoryState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default ProductCategory;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;