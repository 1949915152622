import { useOutletContext } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { GetReducer } from "./reducer";
import { GET_POLICIES, UPDATE_POLICY } from "../../utils/apiEndPoints";
import AlertDialog from "../../components/dialogs/alertDialog";
import { FieldGroupWrapper, FormWrapper, ButtonDefault } from "../../components/styles/addEditFormStyles";
import { callAuthApi, isEmptyVariable } from "../../utils/utilFunctions";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import RichTextEditorField from "../../components/input/richTextEditor";
import { Colors } from "../../components/styles/colors";
import styled from "styled-components";
import SnackBar from "../../components/randomComponents/snackBar";

const Policies = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [policyState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "policies", heading: "Policies" })
        getPolicies();
    }, []);

    useEffect(() => {
        if (policyState.refreshFlag) {
            getPolicies();
        }
    }, [policyState.refreshFlag]);

    const getPolicies = async () => {
        let response = {};
        try {
            response = await callAuthApi(GET_POLICIES, {}, user, logout);
            dispatch({
                type: "SET_RESPONSE",
                response: response.data
            })
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const privacyEditorOnChange = (value) => {
        dispatch({
            type: "SET_INPUT_VALUE",
            name: "privacyPolicyContent",
            value: value
        })
    }

    const refundEditorOnChange = (value) => {
        dispatch({
            type: "SET_INPUT_VALUE",
            name: "refundPolicyContent",
            value: value
        })
    }

    const termsEditorOnChange = (value) => {
        dispatch({
            type: "SET_INPUT_VALUE",
            name: "termsOfUseContent",
            value: value
        })
    }

    const closeSnackbar = () => {
        dispatch({
            type: "CLEAR_SNACKBAR"
        })
    }

    const savePolicy = async (policyType) => {
        if (isEmptyVariable(policyState[policyType])) {
            dispatch({
                type: "SET_ERROR",
                policyType: policyType,
                error: "Please enter the policy content"
            })
        } else {
            try {
                await callAuthApi(UPDATE_POLICY, {
                    [policyType]: policyState[policyType],
                    policyType: policyType
                }, user, logout);

                dispatch({
                    type: "RELOAD_LIST",
                    policyType:policyType
                })

            } catch (errAlert) {
                dispatch({
                    type: "SET_ALERT",
                    alertState: errAlert
                });
            }
        }
    }

    return <ContentSection>
        <TabWrapper>
            <Tabs
                defaultActiveKey="privacyPolicyContent"
                id="policies-tab"
            >
                <Tab eventKey="privacyPolicyContent" title="Privacy Policy">
                    <FormWrapper>
                        <Row>
                            <Col md={12}>
                                <FieldGroupWrapper>
                                    <label>Privacy Policy</label>
                                    <RichTextEditorField
                                        value={policyState.privacyPolicyContent}
                                        editorOnChange={privacyEditorOnChange}
                                        wrapperStyles={{ marginBottom: 20 }}
                                        editorHeight={"300px"}
                                    />
                                    {
                                        !isEmptyVariable(policyState.errors?.privacyPolicyContent) &&
                                        <span className="err-span m-t-10 m-b-10">{policyState.errors.privacyPolicyContent}</span>
                                    }
                                    <ButtonDefault onClick={() => savePolicy("privacyPolicyContent")}>Save</ButtonDefault>
                                </FieldGroupWrapper>
                            </Col>
                        </Row>
                    </FormWrapper>
                </Tab>
                <Tab eventKey="refundPolicyContent" title="Refund Policy">
                    <FormWrapper>
                        <Row>
                            <Col md={12}>
                                <FieldGroupWrapper>
                                    <label>Refund Policy</label>
                                    <RichTextEditorField
                                        value={policyState.refundPolicyContent}
                                        editorOnChange={refundEditorOnChange}
                                        wrapperStyles={{ marginBottom: 20 }}
                                        editorHeight={"300px"}
                                    />
                                    {
                                        !isEmptyVariable(policyState.errors?.refundPolicyContent) &&
                                        <span className="err-span m-t-10 m-b-10">{policyState.errors.refundPolicyContent}</span>
                                    }
                                    <ButtonDefault onClick={() => savePolicy("refundPolicyContent")}>Save</ButtonDefault>
                                </FieldGroupWrapper>
                            </Col>
                        </Row>
                    </FormWrapper>
                </Tab>
                <Tab eventKey="termsOfUseContent" title="Terms Of Use">
                    <FormWrapper>
                        <Row>
                            <Col md={12}>
                                <FieldGroupWrapper>
                                    <label>Terms Of Use</label>
                                    <RichTextEditorField
                                        value={policyState.termsOfUseContent}
                                        editorOnChange={termsEditorOnChange}
                                        wrapperStyles={{ marginBottom: 20 }}
                                        editorHeight={"300px"}
                                    />
                                    {
                                        !isEmptyVariable(policyState.errors?.termsOfUseContent) &&
                                        <span className="err-span m-t-10 m-b-10">{policyState.errors.termsOfUseContent}</span>
                                    }
                                    <ButtonDefault onClick={() => savePolicy("termsOfUseContent")}>Save</ButtonDefault>
                                </FieldGroupWrapper>
                            </Col>
                        </Row>
                    </FormWrapper>
                </Tab>
            </Tabs>
        </TabWrapper>
        <AlertDialog
            alertState={{ ...policyState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        {
            policyState.showSnackbar &&
            <SnackBar
                showSnackbar={policyState.showSnackbar}
                message={policyState.snackbarMessage}
                timeStamp={policyState.timeStamp}
                closeSnackbar={closeSnackbar}
            />
        }

    </ContentSection>
}

export default Policies;

//STYLES
//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
    border-top: 1px solid ${Colors.inputBorderColor};
`;

export const TabWrapper = styled.div`
    border-bottom: 1px solid ${Colors.inputBorderColor};
    ul{
        margin-bottom: 0;
        padding:0px 20px;
    }

    li {
        border: none !important;
        border-bottom: 1px solid transparent !important;
    }

    .nav-link{
        font-size: 13px;
        border: none !important;
        border-bottom: 1px solid transparent !important;
        border-radius: 0 !important;
        color: ${Colors.themeColorPrimary + "88"};
    }

    .nav-link:hover{
        border-radius: 0 !important;
        background: none !important;
        border-bottom: 2px solid ${Colors.themeColorPrimary + "88"} !important;
    }

    .nav-link.active{
        border-radius: 0 !important;
        color: ${Colors.themeColorPrimary};
        border-bottom: 2px solid ${Colors.themeColorPrimary} !important;
    }
`;