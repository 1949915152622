import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import RichTextEditorField from "../../components/input/richTextEditor";
import AddImageButton from "../../components/randomComponents/addImageButton";
import DisplayImageLayoutBtn from "../../components/randomComponents/displayImageLayoutBtn";
import { Colors } from "../../components/styles/colors";
import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import { DELETE_ACTION, EDIT_ACTION } from "../../utils/constants";
import { isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";

const Field = (props) => {
    const { field, fieldIdx, showImageLibrary, deleteImage, setFieldValue, onClickTableActionItem } = props;

    const inputChange = (e) => {
        setFieldValue(e.target.value, fieldIdx)
    }

    const editorOnChange = (val) => {
        setFieldValue(val, fieldIdx)
    }

    switch (field.pageFieldType) {
        case "Text":
            return <FlexVerticalLayout>
                <FlexCenterLayout>
                    <label>{field.pageFieldName}</label>
                    <TableDropdownLayout>
                        <TableDropdown
                            actionArr={[EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }]}
                            onClickTableActionItem={onClickTableActionItem}
                            item={{
                                field: field,
                                fieldIdx: fieldIdx
                            }}
                        />
                    </TableDropdownLayout>
                </FlexCenterLayout>
                <input
                    value={field.pageContent}
                    name={fieldIdx}
                    onChange={inputChange}
                    className="m-b-20"
                />
            </FlexVerticalLayout>
        case "Text Area":
            return <FlexVerticalLayout>
                <FlexCenterLayout>
                    <label>{field.pageFieldName}</label>
                    <TableDropdownLayout>
                        <TableDropdown
                            actionArr={[EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }]}
                            onClickTableActionItem={onClickTableActionItem}
                            item={{
                                field: field,
                                fieldIdx: fieldIdx
                            }}
                        />
                    </TableDropdownLayout>
                </FlexCenterLayout>
                <textarea
                    value={field.pageContent}
                    name={fieldIdx}
                    onChange={inputChange}
                    className="m-b-20"
                />
            </FlexVerticalLayout>
        case "Rich Text":
            return <FlexVerticalLayout className="m-b-15">
                <FlexCenterLayout>
                    <label>{field.pageFieldName}</label>
                    <TableDropdownLayout>
                        <TableDropdown
                            actionArr={[EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }]}
                            onClickTableActionItem={onClickTableActionItem}
                            item={{
                                field: field,
                                fieldIdx: fieldIdx
                            }}
                        />
                    </TableDropdownLayout>
                </FlexCenterLayout>
                <RichTextEditorField
                    value={field.pageContent}
                    editorOnChange={editorOnChange}
                />
            </FlexVerticalLayout>
        case "Image":
            return <FlexVerticalLayout className="m-b-15">
                <FlexCenterLayout>
                    <label>{field.pageFieldName}</label>
                    <TableDropdownLayout>
                        <TableDropdown
                            actionArr={[EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }]}
                            onClickTableActionItem={onClickTableActionItem}
                            item={{
                                field: field,
                                fieldIdx: fieldIdx
                            }}
                        />
                    </TableDropdownLayout>
                </FlexCenterLayout>
                {
                    isEmptyVariable(field.pageContentImageId)
                        ?
                        <AddImageButton
                            text="Add Image"
                            showImageLibrary={() => showImageLibrary(fieldIdx)}
                        />
                        :
                        <DisplayImageLayoutBtn
                            showImageLibrary={() => showImageLibrary(fieldIdx)}
                            deleteImage={deleteImage}
                            imageSrc={ATTACHMENT_PATH + field.pageContent.replace("/images/", "/thumbnails/")}
                        />
                }
            </FlexVerticalLayout>

        default:
            return <></>
    }
}

const PageFields = (props) => {
    const { fields, showEditPageFieldDialog, deleteImage, showDeleteFieldAlert,
        setFieldValue, showImageLibrary } = props;

    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                showEditPageFieldDialog(item.field, item.fieldIdx);
                break;
            case "Delete":
                showDeleteFieldAlert(item.field, item.fieldIdx);
                break;
        }
    }

    return <>
        {
            !isEmptyArray(fields) &&
            fields.map((field, fieldIdx) => {
                return <Row key={"pagefield" + fieldIdx}>
                    <Col md={field.pageFieldType === "Image" ? 4 : 12}>
                        <Field
                            field={field}
                            fieldIdx={fieldIdx}
                            setFieldValue={setFieldValue}
                            showImageLibrary={showImageLibrary}
                            deleteImage={deleteImage}
                            onClickTableActionItem={onClickTableActionItem}
                        />
                    </Col>
                </Row>
            })
        }
    </>
}
export default PageFields;

const FlexCenterLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:10px;
`;

const FlexVerticalLayout = styled.div`
    display:flex;
    flex-direction:column;
    flex-grow: 1;

    label{
        margin-bottom: 0;
    }
`;

const TableDropdownLayout = styled.div`
    flex-shrink: 0;
    margin-left: 10px;
`;
