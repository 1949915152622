import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BsSearch } from "react-icons/bs";
import { useAuth } from '../../hooks/useAuth';
import {
    DELETE_ATTACHMENT, GET_ATTACHMENTS,
    UPLOAD_ATTACHMENT
} from '../../utils/apiEndPoints';
import { ALLOWED_FILE_SIZE_IN_MB, RESULT_SIZE_ARRAY_IMAGE } from '../../utils/constants';
import {
    callAuthApi, getLocalDateFromUTC, isEmptyArray,
    isEmptyVariable
} from '../../utils/utilFunctions';
import AlertDialog from '../dialogs/alertDialog';
import ResultSizeDropdown from '../dropdowns/resultSizeDropdown';
import Loader from '../randomComponents/loader';
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout,
    ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../styles/listingHeaderStyles';
import DisplayImage from './displayImage';
import DisplayImageLoder from './displayImageLoader';
import { Col5, ImageLayout, ImageLibraryBodyLayout, ImageLibraryListRow, InfoLayout, Row5 } from './imagelibrarydialogstyle';
import { GetReducer } from './reducer';
import SelectedImageInfo from './selectedImageInfo';
import UploadImage from './uploadImage';

const ImageLibrary = (props) => {
    const { showDialog, onClickCancel, onClickSubmit, isMultiSelect, selectedImages:selectedImagesProps} = props;
    const { user, logout } = useAuth();
    const [imageState, dispatch] = GetReducer();

    useEffect(() => {
        if (showDialog) {
            dispatch({
                type: "RESET_STATE"
            })
        }
    }, [showDialog]);

    useEffect(() => {
        if (imageState.refreshFlag) {
            getImages();
        }

    }, [imageState.refreshFlag]);

    useEffect(() => {
        if (imageState.uploadIdx !== -1 && imageState.uploadIdx < imageState.files.length) {
            uploadImageByIndex();
        }

    }, [imageState.uploadIdx]);

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (imageState.search != imageState.searchApiKey) {
            dispatch({ type: "RELOAD_IMAGE_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Delete":
                await deleteImage(selectedItem.productAttachmentId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const onClickInsertImage = () => {
        let selectedImages = [];

        if(!isEmptyArray(imageState.selectedIds)){
            selectedImages = imageState.selectedIds.map(item=>imageState.selectedItemsObj[item])
        }

        onClickSubmit(selectedImages);
    }

    const getImages = async (pageNo) => {
        let post = {
            search: imageState.search,
            sort: imageState.sort,
            sortDir: imageState.sortDir,
            resultSize: imageState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? imageState.pageNo : pageNo
        }

        let response = {};
        try {
            response = await callAuthApi(GET_ATTACHMENTS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
                console.log("reload completed");
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const onClickImage = (imageItem) => {
        dispatch({
            type: "SELECT_IMAGE",
            imageItem: imageItem,
            isMultiSelect: isMultiSelect
        })
    }

    const getImageInfo = (fieldName) => {
        if (!isEmptyVariable(imageState.selectedIds)) {
            // console.log(imageState.selectedIds);
            let latestId = imageState.selectedIds[imageState.selectedIds.length - 1];
            // console.log(latestId)
            // console.log(imageState.selectedItemsObj)
            return imageState.selectedItemsObj[latestId][fieldName];
        }
    }

    const showDeleteImageAlert = () => {
        if (!isEmptyArray(imageState.selectedIds)) {
            let latestId = imageState.selectedIds[imageState.selectedIds.length - 1];
            let item = imageState.selectedItemsObj[latestId];

            dispatch({
                type: "SET_ALERT",
                alertState: {
                    showAlertDialog: true,
                    heading: "Delete Image",
                    message: "Are you sure you want to permanently delete " + item.productAttachmentPath.split("-_-:").pop() + "?",
                    type: "ALERT",
                    cancelBtn: true,
                    okayBtn: true,
                    cancelBtnLabel: "Cancel",
                    okayBtnLabel: "Delete",
                    operation: "Delete",
                    selectedItem: item,
                }
            });
        }

    }

    const fileValidation = (filePath) => {
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            return false;
        } else {
            return true;
        }
    }

    const changeImage = (e) => {
        e.preventDefault();
        let uploadObj = {};

        let hasErrors = false;
        let errorMessage = "";

        if (!isEmptyVariable(e.target.files)) {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
                let obj = {
                    uploading: true,
                    error: "",
                }

                if (!fileValidation(file.name)) {
                    errorMessage = "Error: Only jpeg, jpg and png are allowed";
                    hasErrors = true;
                    break;
                }

                if (sizeInMB > ALLOWED_FILE_SIZE_IN_MB) {
                    errorMessage = "Error: Please upload file having size less than " + ALLOWED_FILE_SIZE_IN_MB + " Mb";
                    hasErrors = true;
                    break;
                }

                uploadObj = {
                    ...uploadObj,
                    [file.name]: { ...obj }
                };
            }

            if (hasErrors) {
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Error",
                        message: errorMessage,
                        type: "ALERT",
                        cancelBtn: false,
                        okayBtn: true,
                        okayBtnLabel: "Okay",
                    }
                });
            } else {
                console.log("BEGIN UPLOAD")
                dispatch({
                    type: "BEGIN_UPLOAD",
                    files: e.target.files,
                    uploadObj: uploadObj
                })
            }
        }
    }

    const uploadImageByIndex = async () => {

        const { files, uploadObj, uploadIdx } = imageState;

        const file = files[uploadIdx];

        const formData = new FormData();
        formData.append('attachmentFile', file);
        formData.append('email', user.email);
        formData.append('accessToken', user.accessToken);
        formData.append('merchantApiKey', user.merchantApiKey);

        fetch(UPLOAD_ATTACHMENT,
            {
                method: "POST",
                mode: 'cors',
                body: formData
            })
            .then(response => { return response.json(); })
            .then(data => {
                if (data.responseCode === 0) {
                    console.log(uploadIdx + " COMPLETED Upload: " + file.name);

                    uploadObj[file.name] = {
                        uploading: false,
                        ...data.data
                    }

                    //now check if all are uploaded
                    let isUploading = false;
                    Object.values(uploadObj).forEach(item => {
                        console.log(uploadIdx + " Checking all uploaded");
                        if (item.uploading) {
                            isUploading = true;
                        }
                    })

                    if (isUploading) {
                        setTimeout(() => {
                            console.log(uploadIdx + " Still pending some uploads");
                            dispatch({
                                type: "UPDATE_UPLOADING_STATUS",
                                uploadObj: { ...uploadObj }
                            })
                        }, 500);


                    } else {
                        // lets wait for 1 second
                        console.log(uploadIdx + " Ending Upload");
                        setTimeout(() => {
                            console.log(uploadIdx + "Upload Ended");
                            dispatch({
                                type: "END_UPLOAD"
                            })
                        }, 1000);

                    }
                } else if (data.data.responseCode === 10 || data.data.responseCode === 9) {
                    dispatch({
                        type: "SET_ALERT",
                        alertState: {
                            showAlertDialog: true,
                            heading: "Session Expired",
                            message: "",
                            type: "ALERT",
                            cancelBtn: false,
                            okayBtn: true,
                            cancelBtnLabel: "Cancel",
                            okayBtnLabel: "Refresh"
                        }
                    });
                } else {
                    dispatch({
                        type: "SET_ALERT",
                        alertState: {
                            showAlertDialog: true,
                            heading: "Something went wrong. Please try again",
                            message: data.data.responseMessage,
                            type: "ALERT",
                            cancelBtn: false,
                            okayBtn: true,
                            cancelBtnLabel: "Cancel",
                            okayBtnLabel: "Okay"
                        }
                    });
                }
            })
    }

    const deleteImage = async (productAttachmentId) => {
        let post = {
            productAttachmentId: productAttachmentId
        }

        let response = {};
        try {
            response = await callAuthApi(DELETE_ATTACHMENT, post, user, logout);
            dispatch({
                type: "RELOAD_IMAGE_LIST_AFTER_DELETE",
            })
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    return <>
        <Modal show={showDialog} onHide={onClickCancel} dialogClassName="image-lib-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Image Library</Modal.Title>
            </Modal.Header>
            {
                imageState.isPageLoading &&
                <Loader />
            }
            <ListingHeaderWrapper>
                <ListingHeaderLayout>
                    <ListingHeaderLeftLayout>
                        <ResultSizeDropdown
                            dropdownLabel={imageState.resultSize}
                            dropdownList={RESULT_SIZE_ARRAY_IMAGE}
                            onClickResultSize={onClickResultSize}
                        />

                        <SearchBox>
                            <input type="text" placeholder="Search image names..."
                                value={imageState.search}
                                name="search"
                                onChange={onSearchInputChange}
                                onBlur={searchList}
                                onKeyDown={
                                    (e) => {
                                        if (e.key === 'Enter') searchList();
                                    }
                                }
                            />
                            <button><BsSearch /></button>
                        </SearchBox>

                        {/* <FilterDropdown
                            laceholder={"Sort By"}
                            selectedValue={imageState.sort}
                            label="status"
                            options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                            dropdownItemClick={statusDropdownClick}
                        /> */}

                        {
                            !isEmptyArray(imageState.selectedIds) &&
                            <p>{imageState.selectedIds.length + " Selected"}</p>
                        }
                    </ListingHeaderLeftLayout>

                    <ListingHeaderRightLayout>
                        <button 
                        onClick={onClickInsertImage} 
                        disabled={isEmptyArray(imageState.selectedIds)}>
                                Insert to Page
                        </button>
                    </ListingHeaderRightLayout>
                </ListingHeaderLayout>
            </ListingHeaderWrapper>
            <Modal.Body>
                <ImageLibraryBodyLayout>
                    <ImageLayout>
                        <ImageLibraryListRow>
                            <Row5>
                                <Col5 md={2}>
                                    <UploadImage
                                        changeImage={changeImage}
                                    />
                                </Col5>
                                {
                                    !isEmptyArray(Object.values(imageState.uploadObj)) &&
                                    Object.values(imageState.uploadObj).reverse().map((item, imageIdx) => {
                                        return <Col5 md={2} key={"uploadingImg" + imageIdx}>
                                            {
                                                item.uploading
                                                    ?
                                                    <DisplayImageLoder />
                                                    :
                                                    <DisplayImage
                                                        onClickImage={() => { }}
                                                        item={item}
                                                        imageIdx={imageIdx}
                                                    />
                                            }
                                        </Col5>
                                    })
                                }
                                {
                                    !isEmptyArray(imageState?.response?.result) &&
                                    imageState.response.result.map((item, imageIdx) => {
                                        let isSelected = imageState.selectedIds.includes(item.productAttachmentId);
                                        let isDisabled = selectedImagesProps && selectedImagesProps.some(selectedImage=>selectedImage.productAttachmentId === item.productAttachmentId)
                                        return <Col5 md={2} key={"image" + imageIdx}>
                                            <DisplayImage
                                                onClickImage={onClickImage}
                                                item={item}
                                                isSelected={isSelected}
                                                isDisabled = {isDisabled}
                                            />
                                        </Col5>
                                    })
                                }
                            </Row5>
                        </ImageLibraryListRow>
                    </ImageLayout>
                    {
                        !isEmptyArray(imageState.selectedIds) &&
                        <InfoLayout>
                            <SelectedImageInfo
                                getImageInfo={getImageInfo}
                                showDeleteImageAlert={showDeleteImageAlert}
                                getLocalDateFromUTC={getLocalDateFromUTC}
                            />
                        </InfoLayout>
                    }

                    {
                        isEmptyArray(imageState.selectedIds) &&
                        <InfoLayout style={{ padding: 20 }}>
                            <h6 style={{ fontSize: 16 }}>Attachment Details</h6>
                        </InfoLayout>
                    }
                </ImageLibraryBodyLayout>
            </Modal.Body>
        </Modal>

        <AlertDialog
            alertState={{ ...imageState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default ImageLibrary;