import React from "react";
import styled from "styled-components";
import { FaFaceSadTear } from "react-icons/fa6";
import { Colors } from "../styles/colors";

const NoResultsWrapper = styled.div`
    height:calc(100vh - 100px);
    display:flex;
    align-items:center;
    justify-content:Center;
    text-align:center;
`;

const NoResultsCol = styled.div`
    max-width:400px;
    margin:auto;
    svg{
        font-size:40px;
        color:${Colors.themeColorPrimaryHover+"99"};
    }

    h6{
        margin:10px 0px 5px;
    }
`;
const NoResults = (props) =>{
    return <NoResultsWrapper>
            <NoResultsCol>
                <FaFaceSadTear/>
                <h6>{props.noResultsHeading ? props.noResultsHeading : "Sorry, No Results found"}</h6>
                <p>{props.noResultsMsg ? props.noResultsMsg : "There aren't any results for that query."}</p>
            </NoResultsCol>
    </NoResultsWrapper>
}
export default NoResults;