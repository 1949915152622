import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_DISCOUNTS, DELETE_DISCOUNT, UPDATE_DISCOUNT_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateDiscount from "./createUpdateDiscount";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import DiscountList from "./discountList";
import SelectDiscountType from "./selectDiscountType";


const Discount = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [discountState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "discounts", heading: "Discounts" })
        getDiscountList();
    }, []);

    useEffect(() => {
        if (discountState.refreshFlag) {
            getDiscountList();
        }

    }, [discountState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.discountId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.discountId, "Active");
                break;
            case "Delete":
                // await deleteDiscount(selectedItem.discountId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    // const showCreateDiscountComp = () => {
    //     dispatch({
    //         type: "SHOW_CREATE_DISCOUNT_COMP"
    //     });
    // }

    const onClickDiscountCompCancel = () => {
        dispatch({
            type: "RESET_DISCOUNT_COMP"
        });
    }

    const onClickDiscountCompSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_DISCOUNT_COMP",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_DISCOUNT_COMP",
                    addEditDiscountComp: {
                        showAddEditDiscountComp: true,
                        isEdit: true,
                        discountType: item.discountType,
                        discountObj: {
                            discountId: item.discountId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Discount",
                        message: "Are you sure you want to deactivate " + item.discountTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Discount",
                        message: "Are you sure you want to activate " + item.discountTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Discount",
                        message: "Are you sure you want to delete " + item.discountTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = () => {
        if (discountState.search != discountState.searchApiKey) {
            dispatch({ type: "RELOAD_DISCOUNT_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, discountState.sort, discountState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getDiscountList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: discountState.search,
            sort: discountState.sort,
            sortDir: discountState.sortDir,
            resultSize: discountState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? discountState.pageNo : pageNo
        }

        if (discountState.status !== "All") {
            post.status = discountState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_DISCOUNTS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (discountId, status) => {
        try {
            await callAuthApi(UPDATE_DISCOUNT_STATUS, {
                discountId: discountId,
                discountStatus: status
            }, user, logout);

            getDiscountList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    // const deleteDiscount = async (discountId) => {
    //     try {
    //         await callAuthApi(DELETE_DISCOUNT, {
    //             discountId: discountId,
    //         }, user, logout);

    //         getDiscountList();
    //     } catch (errAlert) {
    //         dispatch({
    //             type: "SET_ALERT",
    //             alertState: errAlert
    //         });
    //     }
    // }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }

    const displayDiscountTypeDialog = () => {
        dispatch({
            type:"DISPLAY_DISCOUNT_TYPE_DIALOG"
        })
    }

    const hideDiscountTypeDialog = () => {
        dispatch({
            type:"HIDE_DISCOUNT_TYPE_DIALOG"
        })
    }

    const onSubmitDiscountType = (discountType) => {
        dispatch({
            type: "ON_SUBMIT_DISCOUNT_TYPE",
            discountType: discountType
        })
    }

    return <ContentSection>
        {
            discountState.isPageLoading &&
            <Loader />
        }

        {
            !discountState.addEditDiscountComp?.showAddEditDiscountComp &&
            <DiscountList
                discountState={discountState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                displayDiscountTypeDialog={displayDiscountTypeDialog}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getDiscountList={getDiscountList}
            />
        }

        {
            discountState.addEditDiscountComp?.showAddEditDiscountComp &&
            <CreateUpdateDiscount
                addEditDiscountComp={{
                    ...discountState.addEditDiscountComp
                }}
                onClickSubmit={onClickDiscountCompSubmit}
                onClickCancel={onClickDiscountCompCancel}
            />
        }

        <SelectDiscountType
            showDiscountTypeDialog = {discountState.showDiscountTypeDialog}
            onClickSubmit = {onSubmitDiscountType}
            onClickCancel = {hideDiscountTypeDialog}
        />

        <AlertDialog
            alertState={{ ...discountState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default Discount;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;