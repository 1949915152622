import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { DELETE_TAG, GET_TAGS } from "../../utils/apiEndPoints";
import {
    DELETE_ACTION, EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateTag from "./createUpdateTag";
import { GetReducer } from "./reducer";
import moment from "moment";

const TagList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [tagState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "tags", heading: "Tags" })
        getTagList();
    }, []);

    useEffect(() => {
        if (tagState.refreshFlag) {
            getTagList();
        }

    }, [tagState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Delete":
                await deleteTag(selectedItem.productTagId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateTagDialog = () => {
        dispatch({
            type: "SHOW_CREATE_TAG_DIALOG"
        });
    }

    const onClickTagDialogCancel = () => {
        dispatch({
            type: "RESET_TAG_DIALOG"
        });
    }

    const onClickTagDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_TAG_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_TAG_DIALOG",
                    createTagDialog: {
                        showDialog: true,
                        isEdit: true,
                        tagObj: {
                            productTagName: item.productTagName,
                            productTagId: item.productTagId
                        }
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Tag",
                        message: "Are you sure you want to delete " + item.productTagName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (tagState.search != tagState.searchApiKey) {
            dispatch({ type: "RELOAD_TAG_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, tagState.sort, tagState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getTagList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: tagState.search,
            sort: tagState.sort,
            sortDir: tagState.sortDir,
            resultSize: tagState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? tagState.pageNo : pageNo,
            timezoneOffset: moment().utcOffset()
        }

        if (tagState.status !== "All") {
            post.status = tagState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_TAGS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteTag = async (productTagId) => {
        try {
            await callAuthApi(DELETE_TAG, {
                productTagId: productTagId,
            }, user, logout);

            getTagList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else {
            return [];
        }
    }

    return <ContentSection>
        {
            tagState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={tagState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search tags..."
                            value={tagState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateTagDialog}><BsFillPlusCircleFill />Add Tag</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            tagState.firstLoad &&
            <>
                {
                    !isEmptyArray(tagState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Tag Name"}
                                    sort={tagState.sort}
                                    sortDir={tagState.sortDir}
                                    tableSortCol={"pt.productTagName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Products"}
                                    sort={tagState.sort}
                                    sortDir={tagState.sortDir}
                                    tableSortCol={"ptcv.productCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={tagState.sort}
                                    sortDir={tagState.sortDir}
                                    tableSortCol={"pt.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={tagState.sort}
                                    sortDir={tagState.sortDir}
                                    tableSortCol={"pt.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                tagState.response.result.map((item, idx) => {
                                    return <tr key={"tagtable"+idx}>
                                        <td>
                                            {item.productTagName}
                                        </td>
                                        <td>{item.productCount}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.productTagStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getTagList(e.selected + 1)}
                            pageRangeDisplayed={tagState.resultSize}
                            pageCount={getPageCount(tagState.response.count, tagState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={tagState.pageNo - 1}
                            totalRowCount = {tagState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(tagState.response.result) &&
                    !tagState.isPageLoading &&
                    !isEmptyVariable(tagState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(tagState.response.result) &&
                    !tagState.isPageLoading &&
                    isEmptyVariable(tagState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...tagState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        {
            tagState.createTagDialog?.showDialog &&
            <CreateUpdateTag
                productTagDialog={{
                    ...tagState.createTagDialog,
                }}
                onClickCancel={onClickTagDialogCancel}
                onClickSubmit={onClickTagDialogSubmit}
            />
        }
    </ContentSection>
}

export default TagList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;