import React from 'react';
import {
    DropdownWrapper, HorizontalDots,VerticalDots, DropdownText, DropdownLink
} from './tableDropdown.style';
import { Dropdown } from 'react-bootstrap';
import { isEmptyVariable } from '../../utils/utilFunctions';

const TableDropdown = (props) => {
    const {minWidth, item, isVertical, actionArr} = props;

    const onClickTableActionItem = (label, e) => {
        props.onClickTableActionItem(label, item);
    }

    return <DropdownWrapper minWidth = {minWidth}>
        <Dropdown>
            <Dropdown.Toggle>
                {
                    isVertical &&
                    <VerticalDots/>
                }
                {
                    !isVertical &&
                    <HorizontalDots/>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    !isEmptyVariable(actionArr) &&
                    actionArr.map((item) => {
                        return <Dropdown.Item as={"button"}
                        style={{borderTop:item.borderTop}}
                        onClick={()=>onClickTableActionItem(item.label)}>
                            <DropdownLink>
                                {
                                    !isEmptyVariable(item.icon) &&
                                    <>
                                        {/* {item.icon} */}
                                    </>
                                }
                                <DropdownText style={{color:item.textColor}}>
                                    {item.label}
                                </DropdownText>
                            </DropdownLink>
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    </DropdownWrapper>
}

export default TableDropdown;