import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_CUSTOMER_PAGES, DELETE_CUSTOMER_PAGE, UPDATE_CUSTOMER_PAGE_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateCustomerPage from "./createUpdateCustomerPage";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import CustomerPageList from "./customerPageListing";


const CustomerPages = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [customerPageState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "pages", heading: "Pages" })
        getCustomerPagesList();
    }, []);

    useEffect(() => {
        if (customerPageState.refreshFlag) {
            getCustomerPagesList();
        }

    }, [customerPageState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.pageId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.pageId, "Active");
                break;
            case "Delete":
                await deleteCustomerPage(selectedItem.pageId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreatePageComp = () => {
        dispatch({
            type: "SHOW_CREATE_CUSTOMER_PAGE_COMP"
        });
    }

    const onClickCustomerPageCompCancel = () => {
        dispatch({
            type: "RESET_CUSTOMER_PAGE_COMP"
        });
    }

    const onClickCustomerPageCompSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_CUSTOMER_PAGE_COMP",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_CUSTOMER_PAGE_COMP",
                    addEditCustomerPageComp: {
                        showAddEditCustomerPageComp: true,
                        isEdit: true,
                        pageObj: {
                            pageId: item.pageId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Page",
                        message: "Are you sure you want to deactivate " + item.pageName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Page",
                        message: "Are you sure you want to activate " + item.pageName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Page",
                        message: "Are you sure you want to delete " + item.pageName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (customerPageState.search != customerPageState.searchApiKey) {
            dispatch({ type: "RELOAD_CUSTOMER_PAGE_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, customerPageState.sort, customerPageState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getCustomerPagesList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: customerPageState.search,
            sort: customerPageState.sort,
            sortDir: customerPageState.sortDir,
            resultSize: customerPageState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? customerPageState.pageNo : pageNo
        }

        if (customerPageState.status !== "All") {
            post.status = customerPageState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_CUSTOMER_PAGES, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (pageId, status) => {
        try {
            await callAuthApi(UPDATE_CUSTOMER_PAGE_STATUS, {
                pageId: pageId,
                pageStatus: status
            }, user, logout);

            getCustomerPagesList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteCustomerPage = async (pageId) => {
        try {
            await callAuthApi(DELETE_CUSTOMER_PAGE, {
                pageId: pageId,
            }, user, logout);

            getCustomerPagesList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            customerPageState.isPageLoading &&
            <Loader />
        }

        {
            !customerPageState.addEditCustomerPageComp?.showAddEditCustomerPageComp &&
            <CustomerPageList
                customerPageState={customerPageState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                showCreatePageComp={showCreatePageComp}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getCustomerPagesList={getCustomerPagesList}
            />
        }

        {
            customerPageState.addEditCustomerPageComp?.showAddEditCustomerPageComp &&
            <CreateUpdateCustomerPage
                addEditCustomerPageComp={{
                    ...customerPageState.addEditCustomerPageComp
                }}
                onClickSubmit={onClickCustomerPageCompSubmit}
                onClickCancel={onClickCustomerPageCompCancel}
            />
        }

        <AlertDialog
            alertState={{ ...customerPageState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default CustomerPages;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;