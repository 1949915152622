import ReactPaginate from 'react-paginate';
import { PaginiationWrapper, PaginiationRow } from './pagination.style';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { isEmptyVariable } from '../../utils/utilFunctions';

const Pagination = (props) => {
    const { forcePage, pageRangeDisplayed, totalRowCount, breakLabel, nextLabel, onPageChange,
        pageCount, previousLabel, containerClassName } = props;

    const start = forcePage * pageRangeDisplayed + 1;
    let end = forcePage * pageRangeDisplayed + parseInt(pageRangeDisplayed);
    if (end > totalRowCount) {
        end = totalRowCount;
    }
    return <PaginiationWrapper>
        {
            !isEmptyVariable(pageCount) &&
            <PaginiationRow>
                <ReactPaginate
                    breakLabel={breakLabel}
                    nextLabel={nextLabel ? nextLabel : <MdOutlineNavigateNext />}
                    onPageChange={onPageChange}
                    pageRangeDisplayed={pageRangeDisplayed}
                    pageCount={pageCount}
                    previousLabel={previousLabel ? previousLabel : <MdOutlineNavigateBefore />}
                    renderOnZeroPageCount={null}
                    containerClassName={containerClassName ? containerClassName : ""}
                    forcePage={forcePage}
                />
                <p>
                    {start + "-" + end + " of " + totalRowCount}
                </p>
            </PaginiationRow>
        }
    </PaginiationWrapper>
}

export default Pagination;