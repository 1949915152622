import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import styled from 'styled-components';
import ImageLibrary from '../../components/imageLibraryDialog';
import RichTextEditorField from '../../components/input/richTextEditor';
import AddImageButton from '../../components/randomComponents/addImageButton';
import DisplayImageLayoutBtn from '../../components/randomComponents/displayImageLayoutBtn';
import { FieldGroupAddImageWrapper, FieldGroupWrapper, FormWrapper } from '../../components/styles/addEditFormStyles';
import { BackButton, ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper } from '../../components/styles/listingHeaderStyles';
import { useAuth } from '../../hooks/useAuth';
import { CREATE_PRODUCT_CATEGORY, ATTACHMENT_PATH, UPDATE_PRODUCT_CATEGORY } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';

const initialState = {
    productCategoryName: "",
    productCategoryDescription: "",
    productCategoryStatus: "",
    seoTitle: "",
    seoDescription: "",
    urlHandle: "",
    imageAttachmentObj: {

    },
    errors: {
        productCategoryName: "",
        productCategoryDescription: "",
        productCategoryStatus: "",
        seoTitle: "",
        seoDescription: "",
        urlHandle: "",
    },
    apiErr: "",
}

const CreateUpdateGroup = (props) => {
    const { addEditGroupComp, onClickSubmit, onClickCancel } = props;

    const [componentState, setComponentState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditGroupComp.showAddEditComp) {
            if (addEditGroupComp.isEdit) {
                setComponentState({
                    ...initialState,
                    ...addEditGroupComp.groupObj
                });
            } else {
                setComponentState(initialState);
            }
            inputRef.current.focus();
        }
    }, [addEditGroupComp.showAddEditComp])

    const inputChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.value
        })
    }

    const handleUrlHandle = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, '-');
        setComponentState({
            ...componentState,
            [e.target.name]: newValue.toLowerCase()
        })
    }

    const editorOnChange = (value) => {
        setComponentState({
            ...componentState,
            productCategoryDescription: value
        })
    }

    const productCategoryStatusDropdownItemClick = (option) => {
        setComponentState({
            ...componentState,
            productCategoryStatus: option.productCategoryStatus
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.productCategoryName)) {
            hasErrors = true;
            errors.productCategoryName = "Please enter the category name"
        }

        if (isEmptyVariable(componentState.productCategoryDescription)) {
            hasErrors = true;
            errors.productCategoryDescription = "Please enter the description"
        }

        if (isEmptyVariable(componentState.seoTitle)) {
            hasErrors = true;
            errors.seoTitle = "Please enter the SEO title"
        }

        if (isEmptyVariable(componentState.seoDescription)) {
            hasErrors = true;
            errors.seoDescription = "Please enter the SEO description"
        }

        if (isEmptyVariable(componentState.urlHandle)) {
            hasErrors = true;
            errors.urlHandle = "Please enter the URL handle"
        }

        if (isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)) {
            hasErrors = true;
            errors.productAttachmentId = "Please select an image"
        }

        setComponentState({
            ...componentState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const showImageLibrary = () => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: true
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: false
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            setComponentState({
                ...componentState,
                imageAttachmentObj: selectedImages[0],
                showImageLibraryDialog: false
            })
        }
    }

    const deleteImage = () => {
        setComponentState({
            ...componentState,
            imageAttachmentObj: {},
        })
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditGroupComp.isEdit) {
                    await updateproductCategory();
                } else {
                    await createNewproductCategory();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setComponentState({
                    ...componentState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    setComponentState({
                        ...componentState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setComponentState({
                    ...componentState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createNewproductCategory = async () => {
        await callAuthApi(CREATE_PRODUCT_CATEGORY, {
            productCategoryName: componentState.productCategoryName,
            productCategoryDescription: componentState.productCategoryDescription,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
        }, user, logout)
    }

    const updateproductCategory = async () => {
        await callAuthApi(UPDATE_PRODUCT_CATEGORY, {
            productCategoryId: addEditGroupComp.groupObj.productCategoryId,
            productCategoryName: componentState.productCategoryName,
            productCategoryDescription: componentState.productCategoryDescription,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
        }, user, logout);
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{addEditGroupComp.isEdit ? "Edit Category" : "Add Category"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={8}>
                    <FieldGroupWrapper>
                        <label>Category Name*</label>
                        <input
                            value={componentState.productCategoryName}
                            name="productCategoryName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productCategoryName) &&
                            <span className="err-span m-b-10">{componentState.errors.productCategoryName}</span>
                        }
                        <label>Description*</label>
                        <RichTextEditorField
                            value={componentState.productCategoryDescription}
                            editorOnChange={editorOnChange}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productCategoryDescription) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productCategoryDescription}</span>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Search Engine</h6>
                        <label>SEO Title*</label>
                        <input
                            value={componentState.seoTitle}
                            name="seoTitle"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5' 
                            style = {{color:componentState.seoTitle.length>60?"red":"#555555"}}>
                            {componentState.seoTitle.length+" of 60 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.seoTitle}</span>
                        }
                        
                        <label>SEO Description*</label>
                        <textarea
                            value={componentState.seoDescription}
                            name="seoDescription"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span  className='help-span m-b-20 m-t-5'
                            style = {{color:componentState.seoDescription.length>320?"red":"#555555"}}>
                            {componentState.seoDescription.length+" of 320 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoDescription) &&
                            <span className="err-span m-b-10">{componentState.errors.seoDescription}</span>
                        }

                        <label>URL Handle*</label>
                        <input
                            value={componentState.urlHandle}
                            name="urlHandle"
                            onChange={handleUrlHandle}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.urlHandle) &&
                            <span className="err-span m-b-10">{componentState.errors.urlHandle}</span>
                        }

                    </FieldGroupWrapper>
                </Col>

                <Col md={4}>
                    <FieldGroupAddImageWrapper>
                        <h6>Featured Image*</h6>
                        {
                            isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)
                                ?
                                <AddImageButton
                                    text = "Add Image"
                                    showImageLibrary = {showImageLibrary}
                                />
                                :
                                <DisplayImageLayoutBtn
                                    showImageLibrary = {showImageLibrary}
                                    deleteImage = {deleteImage}
                                    imageSrc = {ATTACHMENT_PATH + componentState.imageAttachmentObj.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                />
                        }
                        {
                            !isEmptyVariable(componentState.errors?.productAttachmentId) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productAttachmentId}</span>
                        }
                    </FieldGroupAddImageWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />
    </>
}

export default CreateUpdateGroup;

const FlexCenterLayout = styled.div`
    display:flex;
    align-items:center;
`;