import { UploadImageFieldWrapper, UploadImageFieldLayout } from "./imagelibrarydialogstyle";
import { TfiUpload } from "react-icons/tfi";

const UploadImage = ({ changeImage }) => {
    return <UploadImageFieldWrapper>
        <UploadImageFieldLayout>
            <input
                name="file"
                type="file"
                id="attachCommentImage"
                onChange={changeImage}
                multiple
            />
            <label for="attachCommentImage">
                <TfiUpload />
                <p>Upload</p>
            </label>
        </UploadImageFieldLayout>
    </UploadImageFieldWrapper>
}
export default UploadImage;