import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import { DisplayImageWrapper, DisplayImageLayout, SelectedIconBox, ImageOverlay } from "./imagelibrarydialogstyle";
import { FaCheck } from "react-icons/fa6";

const DisplayImage = ({ onClickImage, item, isSelected, isDisabled }) => {
    return <DisplayImageWrapper>
        <DisplayImageLayout>
            <button 
                onClick={isDisabled?()=>{}:() => onClickImage(item)} 
                className={isSelected ? "selectedImg" : ""}
            >
                {isSelected ? <SelectedIconBox><FaCheck /></SelectedIconBox> : null}
                <img src={ATTACHMENT_PATH + item.productAttachmentPath.replace("/images/", "/thumbnails/")} />
            </button>

            {isDisabled && <ImageOverlay />}
        </DisplayImageLayout>
    </DisplayImageWrapper>
}
export default DisplayImage;