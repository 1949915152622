import styled from "styled-components";
import {Colors} from "../../components/styles/colors";

export const ProfileWrapper = styled.div`
    padding: 20px;
    border-top:1px solid #ddd;
`;

export const BorderBox = styled.div`
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    border-radius: 4px;
`;

export const ProfileDetailsCol = styled.div`
    display:flex;
`;

export const ProfilePicLayout = styled.div`
    width:170px;
    // text-align:center;
    button{
        padding: 6px 20px;
        margin-top:10px;
        border:none;
        background: ${Colors.themeColorPrimary};
        color: ${Colors.whiteColor};
        border-radius: 4px;
        font-size: 13px;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
        }
    }
`;

export const ProfilePicInnerLayout = styled.div`
    position:relative;
`;

export const ProfilePicCol = styled.div`
    position:relative;

    svg{
        font-size: 150px;
    }
    img{
        width:150px;
        height:150px;
        border-radius:100%;
    }

    &:hover .delete-layout{
        display:flex;
        align-items:center;
        justify-content:center;
    }
`;

export const ProfileDescLayout = styled.div`
    margin-left:30px;
    margin-top:15px;
    h6{
        margin-bottom:5px;
    }
    p{
        margin-bottom: 5px;
    }
`;

export const DeleteLayout = styled.div`
    background:${Colors.themeColorPrimary+"99"};
    border-radius:100%;
    position:absolute;
    right:0;
    left:0;
    top:0;
    bottom:0;
    width:150px;
    height:150px;
    display:none;
    cursor: pointer;

    svg{
        height:40px;
        cursor:pointer;
        color:${Colors.whiteColor};
    }
`;

export const PasswordWrapper = styled.div`
    h5{
        margin-bottom:15px;
    }

    button{
        width: 100%;
        padding: 6px 20px;
        margin-top:10px;
        border:none;
        background: ${Colors.themeColorPrimary};
        color: ${Colors.whiteColor};
        border-radius: 4px;
        font-size: 13px;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
        }
    }
`;

export const PasswordCol = styled.div`
    
`;
export const PasswordFieldCol = styled.div`
    margin-bottom:15px;

    input{
        border: 1px solid ${Colors.modalInputBorderColor};
        border-radius:4px;
        width:100%;
        padding:8px;
        height:34px;
        box-sizing: border-box;
        font-size:13px;
    }

    label{
        font-size:12px;
        line-height:1;
        display:block;
        margin-bottom:5px;
        font-weight:500;
    }
`;
