import { useReducer } from 'react';
import { DEFAULT_RESULT_SIZE_IMAGE, INITIAL_ALERT_STATE } from '../../utils/constants'

const INITIAL_IMAGE_STATE = {
    isPageLoading: false,
    firstLoad: false,
    search: "",
    searchApiKey: "",
    status: "All",
    sort: "",
    sortDir: "",
    resultSize: DEFAULT_RESULT_SIZE_IMAGE,
    pageNo: 1,
    response: {},
    selectedIds: [],
    selectedItemsObj: {},

    isUploading: false,
    files: [],
    uploadObj: {},
    uploadIdx: -1,
}

const initialState = {
    ...INITIAL_IMAGE_STATE,

    isPageLoading: false,
    refreshFlag: false,
    resetSelectionFlag: false,

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET_STATE": {
            //First Time
            return {
                ...state,
                ...initialState,
                refreshFlag: true
            }
        }
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_RESPONSE": {
            return {
                ...state,
                firstLoad: true,
                alertDialog: { ...INITIAL_ALERT_STATE },
                refreshFlag: false, // Refresh is done so set it to false
                isPageLoading: false,
                response: action.response,
            }
        }
        case "SET_RESPONSE_AND_PAGE": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                refreshFlag: false, // Refresh is done so set it to false
                isPageLoading: false,
                pageNo: action.pageNo,
                response: action.response,
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "CHANGE_RESULT_SIZE": {
            return {
                ...state,
                resultSize: action.resultSize,
                refreshFlag: true, // Whenever the result size is changed we need to refresh the list
            }
        }
        case "SEARCH_INPUT_CHANGE": {
            return {
                ...state,
                search: action.value,
            }
        }
        case "SET_SORT_AND_DIR": {
            return {
                ...state,
                sort: action.sort,
                sortDir: action.sortDir,
                refreshFlag: true,
            }
        }
        case "RELOAD_IMAGE_LIST": {
            return {
                ...state,
                pageNo: 1,
                searchApiKey: state.search,
                refreshFlag: true,
            }
        }
        case "RELOAD_IMAGE_LIST_AFTER_DELETE": {
            return {
                ...state,
                selectedIds: [],
                selectedItemsObj: {},
                refreshFlag: true,
            }
        }
        case "SELECT_IMAGE": {
            const { imageItem, isMultiSelect } = action;
            const { productAttachmentId } = imageItem;
            let selectedItemsObj = {};

            let selectedIds = [...state.selectedIds];

            if (isMultiSelect) {
                if (!selectedIds.includes(productAttachmentId)) {
                    selectedIds.push(productAttachmentId);
                    selectedItemsObj = {
                        ...state.selectedItemsObj,
                        [productAttachmentId]: { ...imageItem }
                    }
                }else{
                    let index = selectedIds.indexOf(productAttachmentId);
                    selectedIds.splice(index,1);
                    selectedItemsObj = {
                        ...state.selectedItemsObj,
                    }
                    delete selectedItemsObj[productAttachmentId]
                }
            } else {
                selectedIds = [productAttachmentId];
                selectedItemsObj = {
                    [productAttachmentId]: { ...imageItem }
                }
            }

            return {
                ...state,
                selectedIds: [...selectedIds],
                selectedItemsObj: { ...selectedItemsObj }
            }
        }
        case "BEGIN_UPLOAD": {
            return {
                ...state,
                isUploading: true,
                files: action.files,
                uploadIdx: 0,
                uploadObj: action.uploadObj
            }
        }
        case "UPDATE_UPLOADING_STATUS": {
            return {
                ...state,
                uploadObj: { ...action.uploadObj },
                uploadIdx: state.uploadIdx + 1,
            }
        }
        case "END_UPLOAD": {
            return {
                ...state,
                uploadIdx: -1,
                isUploading: false,
                files: [],
                uploadObj: {},

                refreshFlag: true,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};