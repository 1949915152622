import Dropdown from 'react-bootstrap/Dropdown';
import { isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';
import { Colors } from '../styles/colors';
import styled from 'styled-components';
//write internal style only

const DropdownWrapper = styled.div`
    display:flex;
    flex-direction:column;

    span{
        font-size:13px;
        margin-bottom:0;
    }
    .dropdown .dropdown-toggle.btn{
        background:${Colors.dropdownDialogBtnBg};
        width:100%;
        border:none;
        padding:3px 10px;
        font-size:13px;
        color:${Colors.dropdownDialogBtnTextColor};
        border:none;
        height:34px;
        border-radius:4px;
        border:1px solid ${Colors.dropdownDialogBtnBorderColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0;
    }
    .dropdown .dropdown-toggle.btn:disabled{
        background:${Colors.inputDisabledBgColor};
    }
    .dropdown .dropdown-toggle.btn.show{
        background:${Colors.dropdownDialogBtnBgHover};
    }
    .dropdown .dropdown-toggle.btn:hover{
        background:${Colors.dropdownDialogBtnBgHover};
        color:${Colors.dropdownDialogBtnTextColor};
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
        background:${Colors.dropdownDialogBtnBgHover};
        color:${Colors.dropdownDialogBtnTextColor};
    }
    .dropdown-menu.show{
        padding:0;
        min-width:${props => props.minWidth ? props.minWidth : "200px"};
        width:100%;
        border-radius:5px;
        border:1px solid ${Colors.dropdownDialogBorderColor};
        overflow: hidden;
    }
    .dropdown-item{
        padding:10px 20px;
        background:${Colors.dropdownDialogItemBg};
        color:${Colors.dropdownDialogItemTextColor};

        &:first-child{
            border-top-left-radius:5px;
            border-top-right-radius:5px;
        }
        &:last-child{
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
        }
    }
    .dropdown-item:hover{
        background:${Colors.dropdownDialogItemBgHover} !important;
    }
`;

const BasicDropdown = (props) => {
    const { placeholder, selectedValue, label, options, dropdownItemClick, disabled,
        wrapperStyles, udf, minWidth } = props;
    return (<DropdownWrapper style={{ ...wrapperStyles }} minWidth={minWidth}>
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" disabled={disabled}>
                {isEmptyVariable(selectedValue) ? placeholder : selectedValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    !isEmptyArray(options) &&
                    options.map((option, idx) => (
                        <Dropdown.Item key={"basic" + idx} as="button" onClick={() => { dropdownItemClick(option, udf) }}>
                            {option[label]}
                        </Dropdown.Item>
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    </DropdownWrapper>
    );
}

export default BasicDropdown;