import React from 'react';
import styled from 'styled-components';
import { IoLogOut, IoSettingsSharp } from "react-icons/io5";
import ProfileDropdown from '../../dropdowns/profileDropdown';
import { useAuth } from '../../../hooks/useAuth';

const TopbarWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:7.5px 20px;
    height:57.5px;
    
    h5{
        margin:0;
        line-height:1;
        font-weight: 600;
    }
`;

const Topbar = (props) => {
    const { heading } = props;
    const { user } = useAuth();

    return <TopbarWrapper>
        <h5>{heading}</h5>
        <ProfileDropdown
            dropdownLabel={user.firstName + " " +user.lastName}
            dropdownList={
                [
                    {
                        option: "Profile",
                        optionUrl: "/profile",
                        optionIcon: <IoSettingsSharp />
                    },
                    {
                        option: "Logout",
                        optionUrl: "/logout",
                        optionIcon: <IoLogOut />
                    }
                ]
            }
            profilePic={user.profilePic}
        />
    </TopbarWrapper>
}

export default Topbar;