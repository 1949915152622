import { useReducer } from 'react';
import { INITIAL_ALERT_STATE } from '../../utils/constants'
import { ifEmptyReturnStr } from '../../utils/utilFunctions';

const INITIAL_TYPE_STATE = {
    isPageLoading: false,
    firstLoad: false,
    privacyPolicyContent: "",
    termsOfUseContent: "",
    refundPolicyContent: "",

    errors: {

    }
}

const initialState = {
    ...INITIAL_TYPE_STATE,

    isPageLoading: false,
    refreshFlag: false,

    //ALERT STATE
    alertDialog: { ...INITIAL_ALERT_STATE }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE }
            }
        }
        case "SET_ALERT": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                alertDialog: { ...action.alertState },
            }
        }
        case "SET_RESPONSE": {
            return {
                ...state,
                alertDialog: { ...INITIAL_ALERT_STATE },
                firstLoad: true,
                refreshFlag: false, // Refresh is done so set it to false
                isPageLoading: false,

                details: {...action.response}
            }
        }
        case "SHOW_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER": {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "SET_INPUT_VALUE": {
            return {
                ...state,
                [action.name]: action.value,
            }
        }
        case "SET_ERROR": {
            return {
                ...state,
                errors: {
                    [action.policyType]: action.error
                },
            }
        }
        case "RELOAD_LIST": {
            return {
                ...state,
                refreshFlag: true
            }
        }
        case "HIDE_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: false
            }
        }
        case "SHOW_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: true
            }
        }
        case "IMAGE_LIBRARY_SUBMIT": {
            return {
                ...state,
                showImageLibraryDialog: false,
                productAttachmentId: action.productAttachmentId,
                updateFlag: true
            }
        }
        case "UPDATE_COMPLETE": {
            return {
                ...state,
                productAttachmentId: "",
                updateFlag: false,
            }
        }
        case "DELETE_PROFILE_PIC_CLICK": {
            return {
                ...state,
                deleteFlag: true
            }
        }
        case "DELETE_PROFILE_PIC_COMPLETE": {
            return {
                ...state,
                deleteFlag: false
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};