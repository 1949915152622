import { useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { ATTACHMENT_PATH } from '../../utils/apiEndPoints';
import { isEmptyArray } from '../../utils/utilFunctions';
import ImageLibrary from '../imageLibraryDialog';

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const RichTextEditorField = (props) => {
    const { editorOnChange, placeholder, value, disabled, wrapperStyles,
        editorHeight } = props;
    const quillRef = useRef();

    const [componentState, setComponentState] = useState({
        showImageLibraryDialog: false,
    });

    const onClickImage = () => {
        setComponentState({
            showImageLibraryDialog: true
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        setComponentState({
            showImageLibraryDialog: false
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {

        if (!isEmptyArray(selectedImages)) {
            let imageAttachmentObj = selectedImages[0];
            const quill = quillRef.current.getEditor();
            // console.log(quill)
            const range = quill.getSelection();
            // console.log(range)
            quill.insertEmbed(range.index, 'image', ATTACHMENT_PATH + imageAttachmentObj.productAttachmentPath);
        }

        setComponentState({
            showImageLibraryDialog: false
        })
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                    ['clean']
                ],
                handlers: {
                    'image': onClickImage
                }
            }
        }), [])



    return <>
        <QuillWrapper editorHeight={editorHeight}>
            <div style={{ ...wrapperStyles }}>
                <ReactQuill
                    ref={quillRef} //UseRef
                    theme="snow"
                    value={value}
                    onChange={editorOnChange}
                    placeholder={placeholder}
                    modules={modules}
                    formats={formats}
                    readOnly={disabled}
                />
            </div>
        </QuillWrapper>

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />
    </>
}

export default RichTextEditorField;

const QuillWrapper = styled.div`
    .ql-editor{
        height:${props => props.editorHeight ? props.editorHeight : "auto"} !important;
        min-height: 150px;
        max-height: 400px;
    }
`;