import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import { SelectedImageInfoWrapper, SelectedImageLayout } from "./imagelibrarydialogstyle";

const SelectedImageInfo = ({ getImageInfo, showDeleteImageAlert, getLocalDateFromUTC }) => {
    return <SelectedImageInfoWrapper>
            <h6>Attachment Details</h6>
        <SelectedImageLayout>
            <img src={ATTACHMENT_PATH + getImageInfo("productAttachmentPath").replace("/images/", "/thumbnails/")} />
            <h6><b>{getImageInfo("productAttachmentPath").split("-_-:").pop()}</b></h6>
            <p>{getLocalDateFromUTC(getImageInfo("created_at"))}</p>
            <p>{getImageInfo("width") + " x " + getImageInfo("height") + " Pixels"}</p>
            <p>{getImageInfo("size") + " KB"}</p>
            <p>{"Usage Count: " + getImageInfo("usageCount")}</p>
            <button onClick={showDeleteImageAlert} className="selected-img-del-btn">Delete Permanently</button>
        </SelectedImageLayout>
    </SelectedImageInfoWrapper>
}

export default SelectedImageInfo;