import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import { useAuth } from "../../hooks/useAuth";
import { GET_CUSTOMERS, UPDATE_CUSTOMER_STATUS } from "../../utils/apiEndPoints";
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderWrapper, ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout, SearchBox
} from '../../components/styles/listingHeaderStyles';
import { BsSearch } from "react-icons/bs";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import Loader from "../../components/randomComponents/loader";
import NoResults from "../../components/randomComponents/noResults";
import NoRecords from "../../components/randomComponents/noRecords";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import StatusSpan from "../../components/randomComponents/statusSpan";

const CustomerList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [customerState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "customers", heading: "Customers" })
        getCustomers();
    }, []);

    useEffect(() => {
        if (customerState.refreshFlag) {
            getCustomers();
        }

    }, [customerState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.userId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.userId, "Active");
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const updateStatus = async (userId, status) => {
        try {
            await callAuthApi(UPDATE_CUSTOMER_STATUS, {
                userId: userId,
                status: status
            }, user, logout);

            getCustomers();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Customer",
                        message: "Are you sure you want to deactivate " + item.fullName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Customer",
                        message: "Are you sure you want to activate " + item.fullName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (customerState.search != customerState.searchApiKey) {
            dispatch({ type: "RELOAD_CUSTOMER_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, customerState.sort, customerState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getCustomers = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: customerState.search,
            sort: customerState.sort,
            sortDir: customerState.sortDir,
            resultSize: customerState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? customerState.pageNo : pageNo
        }

        if (customerState.status !== "All") {
            post.status = customerState.status === "Active"? "Active":"Inactive";
        }

        let response = {};
        try {
            response = await callAuthApi(GET_CUSTOMERS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }


    const getActionArr = (status) => {
        if (status === "Active") {
            return [DEACTIVATE_ACTION];
        } else {
            return [ACTIVATE_ACTION];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }

    return <ContentSection>
        {
            customerState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={customerState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="name, email, phone..."
                            value={customerState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>
                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={customerState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            customerState.firstLoad &&
            <>
                {
                    !isEmptyArray(customerState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Customer"}
                                    sort={customerState.sort}
                                    sortDir={customerState.sortDir}
                                    tableSortCol={"fullName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Email"}
                                    sort={customerState.sort}
                                    sortDir={customerState.sortDir}
                                    tableSortCol={"email"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Orders"}
                                    sort={customerState.sort}
                                    sortDir={customerState.sortDir}
                                    tableSortCol={"ordersCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Registration Date"}
                                    sort={customerState.sort}
                                    sortDir={customerState.sortDir}
                                    tableSortCol={"u.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                customerState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.fullName}
                                            <StatusSpan
                                                status = {item.status}
                                            />    
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.ordersCount}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>
                                            <TableDropdown
                                                actionArr={getActionArr(item.status)}
                                                onClickTableActionItem={onClickTableActionItem}
                                                item={item}
                                            />
                                        </td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getCustomers(e.selected + 1)}
                            pageRangeDisplayed={customerState.resultSize}
                            pageCount={getPageCount(customerState.response.count, customerState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={customerState.pageNo - 1}
                            totalRowCount = {customerState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(customerState.response.result) &&
                    !customerState.isPageLoading &&
                    !isEmptyVariable(customerState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(customerState.response.result) &&
                    !customerState.isPageLoading &&
                    isEmptyVariable(customerState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...customerState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default CustomerList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;