import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { DELETE_REVIEW, GET_REVIEWS, UPDATE_REVIEW_STATUS } from "../../utils/apiEndPoints";
import {
    APPROVE_ACTION,
    DELETE_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import { GetReducer } from "./reducer";

const ReviewList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [reviewState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "reviews", heading: "Reviews" })
        getReviews();
    }, []);

    useEffect(() => {
        if (reviewState.refreshFlag) {
            getReviews();
        }

    }, [reviewState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Delete":
                await deleteReview(selectedItem.reviewId);
                break;
            case "Approve":
                await updateStatus(selectedItem.reviewId, "Active");
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const updateStatus = async (reviewId, status) => {
        try {
            await callAuthApi(UPDATE_REVIEW_STATUS, {
                reviewId: reviewId,
                reviewStatus: status
            }, user, logout);

            getReviews();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Approve":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Type",
                        message: "Are you sure you want to approve this review?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Approve",
                        operation: "Approve",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Review",
                        message: "Are you sure you want to delete this review?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (reviewState.search != reviewState.searchApiKey) {
            dispatch({ type: "RELOAD_REVIEW_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, reviewState.sort, reviewState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getReviews = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: reviewState.search,
            sort: reviewState.sort,
            sortDir: reviewState.sortDir,
            resultSize: reviewState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? reviewState.pageNo : pageNo
        }

        if (reviewState.status !== "All") {
            post.status = reviewState.status === "Approved"? "Active":"Inactive";
        }

        let response = {};
        try {
            response = await callAuthApi(GET_REVIEWS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteReview = async (reviewId) => {
        try {
            await callAuthApi(DELETE_REVIEW, {
                reviewId: reviewId,
            }, user, logout);

            getReviews();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [{ ...DELETE_ACTION, textColor: Colors.tableDropdownDeleteTextColor }];
        } else {
            return [APPROVE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }

    return <ContentSection>
        {
            reviewState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={reviewState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search product names..."
                            value={reviewState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>
                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={reviewState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Approved" }, { status: "Pending" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            reviewState.firstLoad &&
            <>
                {
                    !isEmptyArray(reviewState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Product"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"p.productName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Customer"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"u_firstName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Rating"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"r.rating"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Review"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"r.review"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Review Date"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"r.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Status"}
                                    sort={reviewState.sort}
                                    sortDir={reviewState.sortDir}
                                    tableSortCol={"r.reviewStatus"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                reviewState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>{item.productName}</td>
                                        <td>{item.u_firstName+" "+item.u_lastName}</td>
                                        <td>{item.rating}</td>
                                        <td>{item.review}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>
                                            <StatusSpan
                                                status = {item.reviewStatus}
                                                displayStatus = {item.reviewStatus === "Active" ?"Approved":"Pending"}
                                            />
                                        </td>
                                        <td>
                                            <TableDropdown
                                                actionArr={getActionArr(item.reviewStatus)}
                                                onClickTableActionItem={onClickTableActionItem}
                                                item={item}
                                            />
                                        </td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getReviews(e.selected + 1)}
                            pageRangeDisplayed={reviewState.resultSize}
                            pageCount={getPageCount(reviewState.response.count, reviewState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={reviewState.pageNo - 1}
                            totalRowCount = {reviewState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(reviewState.response.result) &&
                    !reviewState.isPageLoading &&
                    !isEmptyVariable(reviewState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(reviewState.response.result) &&
                    !reviewState.isPageLoading &&
                    isEmptyVariable(reviewState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...reviewState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default ReviewList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;