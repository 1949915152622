import { useEffect, useState } from "react"
import { Modal, Row, Col } from "react-bootstrap";
import { isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";
import ReactCreatableSelect from "../../components/dropdowns/reactCreatableSelect";
import styled from "styled-components";
import { FaRegTrashCan } from "react-icons/fa6";
import { CancelButton, CustomModal, ModalButtonIconLayout, ModalButtonLayout, 
    ModalButtonSecondary, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const defaultOptions = [
    {
        productOptionName: "Size"
    },
    {
        productOptionName: "Color"
    },
    {
        productOptionName: "Material"
    }
]

const initialState = {
    selectedProductOptionNameObj: {},
    optionValues: [],
    errors: {
    }
}

const CreateUpdateProductOption = (props) => {
    const { addEditOptionsDialog, onClickSubmit, onClickCancel, productOptionNamesArr } = props;
    const [dialogState, setDialogState] = useState(initialState);

    useEffect(() => {
        if (addEditOptionsDialog.showAddEditDialog) {
            setDialogState({
                ...initialState,
                selectedProductOptionNameObj: {
                    productOptionName: "",
                },
                optionValues: [
                    {
                        productOptionValue: "",
                        productOptionValueId: "",
                        error: "",
                    },
                    {
                        productOptionValue: "",
                        productOptionValueId: "",
                        error: "",
                    }
                ]
            })
        }
    }, [addEditOptionsDialog.showAddEditDialog]);

    const onSelectProductOptionName = (selectedOptionName) => {
        let optionNameObj = {...selectedOptionName}
        if(selectedOptionName.__isNew__){
            optionNameObj =  {
                productOptionName:selectedOptionName.label
            }
        }

        setDialogState({
            ...dialogState,
            selectedProductOptionNameObj: { 
                ...optionNameObj 
            }
        })
    }

    const deleteOption = (idx) => {
        let optionValues = [...dialogState.optionValues];
        optionValues.splice(idx, 1);

        setDialogState({
            ...dialogState,
            optionValues: [...optionValues]
        })
    }

    const addNewOption = () => {
        let optionValues = [...dialogState.optionValues];
        optionValues.push({
            productOptionValue: "",
            productOptionValueId: "",
        });

        setDialogState({
            ...dialogState,
            optionValues: [...optionValues]
        })
    }

    const inputChangeOptionValue = (e) => {
        let idx = e.target.name;
        let value = e.target.value;

        let optionValues = [...dialogState.optionValues];
        optionValues[idx].productOptionValue = value;
        optionValues[idx].error = "";

        setDialogState({
            ...dialogState,
            optionValues: [...optionValues]
        })
    }

    const onClickSubmitBtn = () => {
        const { selectedProductOptionNameObj, optionValues } = dialogState;
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(selectedProductOptionNameObj?.productOptionName)) {
            hasErrors = true;
            errors.productOptionName = "Please enter the option name"
        }

        if (isEmptyArray(optionValues)) {
            hasErrors = true;
            errors.optionValues = "Please enter atleast one option value"
        }

        let optionValuesTemp = [];
        if (!isEmptyArray(optionValues)) {
            optionValuesTemp = optionValues.map(option => {
                if (isEmptyVariable(option.productOptionValue)) {
                    hasErrors = true;
                    option.error = "Please enter option value"
                    return option;
                } else {
                    return option;
                }
            })
        }

        if (!hasErrors) {
            let finalOptionsObj = {
                productOptionName: selectedProductOptionNameObj.productOptionName,
                optionValues: optionValues.map((option, idx) => {
                    return {
                        ...option,
                        feIndex: idx,
                    }
                }),
                optionValuesLastIdx: optionValues.length
            }
            onClickSubmit(finalOptionsObj, addEditOptionsDialog.optionsIndex);
        } else {
            setDialogState({
                ...dialogState,
                optionValues: optionValuesTemp,
                errors: { ...errors }
            })
        }

    }

    return <>
        <CustomModal show={addEditOptionsDialog.showAddEditDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{"Create Product Option"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <label>Option Name</label>
                        <ReactCreatableSelect
                            isLoading={false}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"optionlist"}
                            options={[...productOptionNamesArr, ...defaultOptions]}
                            isMulti={false}
                            placeholder={"Option Name"}
                            labelField={"productOptionName"}
                            valueField={"productOptionName"}
                            onChange={onSelectProductOptionName}
                            value={Object.keys(dialogState.selectedProductOptionNameObj).length === 0 ? null : dialogState.selectedProductOptionNameObj}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productOptionName) &&
                            <span className="err-span m-b-10">{dialogState.errors.productOptionName}</span>
                        }
                    </Col>
                    <Col md="12">
                        {
                            dialogState.optionValues.map((optionValue, optionValuesIdx) => {
                                return <>
                                    <FlexLayout key={"optionValue" + (optionValuesIdx + 1)}>
                                        <InputLayout>
                                            <label>Option Value {(optionValuesIdx + 1)}</label>
                                            <input
                                                // placeholder={"Enter Option Value"}
                                                value={optionValue.productOptionValue}
                                                name={optionValuesIdx}
                                                onChange={inputChangeOptionValue}
                                                className="m-b-20"
                                            />
                                        </InputLayout>
                                        <ModalButtonIconLayout onClick={() => deleteOption(optionValuesIdx)} margin={"0 0 0 5px"}>
                                            <FaRegTrashCan />
                                        </ModalButtonIconLayout>
                                    </FlexLayout>
                                    {
                                        !isEmptyVariable(optionValue?.error) &&
                                        <span className="err-span m-b-10">{optionValue?.error}</span>
                                    }
                                </>
                            })
                        }
                        {
                            !isEmptyVariable(dialogState.errors?.optionValues) &&
                            <span className="err-span m-b-10">{dialogState.errors.optionValues}</span>
                        }
                    </Col>

                    <Col md="12">
                        <ModalButtonSecondary className = "m-b-15" onClick={addNewOption}>Add Option</ModalButtonSecondary>
                    </Col>
                </Row>

                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>
    </>
}

export default CreateUpdateProductOption;

const FlexLayout = styled.div`
    display:flex;
    align-items:center;
`;
const InputLayout = styled.div`
    flex-grow: 1;
`;