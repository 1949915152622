import { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaArrowLeftLong, FaTrashCan } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import AlertDialog from '../../components/dialogs/alertDialog';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import ReactCreatableSelect from '../../components/dropdowns/reactCreatableSelect';
import ReactSelect from '../../components/dropdowns/reactSelect';
import ImageLibrary from '../../components/imageLibraryDialog';
import Checkbox from '../../components/input/checkbox';
import RadioButton from '../../components/input/radioButton';
import RichTextEditorField from '../../components/input/richTextEditor';
import AddImageButton from '../../components/randomComponents/addImageButton';
import DisplayImageLayoutBtn from '../../components/randomComponents/displayImageLayoutBtn';
import { ButtonIconLayout, FieldGroupAddImageWrapper, FieldGroupWrapper, FormWrapper } from '../../components/styles/addEditFormStyles';
import {
    BackButton,
    ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from '../../hooks/useAuth';
import {
    ADD_PRODUCT, ATTACHMENT_PATH, GET_ADD_PRODUCTS_MASTERDATA,
    GET_PRODUCT_DETAILS,
    UPDATE_PRODUCT
} from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';
import {
    AddNewOptionValueLayout,
    AddOptionButton, ButtonIconLayoutOptions,
    Col5, MediaLayout,
    OptionNameBtnLayout,
    OptionNameLayout,
    OptionValueBtnLayout,
    OptionValueLayout,
    OptionValueWrapper,
    OptionWrapper,
    Row5
} from './addEditProduct.style';
import AddEditProductOptionValue from './addEditProductOptionValue';
import CreateProductOption from './addEditProductOptions';
import EditProductOptionName from './editProductOptionName';
import EditProductVariantProperties from './editProductVariantProperties';
import ProductUdf from './productUdf';
import { GetReducer } from './reducerCreateProduct';
import VariantTable from './variantsTable';

const CreateUpdateGroup = (props) => {
    const { addEditComp, onClickSubmit, onClickCancel } = props;

    const [componentState, dispatch] = GetReducer();
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditComp.showAddEditComp) {
            getMasterData();
        }
    }, [addEditComp.showAddEditComp]);

    const getMasterData = async () => {
        try {
            //get master data
            let response = await callAuthApi(GET_ADD_PRODUCTS_MASTERDATA, {}, user, logout);
            if (addEditComp.isProductEdit) {
                //now get product details
                let prodDetails = await callAuthApi(GET_PRODUCT_DETAILS, {
                    productUniqueId: addEditComp.productUniqueId
                }, user, logout);
                dispatch({
                    type: "SET_MASTER_AND_DETAILS",
                    masterData: response.data,
                    productDetails: prodDetails.data.productDetails
                })
            } else {
                dispatch({
                    type: "SET_MASTER_RESPONSE",
                    masterData: response.data
                })
            }
        } catch (alertErr) {
            dispatch({
                type: "SET_API_ERR",
                apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            })

            setTimeout(() => {
                dispatch({
                    type: "SET_API_ERR",
                    apiErr: ""
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //Options and Variants Functions
    const showCreateProductOptionDialog = () => {
        dispatch({
            type: "SHOW_CREATE_OPTIONS_DIALOG",
        })
    }

    const onClickProductOptionsDialogCancel = () => {
        dispatch({
            type: "RESET_CREATE_OPTIONS_DIALOG",
        })
    }

    const onClickProductOptionsDialogSubmit = (finalOptionsObj, optionsIndex) => {
        dispatch({
            type: "SUBMIT_CREATE_OPTIONS_DIALOG",
            optionsIndex: optionsIndex,
            finalOptionsObj: finalOptionsObj
        })
    }

    const editOptionName = (optionsIndex) => {
        dispatch({
            type: "SHOW_EDIT_OPTION_NAME_DIALOG",
            optionsIndex: optionsIndex
        })
    }

    const onClickEditOptionNameDialogSubmit = (productOptionName, optionsIndex) => {
        dispatch({
            type: "SUBMIT_EDIT_OPTION_NAME_DIALOG",
            productOptionName: productOptionName,
            optionsIndex: optionsIndex
        })
    }

    const onClickEditOptionNameDialogCancel = () => {
        dispatch({
            type: "RESET_EDIT_OPTION_NAME_DIALOG",
        })
    }

    const addEditOptionValue = (isEdit, optionsIndex, optionValuesIdx) => {
        dispatch({
            type: "SHOW_ADD_EDIT_OPTION_VALUE_DIALOG",
            isEdit: isEdit,
            optionsIndex: optionsIndex,
            optionValuesIdx: optionValuesIdx,
        })
    }

    const onClickEditOptionValueDialogSubmit = (productOptionValue, optionsIndex, optionValuesIdx) => {
        dispatch({
            type: "SUBMIT_ADD_EDIT_OPTION_VALUE_DIALOG",
            productOptionValue: productOptionValue,
            optionsIndex: optionsIndex,
            optionValuesIdx: optionValuesIdx,
        })
    }

    const onClickEditOptionValueDialogCancel = () => {
        dispatch({
            type: "RESET_ADD_EDIT_OPTION_VALUE_DIALOG",
        })
    }

    const deleteOption = (optionsIndex) => {
        let name = componentState.productOptions[optionsIndex].productOptionName;
        dispatch({
            type: "SET_ALERT",
            alertState: {
                showAlertDialog: true,
                heading: "Delete Option",
                message: "Are you sure you want to delete " + name + " option?",
                type: "ALERT",
                cancelBtn: true,
                okayBtn: true,
                cancelBtnLabel: "Cancel",
                okayBtnLabel: "Delete",
                operation: "DeleteOption",
                selectedItem: { optionsIndex: optionsIndex },
            }
        });
    }

    const deleteOptionValue = (optionsIndex, optionValuesIdx) => {
        let name = componentState.productOptions[optionsIndex].optionValues[optionValuesIdx].productOptionValue;
        dispatch({
            type: "SET_ALERT",
            alertState: {
                showAlertDialog: true,
                heading: "Delete Option",
                message: "Are you sure you want to delete " + name + " option value?",
                type: "ALERT",
                cancelBtn: true,
                okayBtn: true,
                cancelBtnLabel: "Cancel",
                okayBtnLabel: "Delete",
                operation: "DeleteOptionValue",
                selectedItem: { optionsIndex: optionsIndex, optionValuesIdx: optionValuesIdx },
            }
        });
    }

    const showEditVariantDialog = (op1FeIdx, op2FeIdx) => {
        dispatch({
            type: "SHOW_EDIT_VARIANT_DIALOG",
            op1FeIdx: op1FeIdx,
            op2FeIdx: op2FeIdx
        })
    }

    const onClickEditVariantDialogCancel = () => {
        dispatch({
            type: "RESET_EDIT_VARIANT_DIALOG",
        })
    }

    const onClickEditVariantDialogSubmit = (variant) => {
        dispatch({
            type: "SUBMIT_EDIT_VARIANT_DIALOG",
            variant: variant,
        })
    }

    //Alert Dialog Functions
    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT"
        })
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "DeleteOption":
                dispatch({
                    type: "DELETE_OPTION",
                    optionsIndex: selectedItem.optionsIndex
                });
                break;
            case "DeleteOptionValue":
                dispatch({
                    type: "DELETE_OPTION_VALUE",
                    optionsIndex: selectedItem.optionsIndex,
                    optionValuesIdx: selectedItem.optionValuesIdx
                });
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    //Input Field Functions
    const inputChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: e.target.value
        })
    }

    const handleDecimalInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9.]/g, '');

        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue
        })
    };

    const handleUrlHandle = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, '-');
        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue.toLowerCase()
        })
    }

    const handleIntegerInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9]/g, '');

        dispatch({
            type: "INPUT_CHANGE",
            name: e.target.name,
            value: newValue
        })
    };

    const editorOnChange = (value) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "productDescription",
            value: value
        })
    }

    const chargeTaxHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "chargeTax",
            value: e.target.checked ? "Y" : "N"
        })
    }

    const hasOptionsHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "hasOptions",
            value: e.target.name
        })
    }

    const trackQuantityHandleChange = (e) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "trackQuantity",
            value: e.target.checked ? "Y" : "N"
        })
    }

    const udfInputChange = (name, value) => {
        dispatch({
            type: "SET_UDF_VALUE",
            name: name,
            value, value
        })
    }
    //Dropdown Functions
    const productStatusDropdownItemClick = (item) => {
        dispatch({
            type: "INPUT_CHANGE",
            name: "productStatus",
            value: item.productStatus
        })
    }

    const onSelectVendor = (selectedOption) => {
        dispatch({
            type: "SELECT_VENDOR",
            selectedOption: selectedOption
        })
    }

    const onSelectProductType = (selectedOption) => {
        dispatch({
            type: "SELECT_PRODUCT_TYPE",
            selectedOption: selectedOption
        })
    }

    const onSelectProductGroups = (selectedOption) => {
        dispatch({
            type: "SELECT_PRODUCT_GROUPS",
            selectedOption: selectedOption
        })
    }

    const onSelectProductTags = (selectedOption) => {
        dispatch({
            type: "SELECT_PRODUCT_TAGS",
            selectedOption: selectedOption
        })
    }

    //Image & Image Library Functions
    const showImageLibrary = (type) => {
        dispatch({
            type: "SHOW_IMAGE_LIBRARY",
            sourceType: type,
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        dispatch({
            type: "HIDE_IMAGE_LIBRARY",
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            dispatch({
                type: "ONCLICK_SUBMIT_IMAGE_LIBRARY",
                selectedImages: selectedImages
            })
        }
    }

    const deletePrimaryImage = () => {
        dispatch({
            type: "DELETE_PRIMARY_IMAGE"
        })
    }

    const deleteImage = (imageId) => {
        dispatch({
            type: "DELETE_IMAGE",
            imageId: imageId
        })
    }

    //Util Functions
    const getProfit = () => {
        const { productPrice, productDiscountedPrice, productCostPerItem } = componentState;
        if (isEmptyVariable(productPrice))
            return null;

        let actualPriceStr = productPrice;
        if (!isEmptyVariable(productDiscountedPrice)) {
            actualPriceStr = productDiscountedPrice;
        }

        if (isEmptyVariable(productCostPerItem)) {
            return null;
        }

        try {
            let actualPrice = parseFloat(actualPriceStr);
            let costPerItem = parseFloat(productCostPerItem);

            return (actualPrice - costPerItem) + "";

        } catch (e) {
            return null;
        }
    }

    const getProfitPercentage = () => {
        const { productPrice, productDiscountedPrice, productCostPerItem } = componentState;
        if (isEmptyVariable(productPrice))
            return null;

        let actualPriceStr = productPrice;
        if (!isEmptyVariable(productDiscountedPrice)) {
            actualPriceStr = productDiscountedPrice;
        }

        if (isEmptyVariable(productCostPerItem)) {
            return null;
        }

        try {
            let actualPrice = parseFloat(actualPriceStr);
            let costPerItem = parseFloat(productCostPerItem);
            let profit = (actualPrice - costPerItem);
            let profitPerc = (profit / costPerItem) * 100;
            return profitPerc.toFixed(2);

        } catch (e) {
            return null;
        }
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.productName)) {
            hasErrors = true;
            errors.productName = "Please enter the product name"
        }

        if (isEmptyVariable(componentState.productDescription)) {
            hasErrors = true;
            errors.productDescription = "Please enter the description"
        }

        if (isEmptyVariable(componentState.seoTitle)) {
            hasErrors = true;
            errors.seoTitle = "Please enter the SEO title"
        }

        if (isEmptyVariable(componentState.seoDescription)) {
            hasErrors = true;
            errors.seoDescription = "Please enter the SEO description"
        }

        if (isEmptyVariable(componentState.urlHandle)) {
            hasErrors = true;
            errors.urlHandle = "Please enter the URL handle"
        }

        if (isEmptyVariable(componentState.selectedVendor?.vendorName)) {
            hasErrors = true;
            errors.vendorName = "Please select the vendor"
        }

        if (isEmptyVariable(componentState.selectedProductType?.productTypeName)) {
            hasErrors = true;
            errors.productTypeName = "Please select the product type"
        }

        if (isEmptyVariable(componentState.primaryImageAttachmentObj?.productAttachmentId)) {
            hasErrors = true;
            errors.productAttachmentId = "Please select the primary image"
        }

        //If product has no options
        if (componentState.hasOptions === "N") {
            if (isEmptyVariable(componentState.productPrice)) {
                hasErrors = true;
                errors.productPrice = "Please enter the product price"
            }

            if (componentState.trackQuantity === "Y" && isEmptyVariable(componentState.productQuantity)) {
                hasErrors = true;
                errors.productQuantity = "Please enter the product quantity"
            }

            if (componentState.chargeTax === "Y" && isEmptyVariable(componentState.taxPercentage)) {
                hasErrors = true;
                errors.taxPercentage = "Please enter the tax percentage"
            }
        } else {
            //now check if the product is having 1 option or 2 options
            if (componentState.productOptions.length === 1) {
                Object.values(componentState.variants).forEach(variant => {
                    if (isEmptyVariable(variant.productPrice)) {
                        hasErrors = true;
                        errors.variantError = "Please fill all the mandatory fields"
                    }

                    if (variant.trackQuantity === "Y" && isEmptyVariable(variant.productQuantity)) {
                        hasErrors = true;
                        errors.variantError = "Please fill all the mandatory fields"
                    }

                    if (variant.chargeTax === "Y" && isEmptyVariable(variant.taxPercentage)) {
                        hasErrors = true;
                        errors.variantError = "Please fill all the mandatory fields"
                    }
                })
            } else if (componentState.productOptions.length === 2) {
                Object.values(componentState.variants).forEach(firstLevelVariant => {
                    Object.values(firstLevelVariant).forEach(secondLevelVariant => {
                        if (isEmptyVariable(secondLevelVariant.productPrice)) {
                            hasErrors = true;
                            errors.variantError = "Please fill all the mandatory fields"
                        }

                        if (secondLevelVariant.trackQuantity === "Y" && isEmptyVariable(secondLevelVariant.productQuantity)) {
                            hasErrors = true;
                            errors.variantError = "Please fill all the mandatory fields"
                        }

                        if (secondLevelVariant.chargeTax === "Y" && isEmptyVariable(secondLevelVariant.taxPercentage)) {
                            hasErrors = true;
                            errors.variantError = "Please fill all the mandatory fields"
                        }
                    })
                })
            } else {
                hasErrors = true;
                errors.optionError = "Please add options"
            }
        }


        dispatch({
            type: "SET_ERRORS",
            errors: errors
        })

        return hasErrors;
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditComp.isProductEdit) {
                    await updateProduct();
                } else {
                    await addNewProduct();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                dispatch({
                    type: "SET_API_ERR",
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    dispatch({
                        type: "SET_API_ERR",
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            // setTimeout(() => {
            //     dispatch({
            //         type: "SET_ERRORS",
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const addNewProduct = async () => {
        let pricingInfo = {};
        let productOptions = [];
        let variants = {};

        if (componentState.hasOptions === "Y") {
            productOptions = [...componentState.productOptions];
            variants = { ...componentState.variants };
        } else {
            pricingInfo = {
                productPrice: componentState.productPrice,
                productDiscountedPrice: componentState.productDiscountedPrice,
                productCostPerItem: componentState.productCostPerItem,
                chargeTax: componentState.chargeTax,
                taxPercentage: componentState.taxPercentage,

                trackQuantity: componentState.trackQuantity,
                productQuantity: componentState.productQuantity,
                productSKU: componentState.productSKU,
                productBarcode: componentState.productBarcode,
            }
        }

        let udf =[];
        if (!isEmptyArray(componentState.masterData?.productUdfs?.result)) {
            udf = componentState.masterData.productUdfs.result.map(udf => {
                return {
                    ...udf,
                    productUdfValue: ifEmptyReturnStr(componentState.udfValueMap[udf.productUdfId], "")
                }
            })
        }

        await callAuthApi(ADD_PRODUCT, {
            productName: componentState.productName,
            productDescription: componentState.productDescription,
            productStatus: componentState.productStatus,

            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            hasOptions: componentState.hasOptions,

            vendor: JSON.stringify(componentState.selectedVendor),
            productType: JSON.stringify(componentState.selectedProductType),
            productGroupIds: JSON.stringify(componentState.selectedProductGroups.map(group => group.productGroupId)),
            productTags: JSON.stringify(componentState.selectedProductTags),
            selectedMediaImages: JSON.stringify(componentState.selectedMediaImages),

            productAttachmentId: componentState.primaryImageAttachmentObj.productAttachmentId,

            ...pricingInfo,
            productOptions: JSON.stringify(productOptions),
            productVariants: JSON.stringify(variants),

            productUdfValues: JSON.stringify(udf)

        }, user, logout)
    }

    const updateProduct = async () => {
        let pricingInfo = {};
        let productOptions = [];
        let variants = {};

        if (componentState.hasOptions === "Y") {
            productOptions = [...componentState.productOptions];
            variants = { ...componentState.variants };
        } else {
            pricingInfo = {
                productPrice: componentState.productPrice,
                productDiscountedPrice: componentState.productDiscountedPrice,
                productCostPerItem: componentState.productCostPerItem,
                chargeTax: componentState.chargeTax,
                taxPercentage: componentState.taxPercentage,

                trackQuantity: componentState.trackQuantity,
                productQuantity: componentState.productQuantity,
                productSKU: componentState.productSKU,
                productBarcode: componentState.productBarcode,
            }
        }

        let udf =[];
        if (!isEmptyArray(componentState.masterData?.productUdfs?.result)) {
            udf = componentState.masterData.productUdfs.result.map(udf => {
                return {
                    ...udf,
                    productUdfValue: ifEmptyReturnStr(componentState.udfValueMap[udf.productUdfId], "")
                }
            })
        }

        await callAuthApi(UPDATE_PRODUCT, {
            productUniqueId: addEditComp.productUniqueId,
            productName: componentState.productName,
            productDescription: componentState.productDescription,
            productStatus: componentState.productStatus,

            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            hasOptions: componentState.hasOptions,

            vendor: JSON.stringify(componentState.selectedVendor),
            productType: JSON.stringify(componentState.selectedProductType),
            productGroupIds: JSON.stringify(componentState.selectedProductGroups.map(group => group.productGroupId)),
            productTags: JSON.stringify(componentState.selectedProductTags),
            selectedMediaImages: JSON.stringify(componentState.selectedMediaImages),

            productAttachmentId: componentState.primaryImageAttachmentObj.productAttachmentId,

            ...pricingInfo,
            productOptions: JSON.stringify(productOptions),
            productVariants: JSON.stringify(variants),

            productUdfValues: JSON.stringify(udf)
        }, user, logout);
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{addEditComp.isProductEdit ? "Edit Product" : "Add Product"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={8}>
                    <FieldGroupWrapper>
                        <label>Product Name*</label>
                        <input
                            placeholder={"Enter Product Name"}
                            value={componentState.productName}
                            name="productName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productName) &&
                            <span className="err-span m-b-10">{componentState.errors.productName}</span>
                        }

                        <label>Description*</label>
                        <RichTextEditorField
                            value={componentState.productDescription}
                            editorOnChange={editorOnChange}
                            placeholder="Enter the Description"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productDescription) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productDescription}</span>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Media</h6>
                        {
                            isEmptyArray(componentState.selectedMediaImages)
                                ?
                                <AddImageButton
                                    text="Add Media"
                                    showImageLibrary={() => showImageLibrary('media')}
                                />
                                :
                                <Row5>
                                    {
                                        componentState.selectedMediaImages.map((item, mediaIdx) => {
                                            return <Col5 md={3} key={"media" + mediaIdx}>
                                                <MediaLayout>
                                                    <DisplayImageLayoutBtn
                                                        deleteImage={() => deleteImage(item.productAttachmentId)}
                                                        imageSrc={ATTACHMENT_PATH + item.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                                        layoutHeight="140px"
                                                        actionBtnFontSize="15px"
                                                        actionBtnPadding="10px"
                                                        showEdit="N"
                                                    />
                                                </MediaLayout>
                                            </Col5>
                                        })

                                    }
                                    <Col5 md={3}>
                                        <AddImageButton
                                            text="Add Media"
                                            showImageLibrary={() => showImageLibrary('media')}
                                            layoutHeight={"138px"}
                                        />
                                    </Col5>
                                </Row5>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Does this product have options?</h6>
                        <Row>
                            <Col md={2}>
                                <RadioButton
                                    radioButtonId={"hasOptionsYes"}
                                    radioButtonLabel={"Yes"}
                                    handleChange={hasOptionsHandleChange}
                                    name="Y"
                                    checked={componentState.hasOptions === "Y"}
                                    disabled={addEditComp.isProductEdit}
                                />
                            </Col>
                            <Col md={2}>
                                <RadioButton
                                    radioButtonId={"hasOptionsNo"}
                                    radioButtonLabel={"No"}
                                    handleChange={hasOptionsHandleChange}
                                    name="N"
                                    checked={componentState.hasOptions === "N"}
                                    disabled={addEditComp.isProductEdit}
                                />
                            </Col>
                        </Row>
                    </FieldGroupWrapper>

                    {
                        componentState.hasOptions === "N" &&
                        <FieldGroupWrapper>
                            <h6>Pricing</h6>
                            <Row>
                                <Col md={4}>
                                    <label>Price*</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={componentState.productPrice}
                                        name="productPrice"
                                        onChange={handleDecimalInputChange}
                                        className="m-b-20"
                                    />
                                    {
                                        !isEmptyVariable(componentState.errors?.productPrice) &&
                                        <span className="err-span m-b-10">{componentState.errors.productPrice}</span>
                                    }
                                </Col>
                                <Col md={4}>
                                    <label>Discounted Price</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={componentState.productDiscountedPrice}
                                        name="productDiscountedPrice"
                                        onChange={handleDecimalInputChange}
                                        className="m-b-20"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <label>Cost Per Item</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={componentState.productCostPerItem}
                                        name="productCostPerItem"
                                        onChange={handleDecimalInputChange}
                                        className="m-b-20"
                                    />
                                </Col>
                                <Col md={4}>
                                    <label>Profit</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={getProfit()}
                                        className="m-b-20 input-disabled"
                                        disabled
                                    />
                                </Col>
                                <Col md={4}>
                                    <label>Profit %</label>
                                    <input
                                        placeholder={"0%"}
                                        value={getProfitPercentage()}
                                        className="m-b-20 input-disabled"
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <label className='m-t-15'></label>
                                    <Checkbox
                                        checkBoxId={"chargetax"}
                                        checkboxLabel={"Charge Tax"}
                                        handleChange={chargeTaxHandleChange}
                                        checked={componentState.chargeTax === "Y"}
                                    />
                                    <div className='m-b-15'></div>
                                </Col>

                                <Col md={6}>
                                    <label>Tax Percentage{componentState.chargeTax === "Y" ? "*" : ""}</label>
                                    <input
                                        placeholder={"0.00"}
                                        value={componentState.taxPercentage}
                                        name="taxPercentage"
                                        onChange={handleDecimalInputChange}
                                        className={componentState.chargeTax === "N" ? "m-b-20 input-disabled" : "m-b-20"}
                                        disabled={componentState.chargeTax === "N"}
                                    />
                                    {
                                        !isEmptyVariable(componentState.errors?.taxPercentage) &&
                                        <span className="err-span m-b-10">{componentState.errors.taxPercentage}</span>
                                    }
                                </Col>
                            </Row>

                        </FieldGroupWrapper>
                    }

                    {
                        componentState.hasOptions === "N" &&
                        <FieldGroupWrapper>
                            <h6>Inventory</h6>
                            <Row>
                                <Col md={12}>
                                    <Checkbox
                                        checkBoxId={"trackquantity"}
                                        checkboxLabel={"Track Quantity"}
                                        handleChange={trackQuantityHandleChange}
                                        checked={componentState.trackQuantity === "Y"}
                                    />
                                    <div className='m-b-15'></div>
                                </Col>
                                <Col md={6}>
                                    <label>Quantity{componentState.trackQuantity === "N" ? "" : "*"}</label>
                                    <input
                                        placeholder={"0"}
                                        value={componentState.productQuantity}
                                        name="productQuantity"
                                        onChange={handleIntegerInputChange}
                                        className={componentState.trackQuantity === "N" ? "m-b-20 input-disabled" : "m-b-20"}
                                        disabled={componentState.trackQuantity === "N"}
                                    />
                                    {
                                        !isEmptyVariable(componentState.errors?.productQuantity) &&
                                        <span className="err-span m-b-10">{componentState.errors.productQuantity}</span>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <label>SKU (Stock Keeping Unit)</label>
                                    <input
                                        placeholder={"Enter SKU"}
                                        value={componentState.productSKU}
                                        name="productSKU"
                                        onChange={inputChange}
                                        className="m-b-0"
                                    />
                                </Col>

                                <Col md={6}>
                                    <label>Barcode</label>
                                    <input
                                        placeholder={"Enter Barcode"}
                                        value={componentState.productBarcode}
                                        name="productBarcode"
                                        onChange={inputChange}
                                        className="m-b-0"
                                    />
                                </Col>
                            </Row>
                        </FieldGroupWrapper>
                    }

                    {
                        componentState.hasOptions === "Y" &&
                        <FieldGroupWrapper>
                            <h6>Product Options</h6>
                            {
                                !isEmptyArray(componentState.productOptions) &&
                                componentState.productOptions.map((option, optionsIdx) => {
                                    return <OptionWrapper key={"optionsvalue" + optionsIdx}>
                                        <OptionNameLayout>
                                            {/* TODO To be deleted */}
                                            <h6>{option.productOptionName + " " + option.optionValuesLastIdx}</h6>
                                            <OptionNameBtnLayout>
                                                <ButtonIconLayout onClick={() => editOptionName(optionsIdx)}>
                                                    <MdModeEdit />
                                                </ButtonIconLayout>
                                                <ButtonIconLayout onClick={() => deleteOption(optionsIdx)}>
                                                    <FaTrashCan />
                                                </ButtonIconLayout>
                                            </OptionNameBtnLayout>
                                        </OptionNameLayout>
                                        <OptionValueWrapper>
                                            {
                                                option.optionValues.map((optionValue, optionValuesIdx) => {
                                                    return <OptionValueLayout key={"optionvalue" + optionValuesIdx}>
                                                        <span>{optionValue.productOptionValue + " " + optionValue.feIndex}</span>
                                                        <OptionValueBtnLayout>
                                                            <ButtonIconLayoutOptions onClick={() => addEditOptionValue(true, optionsIdx, optionValuesIdx)}>
                                                                <MdModeEdit />
                                                            </ButtonIconLayoutOptions>
                                                            <ButtonIconLayoutOptions onClick={() => deleteOptionValue(optionsIdx, optionValuesIdx)}>
                                                                <FaTrashCan />
                                                            </ButtonIconLayoutOptions>
                                                        </OptionValueBtnLayout>
                                                    </OptionValueLayout>
                                                })
                                            }
                                            <AddNewOptionValueLayout onClick={() => addEditOptionValue(false, optionsIdx)}><BsFillPlusCircleFill /></AddNewOptionValueLayout>
                                        </OptionValueWrapper>
                                    </OptionWrapper>
                                })
                            }
                            {
                                (componentState.productOptions.length < 2) &&
                                <AddOptionButton onClick={showCreateProductOptionDialog}>Add Options</AddOptionButton>
                            }
                            {
                                !isEmptyVariable(componentState.errors?.optionError) &&
                                <span className="err-span m-t-10 m-b-10">{componentState.errors.optionError}</span>
                            }
                        </FieldGroupWrapper>
                    }

                    {
                        !isEmptyArray(componentState.productOptions) &&
                        componentState.hasOptions === "Y" &&
                        <FieldGroupWrapper>
                            <h6>Product Variants</h6>
                            <VariantTable
                                variants={componentState.variants}
                                productOptions={componentState.productOptions}
                                showEditVariantDialog={showEditVariantDialog}
                            />
                            {
                                !isEmptyVariable(componentState.errors?.variantError) &&
                                <span className="err-span m-t-10 m-b-10">{componentState.errors.variantError}</span>
                            }
                        </FieldGroupWrapper>
                    }

                    <FieldGroupWrapper>
                        <h6>Search Engine</h6>
                        <label>Title*</label>
                        <input
                            placeholder={"Enter SEO Title"}
                            value={componentState.seoTitle}
                            name="seoTitle"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoTitle.length > 60 ? "red" : "#555555" }}>
                            {componentState.seoTitle.length + " of 60 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.seoTitle}</span>
                        }

                        <label>Description*</label>
                        <textarea
                            placeholder={"Enter SEO Description"}
                            value={componentState.seoDescription}
                            name="seoDescription"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoDescription.length > 320 ? "red" : "#555555" }}>
                            {componentState.seoDescription.length + " of 320 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoDescription) &&
                            <span className="err-span m-b-10">{componentState.errors.seoDescription}</span>
                        }

                        <label>URL Handle*</label>
                        <input
                            placeholder={"Enter URL Handle"}
                            value={componentState.urlHandle}
                            name="urlHandle"
                            onChange={handleUrlHandle}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.urlHandle) &&
                            <span className="err-span m-b-10">{componentState.errors.urlHandle}</span>
                        }
                    </FieldGroupWrapper>

                    {
                        !isEmptyArray(componentState.masterData?.productUdfs?.result) &&
                        <FieldGroupWrapper>
                            <h6>Custom Fields</h6>
                            <ProductUdf
                                productUdf={componentState.masterData.productUdfs.result}
                                udfValueMap={componentState.udfValueMap}
                                udfInputChange={udfInputChange}
                            />
                        </FieldGroupWrapper>

                    }
                </Col>

                <Col md={4}>
                    <FieldGroupAddImageWrapper>
                        <h6>Primary Image*</h6>
                        {
                            isEmptyVariable(componentState.primaryImageAttachmentObj?.productAttachmentId)
                                ?
                                <AddImageButton
                                    text="Add Image"
                                    showImageLibrary={showImageLibrary}
                                    layoutHeight={"200px"}
                                />
                                :
                                <DisplayImageLayoutBtn
                                    showImageLibrary={showImageLibrary}
                                    deleteImage={deletePrimaryImage}
                                    imageSrc={ATTACHMENT_PATH + componentState.primaryImageAttachmentObj.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                    layoutHeight="200px"
                                />
                        }
                        {
                            !isEmptyVariable(componentState.errors?.productAttachmentId) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productAttachmentId}</span>
                        }
                    </FieldGroupAddImageWrapper>

                    <FieldGroupWrapper>
                        <h6>Product Organisation</h6>

                        <label>Vendor*</label>
                        <ReactCreatableSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"vendorlist"}
                            options={componentState.masterData?.vendors?.result}
                            isMulti={false}
                            placeholder={"Select Vendor"}
                            labelField={"vendorName"}
                            valueField={"vendorId"}
                            onChange={onSelectVendor}
                            value={Object.keys(componentState.selectedVendor).length === 0 ? null : componentState.selectedVendor}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.vendorName) &&
                            <span className="err-span m-b-10">{componentState.errors.vendorName}</span>
                        }

                        <label>Product Type*</label>
                        <ReactCreatableSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"producttypelist"}
                            options={componentState.masterData?.productTypes?.result}
                            isMulti={false}
                            placeholder={"Select Product Type"}
                            labelField={"productTypeName"}
                            valueField={"productTypeId"}
                            onChange={onSelectProductType}
                            value={Object.keys(componentState.selectedProductType).length === 0 ? null : componentState.selectedProductType}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productTypeName) &&
                            <span className="err-span m-b-10">{componentState.errors.productTypeName}</span>
                        }

                        <label>Collections</label>
                        <ReactSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"collections"}
                            options={componentState.masterData?.productGroups?.result}
                            isMulti={true}
                            placeholder={"Select Collections"}
                            labelField={"productGroupName"}
                            valueField={"productGroupId"}
                            onChange={onSelectProductGroups}
                            value={isEmptyArray(componentState.selectedProductGroups) ? null : componentState.selectedProductGroups}
                        />

                        <label>Product Tags</label>
                        <ReactCreatableSelect
                            isLoading={componentState.componentDidMount === "N"}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"producttaglist"}
                            options={componentState.masterData?.productTags?.result}
                            isMulti={true}
                            placeholder={"Select Tags"}
                            labelField={"productTagName"}
                            valueField={"productTagId"}
                            onChange={onSelectProductTags}
                            value={isEmptyArray(componentState.selectedProductTags) ? null : componentState.selectedProductTags}
                        />

                        <label>Status</label>
                        <BasicDropdown
                            placeholder={"Select Status"}
                            selectedValue={componentState.productStatus}
                            label="productStatus"
                            options={[{ productStatus: "Active" }, { productStatus: "Draft" }, { productStatus: "Inactive" }]}
                            dropdownItemClick={productStatusDropdownItemClick}
                            wrapperStyles={{ marginBottom: 0 }}
                        />
                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            isMultiSelect={componentState.isMultiSelect}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
            selectedImages={componentState.isMultiSelect ? [...componentState.selectedMediaImages] : [{ ...componentState.primaryImageAttachmentObj }]}
        />

        {
            componentState.addOptionsDialog?.showAddEditDialog &&
            <CreateProductOption
                addEditOptionsDialog={{
                    ...componentState.addOptionsDialog,
                }}
                productOptionNamesArr={[]}
                onClickCancel={onClickProductOptionsDialogCancel}
                onClickSubmit={onClickProductOptionsDialogSubmit}
            />
        }
        {
            componentState.editOptionNameDialog?.showEditOptionName &&
            <EditProductOptionName
                editOptionNameDialog={{
                    ...componentState.editOptionNameDialog,
                }}
                productOptionNamesArr={[]}
                onClickCancel={onClickEditOptionNameDialogCancel}
                onClickSubmit={onClickEditOptionNameDialogSubmit}
            />
        }

        {
            componentState.addEditOptionValueDialog?.showAddEditOptionValue &&
            <AddEditProductOptionValue
                addEditOptionValueDialog={{
                    ...componentState.addEditOptionValueDialog,
                }}
                onClickCancel={onClickEditOptionValueDialogCancel}
                onClickSubmit={onClickEditOptionValueDialogSubmit}
            />
        }
        {
            componentState.editVariantPropertiesDialog?.showEditVariantProperties &&
            <EditProductVariantProperties
                editVariantPropertiesDialog={{
                    ...componentState.editVariantPropertiesDialog,
                }}
                onClickCancel={onClickEditVariantDialogCancel}
                onClickSubmit={onClickEditVariantDialogSubmit}
            />
        }

        <AlertDialog
            alertState={{ ...componentState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default CreateUpdateGroup;