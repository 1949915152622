import moment from "moment";
import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { GET_ORDERS } from "../../utils/apiEndPoints";
import {
    DELETE_ACTION, EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import { GetReducer } from "./reducer";

const OrderList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [orderState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "orders", heading: "Orders" })
        getOrderList();
    }, []);

    useEffect(() => {
        if (orderState.refreshFlag) {
            getOrderList();
        }

    }, [orderState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Delete":
                // await deleteOrder(selectedItem.productOrderId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Order",
                        message: "Are you sure you want to delete " + item.productOrderName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (orderState.search != orderState.searchApiKey) {
            dispatch({ type: "RELOAD_ORDER_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, orderState.sort, orderState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getOrderList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: orderState.search,
            sort: orderState.sort,
            sortDir: orderState.sortDir,
            resultSize: orderState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? orderState.pageNo : pageNo,
        }

        if (orderState.status !== "All") {
            post.status = orderState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_ORDERS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else {
            return [];
        }
    }

    return <ContentSection>
        {
            orderState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={orderState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search orders..."
                            value={orderState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={()=>{}}><BsFillPlusCircleFill />Create Order</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            orderState.firstLoad &&
            <>
                {
                    !isEmptyArray(orderState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Order Id"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"o.orderNumber"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Customer"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"fullName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Total"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"o.grandTotal"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Items"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"oicv.orderItemCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Payment"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"o.paymentMode"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Status"}
                                    sort={orderState.sort}
                                    sortDir={orderState.sortDir}
                                    tableSortCol={"o.status"}
                                    sortTableLocal={sortTableLocal}
                                />
                            </tr>

                            {
                                orderState.response.result.map((item, idx) => {
                                    return <tr key={"ordertable"+idx}>
                                        <td>
                                            <OrderLink
                                                href = {"/orderdetails/"+item.orderNumber}>
                                                {item.orderNumber}
                                            </OrderLink>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>{item.grandTotal.toFixed(2)}</td>
                                        <td>{item.orderItemCount}</td>
                                        <td>
                                            <PaymentSpan text={item.paymentMode}>{item.paymentMode}</PaymentSpan>
                                        </td>
                                        {/* TODO - show different colors to different status  */}
                                        <td>{item.status}</td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getOrderList(e.selected + 1)}
                            pageRangeDisplayed={orderState.resultSize}
                            pageCount={getPageCount(orderState.response.count, orderState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={orderState.pageNo - 1}
                            totalRowCount = {orderState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(orderState.response.result) &&
                    !orderState.isPageLoading &&
                    !isEmptyVariable(orderState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(orderState.response.result) &&
                    !orderState.isPageLoading &&
                    isEmptyVariable(orderState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...orderState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

    </ContentSection>
}

export default OrderList;


//STYLES
const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;

const PaymentSpan = styled.span`
    display:inline-block;
    margin:0;
    font-size:11px;
    background: ${props => props.text === 'Online Payment'?"#d8d8d8":"#f9c7a0"};
    padding: 2px 6px;
    border-radius: 3px;
    color: ${props => props.text === 'Online Payment'?"#000":"#000"};
`;

const OrderLink = styled.a`
    color:#0000EE;

    &:hover{
        text-decoration: underline;
    }
`;