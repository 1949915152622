import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import ImageLibrary from "../../components/imageLibraryDialog";
import { Colors } from "../../components/styles/colors";
import { useAuth } from "../../hooks/useAuth";
import { DELETE_MERCHANT_LOGO, GET_MERCHANT_DETAILS, UPDATE_MERCHANT_LOGO } from "../../utils/apiEndPoints";
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";
import GstComp from "./gstComp";
import MerchantDetailsComp from "./merchantDetailsComp";
import { GetReducer } from "./reducer";

const MerchantDetails = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout, login } = useAuth();
    const [merchantState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "merchantdetails", heading: "Merchant" })
        getMerchantDetails();
    }, []);

    useEffect(() => {
        if (merchantState.refreshFlag) {
            getMerchantDetails();
        }
    }, [merchantState.refreshFlag]);

    useEffect(() => {
        if (merchantState.updateFlag) {
            updateImage();
        }
    }, [merchantState.updateFlag]);

    useEffect(() => {
        if (merchantState.deleteFlag) {
            deleteImage();
        }
    }, [merchantState.deleteFlag])

    const deleteImage = async () => {
        try {
            let response = await callAuthApi(DELETE_MERCHANT_LOGO, {}, user, logout);

            //now update local storage
            await login(response.data);

            dispatch({
                type: "DELETE_PROFILE_PIC_COMPLETE",
                snackbarMessage: "merchant logo updated successfully"
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_VALIDATION_ERRORS",
                errors: {
                    merchantLogoUpdateErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                }
            })
        }
    }

    const updateImage = async () => {
        try {
            let response = await callAuthApi(UPDATE_MERCHANT_LOGO, {
                productAttachmentId: merchantState.productAttachmentId,
            }, user, logout);

            //now update local storage
            await login(response.data);

            dispatch({
                type: "UPDATE_COMPLETE",
                snackbarMessage: "merchant logo uploaded successfully"
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_VALIDATION_ERRORS",
                errors: {
                    merchantLogoUpdateErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                }
            })
        }
    }

    const getMerchantDetails = async () => {
        let response = {};
        try {
            response = await callAuthApi(GET_MERCHANT_DETAILS, {}, user, logout);
            dispatch({
                type: "SET_RESPONSE",
                response: response.data
            })
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation) => {
        switch (operation) {
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const onClickChangeProfilePhoto = () => {
        dispatch({
            type: "SHOW_IMAGE_LIBRARY"
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        dispatch({
            type: "HIDE_IMAGE_LIBRARY"
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            const { productAttachmentId } = selectedImages[0];
            dispatch({
                type: "IMAGE_LIBRARY_SUBMIT",
                productAttachmentId: productAttachmentId,
            })
        }
    }

    const onClickDeleteProfilePhoto = () => {
        dispatch({
            type:"DELETE_PROFILE_PIC_CLICK"
        })
    }

    return <ContentSection>
        <TabWrapper>
            <Tabs
                defaultActiveKey="details"
                id="policies-tab"
            >
                <Tab eventKey="details" title="Details">
                    {
                        !isEmptyVariable(merchantState.details?.merchantName) &&
                        <MerchantDetailsComp
                            details={merchantState.details}
                            onClickChangeProfilePhoto={onClickChangeProfilePhoto}
                            onClickDeleteProfilePhoto={onClickDeleteProfilePhoto}
                        />
                    }
                </Tab>
                <Tab eventKey="gst" title="GST">
                    {
                        !isEmptyVariable(merchantState.details?.merchantName) &&
                        <GstComp
                            details={merchantState.details}
                        />
                    }
                </Tab>
            </Tabs>
        </TabWrapper>
        <AlertDialog
            alertState={{ ...merchantState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        <ImageLibrary
            showDialog={merchantState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />
    </ContentSection>
}

export default MerchantDetails;

//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
    border-top: 1px solid ${Colors.inputBorderColor};
`;

export const TabWrapper = styled.div`
    
    border-bottom: 1px solid ${Colors.inputBorderColor};
    ul{
        margin-bottom: 0;
        padding:0px 20px;
        position:sticky;
        top:0;
        background: ${Colors.whiteColor};
    }

    li {
        border: none !important;
        border-bottom: 1px solid transparent !important;
    }

    .nav-link{
        font-size: 13px;
        border: none !important;
        border-bottom: 1px solid transparent !important;
        border-radius: 0 !important;
        color: ${Colors.themeColorPrimary + "88"};
    }

    .nav-link:hover{
        border-radius: 0 !important;
        background: none !important;
        border-bottom: 2px solid ${Colors.themeColorPrimary + "88"} !important;
    }

    .nav-link.active{
        border-radius: 0 !important;
        color: ${Colors.themeColorPrimary};
        border-bottom: 2px solid ${Colors.themeColorPrimary} !important;
    }

    table tr th{
        padding: 10px 15px;
    }
`;