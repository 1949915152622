import { useEffect, useRef, useState } from "react"
import { Modal, Row, Col } from "react-bootstrap";
import { isEmptyVariable } from "../../utils/utilFunctions";
import ReactCreatableSelect from "../../components/dropdowns/reactCreatableSelect";
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const defaultOptions = [
    {
        productOptionName: "Size"
    },
    {
        productOptionName: "Color"
    },
    {
        productOptionName: "Material"
    }
]

const initialState = {
    selectedProductOptionNameObj: {},
    errors: {
    }
}

const EditProductOptionName = (props) => {
    const { editOptionNameDialog, onClickSubmit, onClickCancel, productOptionNamesArr } = props;
    const [dialogState, setDialogState] = useState(initialState);

    useEffect(() => {
        if (editOptionNameDialog.showEditOptionName) {
            setDialogState({
                ...initialState,
                selectedProductOptionNameObj: {
                    productOptionName: editOptionNameDialog.productOptionName,
                }
            })
        }
    }, [editOptionNameDialog.showEditOptionName]);

    const onSelectProductOptionName = (selectedOptionName) => {
        let optionNameObj = {...selectedOptionName}
        if(selectedOptionName.__isNew__){
            optionNameObj =  {
                productOptionName:selectedOptionName.label
            }
        }

        setDialogState({
            ...dialogState,
            selectedProductOptionNameObj: { ...optionNameObj }
        })
    }

    const onClickSubmitBtn = () => {
        const { selectedProductOptionNameObj } = dialogState;
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(selectedProductOptionNameObj?.productOptionName)) {
            hasErrors = true;
            errors.productOptionName = "Please enter the option name"
        }

        if (!hasErrors) {
            onClickSubmit(selectedProductOptionNameObj.productOptionName, editOptionNameDialog.optionsIndex);
        } else {
            setDialogState({
                ...dialogState,
                errors: { ...errors }
            })
        }

    }

    return <>
        <CustomModal show={editOptionNameDialog.showEditOptionName} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{"Edit Product Option Name"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <label>Option Name</label>
                        <ReactCreatableSelect
                            isLoading={false}
                            isClearable={true}
                            isSearchable={true}
                            reactSelectName={"optionlist"}
                            options={[...productOptionNamesArr, ...defaultOptions]}
                            isMulti={false}
                            placeholder={"Option Name"}
                            labelField={"productOptionName"}
                            valueField={"productOptionName"}
                            onChange={onSelectProductOptionName}
                            value={Object.keys(dialogState.selectedProductOptionNameObj).length === 0 ? null : dialogState.selectedProductOptionNameObj}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productOptionName) &&
                            <span className="err-span m-b-10">{dialogState.errors.productOptionName}</span>
                        }
                    </Col>
                </Row>
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>
    </>
}

export default EditProductOptionName;