import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { DELETE_VENDOR, GET_VENDORS, UPDATE_VENDOR_STATUS } from "../../utils/apiEndPoints";
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    DELETE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateVendor from "./createUpdateVendor";
import { GetReducer } from "./reducer";

const VendorList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [vendorState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "vendors", heading: "Vendors" })
        getVendorList();
    }, []);

    useEffect(() => {
        if (vendorState.refreshFlag) {
            getVendorList();
        }

    }, [vendorState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.vendorId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.vendorId, "Active");
                break;
            case "Delete":
                await deleteVendor(selectedItem.vendorId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateVendorDialog = () => {
        dispatch({
            type: "SHOW_CREATE_VENDOR_DIALOG"
        });
    }

    const onClickVendorDialogCancel = () => {
        dispatch({
            type: "RESET_VENDOR_DIALOG"
        });
    }

    const onClickVendorDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_VENDOR_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_VENDOR_DIALOG",
                    createVendorDialog: {
                        showDialog: true,
                        isEdit: true,
                        vendorObj: {
                            vendorName: item.vendorName,
                            vendorId: item.vendorId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Vendor",
                        message: "Are you sure you want to deactivate " + item.vendorName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Vendor",
                        message: "Are you sure you want to activate " + item.vendorName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Vendor",
                        message: "Are you sure you want to delete " + item.vendorName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (vendorState.search != vendorState.searchApiKey) {
            dispatch({ type: "RELOAD_VENDOR_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, vendorState.sort, vendorState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getVendorList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: vendorState.search,
            sort: vendorState.sort,
            sortDir: vendorState.sortDir,
            resultSize: vendorState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? vendorState.pageNo : pageNo
        }

        if (vendorState.status !== "All") {
            post.status = vendorState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_VENDORS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (vendorId, status) => {
        try {
            await callAuthApi(UPDATE_VENDOR_STATUS, {
                vendorId: vendorId,
                vendorStatus: status
            }, user, logout);

            getVendorList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteVendor = async (vendorId) => {
        try {
            await callAuthApi(DELETE_VENDOR, {
                vendorId: vendorId,
            }, user, logout);

            getVendorList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    
    return <ContentSection>
        {
            vendorState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={vendorState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search vendors..."
                            value={vendorState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={vendorState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateVendorDialog}><BsFillPlusCircleFill />Add Vendor</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            vendorState.firstLoad &&
            <>
                {
                    !isEmptyArray(vendorState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Vendor Name"}
                                    sort={vendorState.sort}
                                    sortDir={vendorState.sortDir}
                                    tableSortCol={"v.vendorName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Products"}
                                    sort={vendorState.sort}
                                    sortDir={vendorState.sortDir}
                                    tableSortCol={"pvcv.productCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={vendorState.sort}
                                    sortDir={vendorState.sortDir}
                                    tableSortCol={"v.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={vendorState.sort}
                                    sortDir={vendorState.sortDir}
                                    tableSortCol={"v.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                vendorState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.vendorName}
                                            <StatusSpan
                                                status={item.vendorStatus}
                                            />
                                        </td>
                                        <td>{item.productCount}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.vendorStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getVendorList(e.selected + 1)}
                            pageRangeDisplayed={vendorState.resultSize}
                            pageCount={getPageCount(vendorState.response.count, vendorState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={vendorState.pageNo - 1}
                            totalRowCount = {vendorState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(vendorState.response.result) &&
                    !vendorState.isPageLoading &&
                    !isEmptyVariable(vendorState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(vendorState.response.result) &&
                    !vendorState.isPageLoading &&
                    isEmptyVariable(vendorState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...vendorState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        {
            vendorState.createVendorDialog?.showDialog &&
            <CreateUpdateVendor
                vendorDialog={{
                    ...vendorState.createVendorDialog,
                }}
                onClickCancel={onClickVendorDialogCancel}
                onClickSubmit={onClickVendorDialogSubmit}
            />
        }
    </ContentSection>
}

export default VendorList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;