import Select from 'react-select';
import { ifEmptyReturnStr } from '../../utils/utilFunctions';

const ReactSelect = (props) => {
    const { isDisabled, isLoading, isClearable, isSearchable, reactSelectName, options,
        isMulti, placeholder, labelField, valueField, onChange, value } = props;
    return <>
        <Select
            className="react-select"
            classNamePrefix="rSelect"

            isDisabled={ifEmptyReturnStr(isDisabled, false)}
            isLoading={ifEmptyReturnStr(isLoading, false)}
            isClearable={ifEmptyReturnStr(isClearable, false)}
            isSearchable={ifEmptyReturnStr(isSearchable, false)}

            name={reactSelectName}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            getOptionLabel={optionObj => optionObj[labelField]}
            getOptionValue={optionObj => optionObj[valueField]}
            onChange={onChange}
            value={value}
        />
    </>
}


export default ReactSelect;