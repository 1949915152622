import styled from "styled-components";
import { Colors } from "../styles/colors";
import { Col, Row } from "react-bootstrap";

export const ImageLibraryBodyLayout = styled.div`
    display:flex;
    align-items:flex-start;
`;

export const ImageLayout = styled.div`
    width: 75%;
    border-right: 1px solid ${Colors.commonBorderColor};
`;

export const InfoLayout = styled.div`
    width: 25%;
`;

export const Row5 = styled(Row)`
    margin-left: -5px;
    margin-right: -5px;
`;
export const Col5 = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`;

export const ImageLibraryListRow = styled.div`
    height:calc(100vh - 170px);
    overflow-y:auto;
    overflow-x:hidden;
    padding: 20px;
    
     /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${Colors.displayImageListScrollbarColor};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${Colors.displayImageListScrollbarThumbColor};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${Colors.displayImageListScrollbarHoverColor};
    } 
`;

export const UploadImageFieldWrapper = styled.div`
    border:2px solid transparent;
`;
export const UploadImageFieldLayout = styled.div`
    input[type="file"]{
        display:none;
    }
    label{
        cursor:pointer;
        width:100%;
        border:${Colors.uploadButtonBorder};
        height:140px;
        display:flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 4px;

        &:hover{
            border:${Colors.uploadButtonBorderHover};
        }
    }
    svg{
        font-size:30px;
        margin-bottom: 10px;
    }
    p{
        font-size:20px;
        margin-bottom: 0;
    }
`;

export const DisplayImageWrapper = styled.div`

`;

export const DisplayImageLayout = styled.div`
    position:relative;
    button{
        border:none;
        display:block;
        width:100%;
        margin-bottom:10px;
        padding:0;
        background:none;
        border-radius: 6px;
        position:relative;
        overflow:hidden;
        border:2px solid transparent;
    }
    button img{
        width:100%;
        height:140px;
        object-fit: cover;
        object-position: center center;
    }
    button svg{
        color: ${Colors.displayImageTickIconColor};
        font-size:16px;
    }
    .selectedImg{
        border: ${Colors.displayImageSelectedImageBorder};
    }
`;

export const ImageOverlay = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    background:${Colors.displayImageOverlayBgColor};
    top:0;
`;

export const SelectedIconBox = styled.div`
    position:absolute;
    background: ${Colors.displayImageTickIconBackgroundColor};
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius:6px;
`;

export const SelectedImageInfoWrapper = styled.div`
    height:calc(100vh - 170px);
    overflow-y:auto;
    padding: 20px;

    h6{
        font-size:16px;
        margin-bottom:5px;
    }
    
     /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${Colors.displayImageListScrollbarColor};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${Colors.displayImageListScrollbarThumbColor};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${Colors.displayImageListScrollbarHoverColor};
    } 
`;

export const SelectedImageLayout = styled.div`
    img{
        width:100%;
        height:auto;
        object-fit:cover;
        object-position: center center;
        border-radius:4px;
        margin-bottom: 20px;
    }

    h6{
        font-size:15px;
        line-height: 21px;
        margin-bottom: 5px;
    }

    .selected-img-del-btn{
        background:none;
        border:none;
        color:${Colors.selectedImageDeleteButtonColor};
        padding:3px 0;

        &:hover{
            color:${Colors.selectedImageDeleteButtonColor+"99"};
        }
    }

    p{
        font-size: 13px;
        margin-bottom: 5px;
    }
`;

export const DisplayImageLoaderLayout = styled.div`
    border:2px solid transparent;
`;

export const DisplayImageLoaderWrapper = styled.div`
    @-webkit-keyframes rotation {
        from {-webkit-transform: rotate(0deg);}
        to   {-webkit-transform: rotate(359deg);}
    }
    svg{
        font-size:40px;
        -webkit-animation: rotation 2s infinite linear;
    }

    width:100%;
    border:${Colors.uploadButtonBorder};
    height:140px;
    display:flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    
`;