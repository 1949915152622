import axios from "axios";
import moment from "moment";

//Check Variable is empty
export const isEmptyVariable = (variableName) => {
    if (variableName === '') {
        return true;
    }

    if (variableName === undefined) {
        return true;
    }

    if (variableName === null) {
        return true;
    }

    if (variableName === "null") {
        return true;
    }

    return false;
}

//Check if array is empty
export const isEmptyArray = (arrayName) => {
    if (arrayName === '') {
        return true;
    }

    if (arrayName === undefined) {
        return true;
    }

    if (arrayName === null) {
        return true;
    }

    if (!Array.isArray(arrayName)) {
        return true;
    }

    if (arrayName.length === 0) {
        return true;
    }

    return false;
}

//Check if object is empty
export const isEmptyObject = (objectName) => {
    if (isEmptyVariable(objectName)) {
        return true
    }

    if (Object.keys(objectName).length <= 0) {
        return true
    }

    return false;
}

// check if variable name is empty, if yes then send returnVar
export const ifEmptyReturnStr = (variableName, returnVar) => {
    return isEmptyVariable(variableName) ? returnVar : variableName;
}

//Remove html tags from string
export const removeHtmlTags = (htmlStr) => {
    return htmlStr.replace(/(<([^>]+)>)/gi, "");
}

//sort table - single state
export const sortTable = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp: "",
        sortTemp: sortColumn
    };

    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if (currentSortColumn === sortColumn) {
        if (currentSortDir === "") {
            sortObj.sortDirTemp = "asc";
        } else if (currentSortDir === "asc") {
            sortObj.sortDirTemp = "desc";
        } else if (currentSortDir === "desc") {
            sortObj.sortDirTemp = "";
            sortObj.sortTemp = "";
        }
    } else {
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

//sort table - two state
export const sortTableTwoStates = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp: "",
        sortTemp: sortColumn
    };

    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if (currentSortColumn === sortColumn) {
        if (currentSortDir === "") {
            sortObj.sortDirTemp = "asc";
        } else if (currentSortDir === "asc") {
            sortObj.sortDirTemp = "desc";
        } else if (currentSortDir === "desc") {
            //Go back to default setting
            sortObj.sortDirTemp = "asc";
        }
    } else {
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

export const validatePassword = (password) => {
    let respObj = {
        err: false,
        responseMsg: ""
    }

    // password empty
    if (isEmptyVariable(password)) {
        respObj = {
            err: true,
            responseMsg: "Please enter password"
        }
        return respObj;
    }

    // password length should be greater than 8
    if (password.length < 7) {
        respObj = {
            err: true,
            responseMsg: "Password must have minimum 8 characters"
        }
        return respObj;
    }

    return respObj;
}

export const isJsonString = (str) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false;
    }
    return true;
}

export const isAlphaNumeric = (str) => {
    let pattern = /^[^\`"':]+$/i;
    return pattern.test(str);
}

export const isNumber = (num) => {
    return (typeof (num) === 'number' || typeof (num) === 'string' && num.trim() !== '') && !isNaN(num);
}

export const rgb2hex = (rgb) => {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}

export const getUTCDateTimeFromLocal = (dateStr, isEOD) => {
    if (isEmptyVariable(dateStr)) {
        return "";
    }

    var localDate = moment(dateStr);
    if (isEOD) {
        localDate = localDate.endOf("day");
    }
    return localDate.utc().format("YYYY-MM-DD HH:mm:ss");
}
export const getCurrentLocalDateAndTimeStr = () => {
    return moment().format("YYYY-MM-DD HH:mm:ss");
}
export const getCurrentLocalDateAndTimeDateObj = () => {
    return moment().toDate();
}
export const getCurrentLocalDateAndTimeObj = () => {
    return moment();
}
export const convertDateTimeObjToString = (dateObj) => {
    let momentDate = moment(dateObj);
    return momentDate.format("YYYY-MM-DD HH:mm:ss");
}
export const getDateAndTimeObjFromStr = (dateTimeStr) => {
    if (isEmptyVariable(dateTimeStr)) {
        return "";
    }
    return moment(dateTimeStr, "YYYY-MM-DD HH:mm:ss").toDate();
}
export const getLocalDateFromUTC = (dateStr) => {
    if (isEmptyVariable(dateStr)) {
        return "";
    }
    var utcDate = moment.utc(dateStr);
    return utcDate.local().format("DD-MMM-YYYY hh:mm a");
}
export const getLocalDateOnlyFromUTC = (dateStr) => {
    if (isEmptyVariable(dateStr)) {
        return "";
    }
    var utcDate = moment.utc(dateStr);
    return utcDate.local().format("DD-MMM-YYYY");
}
//For example if local time is 16-05-2024 04:05:00 then 
//it will return 15-05-2024 05:30:00 (utc start of the day + time zone offset)
export const getNowUtcStartTimeWrtTimeZone = () => {
    const utcOffset = moment().utcOffset();
    return moment.utc().startOf("day").add(utcOffset, 'minutes').format("DD-MMM-YYYY HH:mm:ss");
}
export const callApi = async (url, post) => {
    try {
        const params = new URLSearchParams();
        Object.keys(post).forEach(key => {
            params.append(key, post[key]);
        })
        const config = { 'Access-Control-Allow-Origin': '*' };
        return await axios.post(url, params, config);
    } catch (e) {
        //error handling
        return e;
    }
}

export const callAuthApi = async (url, post, user, logout) => {
    if (isEmptyVariable(user.email) || isEmptyVariable(user.accessToken) ||
        isEmptyVariable(user.merchantApiKey)) {
        logout();
    }
    let response = await callApi(url, {
        email: user.email,
        accessToken: user.accessToken,
        merchantApiKey: user.merchantApiKey,
        ...post
    });

    if (response?.status !== 200) {
        throw {
            showAlertDialog: true,
            heading: "Something went wrong. Please try again",
            message: "",
            type: "ALERT",
            cancelBtn: true,
            okayBtn: false,
            cancelBtnLabel: "Close",
            okayBtnLabel: ""
        };
    }

    if (response.data.responseCode === 0) {
        return response.data;
    } else if (response.data.responseCode === 10 || response.data.responseCode === 9) {
        //since we dont want to automatically redirect user to login screen lets display error dialog
        window.localStorage.setItem("user", null);
        // now display alert dialog
        throw {
            showAlertDialog: true,
            heading: "Session Expired",
            message: "",
            type: "ALERT",
            cancelBtn: false,
            okayBtn: true,
            cancelBtnLabel: "Cancel",
            okayBtnLabel: "Refresh"
        };
    } else {
        console.log(response)
        //Show alert dialog with the error message
        throw {
            showAlertDialog: true,
            heading: "Something went wrong!",
            message: response.data.responseMessage,
            type: "ALERT",
            cancelBtn: true,
            okayBtn: false,
            cancelBtnLabel: "Close",
            okayBtnLabel: ""
        };
    }
}

export const getPageCount = (totalCount, resultSize) => {
    return Math.ceil(totalCount / resultSize);
}

export const shuffleArray = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
}

export const validateEmail = (email) => {
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(email) == false) {
        return false;
    }

    return true;
};