import { Modal } from "react-bootstrap";
import { Colors } from "./colors";
import styled from "styled-components";

export const CustomAlertModal = styled(Modal)`
    text-align:center;

    .h4{

    }
    .modal-header{
        border:none;
        padding:0;
        position:relative;

        button{
            right:20px;
            top:20px;
            position:absolute;
            z-index:1;

            &:hover{
                background-color:transparent;
                color: ${Colors.blackColor};
            }
        }
    }

    .modal-body{
        padding-bottom:25px;
        svg{
            font-size:60px;
            color:${Colors.themeColorPrimary + "44"};
            margin-bottom: 10px;
        }
        h1,h2,h3,h4,h5,h6{
            margin:0px 0px 10px;
        }
        p{

        }
        button{
            padding:6px 20px;
            border:none;
            background-color:${Colors.alertDialogSubmitButtonColor};
            color:${Colors.whiteColor};
            border-radius:4px;
            margin:5px;

            &:hover{
                background-color:${Colors.alertDialogSubmitButtonColor + "AA"};
            }
        }

        button.cancel-btn{
            padding:6px 20px;
            border:none;
            background-color:${Colors.modalCancelBtnBgHover};
            color:${Colors.blackColor};
            border-radius:4px;
            margin:5px;

            &:hover{
                background-color:${Colors.modalCancelBtnBgHover + "33"};
            }
        }
    }
`;