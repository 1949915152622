import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { useOutletContext } from "react-router-dom";
import ImageLibrary from "../../components/imageLibraryDialog";
import { useAuth } from "../../hooks/useAuth";
import { ATTACHMENT_PATH, CHANGE_PASSWORD, DELETE_MERCHANT_USER_PROFILE_PIC, UPDATE_MERCHANT_USER_PROFILE_PIC } from "../../utils/apiEndPoints";
import { WARNING_MSG_TIMEOUT } from "../../utils/constants";
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";
import {
    BorderBox,
    DeleteLayout,
    PasswordCol, PasswordFieldCol,
    PasswordWrapper,
    ProfileDescLayout,
    ProfileDetailsCol,
    ProfilePicCol,
    ProfilePicInnerLayout,
    ProfilePicLayout,
    ProfileWrapper
} from "./index.style";
import { GetReducer } from "./reducer";

const Profile = () => {
    const { baseDispatch } = useOutletContext();
    const { user, login, logout } = useAuth();
    const [profileState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "Profile", heading: "Profile" })
    }, []);

    useEffect(() => {
        if (profileState.updateFlag) {
            updateImage();
        }
    }, [profileState.updateFlag])

    useEffect(() => {
        if (profileState.deleteFlag) {
            deleteImage();
        }
    }, [profileState.deleteFlag])

    const deleteImage = async () => {
        try {
            let response = await callAuthApi(DELETE_MERCHANT_USER_PROFILE_PIC, {}, user, logout);

            //now update local storage
            await login(response.data);

            dispatch({
                type: "DELETE_PROFILE_PIC_COMPLETE",
                snackbarMessage: "Profile picture updated successfully"
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_VALIDATION_ERRORS",
                errors: {
                    profileUpdateErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                }
            })
        }
    }

    const updateImage = async () => {
        try {
            let response = await callAuthApi(UPDATE_MERCHANT_USER_PROFILE_PIC, {
                profileImageAttachmentId: profileState.productAttachmentId,
            }, user, logout);

            //now update local storage
            await login(response.data);

            dispatch({
                type: "UPDATE_COMPLETE",
                snackbarMessage: "Profile picture updated successfully"
            })

        } catch (alertErr) {
            dispatch({
                type: "SET_VALIDATION_ERRORS",
                errors: {
                    profileUpdateErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                }
            })
        }
    }

    const onClickChangeProfilePhoto = () => {
        dispatch({
            type: "SHOW_IMAGE_LIBRARY"
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        dispatch({
            type: "HIDE_IMAGE_LIBRARY"
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            const { productAttachmentId } = selectedImages[0];
            dispatch({
                type: "IMAGE_LIBRARY_SUBMIT",
                productAttachmentId: productAttachmentId,
            })
        }
    }

    const onClickDeleteProfilePhoto = () => {
        dispatch({
            type:"DELETE_PROFILE_PIC_CLICK"
        })
    }

    const validateChangePassword = () => {
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(profileState.oldPassword)) {
            hasErrors = true;
            errors.oldPassword = "Please enter the old password"
        }

        if (isEmptyVariable(profileState.newPassword)) {
            hasErrors = true;
            errors.newPassword = "Please enter the new password"
        }

        if (isEmptyVariable(profileState.confirmNewPassword)) {
            hasErrors = true;
            errors.confirmNewPassword = "Please confirm the new password"
        }

        if (!isEmptyVariable(profileState.newPassword) && !isEmptyVariable(profileState.confirmNewPassword) &&
            profileState.newPassword !== profileState.confirmNewPassword) {
            hasErrors = true;
            errors.confirmNewPassword = "Password mismatch"
        }

        dispatch({
            type: "SET_VALIDATION_ERRORS",
            errors: errors
        })
        return hasErrors;
    }

    const changePassword = async () => {

        if (!validateChangePassword()) {
            try {
                await callAuthApi(CHANGE_PASSWORD, {
                    oldPassword: profileState.oldPassword,
                    newPassword: profileState.newPassword,
                }, user, logout);

                dispatch({
                    type: "RESET_CHANGE_PASSWORD_FIELDS",
                    response: "Password changed successfully"
                })

                setTimeout(() => {
                    dispatch({
                        type: "RESET_CHANGE_PASSWORD_FIELDS",
                        response: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            } catch (alertErr) {
                dispatch({
                    type: "SET_VALIDATION_ERRORS",
                    errors: {
                        apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                    }
                })
            }
        } else {
            setTimeout(() => {
                dispatch({
                    type: "SET_VALIDATION_ERRORS",
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    return <ProfileWrapper>
        <Row>
            <Col md="6">
                <BorderBox>
                    <h5>Profile</h5>
                    <ProfileDetailsCol>
                        <ProfilePicLayout>
                            <ProfilePicInnerLayout>
                                {
                                    isEmptyVariable(user.profilePic) ?
                                        <ProfilePicCol>
                                            <FaUserCircle />
                                        </ProfilePicCol>
                                        : 
                                        <ProfilePicCol>
                                            <DeleteLayout className="delete-layout"
                                                onClick={onClickDeleteProfilePhoto}>
                                                <FaRegTrashCan />
                                            </DeleteLayout>
                                            <img src={ATTACHMENT_PATH+user.profilePic} alt="profile picture" />
                                        </ProfilePicCol>
                                }
                            </ProfilePicInnerLayout>
                            {
                                !isEmptyVariable(profileState.errors.profileUpdateErr) &&
                                <span className="err-span m-t-10 m-b-10">{profileState.errors.profileUpdateErr}</span>
                            }
                            <button onClick={onClickChangeProfilePhoto} className="change-photo-btn">
                                Change Photo
                            </button>
                        </ProfilePicLayout>

                        <ProfileDescLayout>
                            <h6>{user.firstName + " " + user.lastName}</h6>
                            <p>{user.email}</p>
                            <p>{user.phoneNumber}</p>
                            <p>{user.userType}</p>
                        </ProfileDescLayout>
                    </ProfileDetailsCol>
                </BorderBox>
            </Col>
            <Col md="6">
                <BorderBox>
                    <PasswordWrapper>
                        <PasswordCol>
                            <h5>Change Password</h5>
                            <PasswordFieldCol>
                                <label>Old Password</label>
                                <input
                                    type="password"
                                    name="oldPassword"
                                    value={profileState.oldPassword}
                                    onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "oldPassword", fieldValue: e.target.value })}
                                />
                            </PasswordFieldCol>
                            {
                                !isEmptyVariable(profileState.errors.oldPassword) &&
                                <span className="err-span m-b-10">{profileState.errors.oldPassword}</span>
                            }
                            <PasswordFieldCol>
                                <label>New Password</label>
                                <input
                                    type="password"
                                    name="newPassword"
                                    value={profileState.newPassword}
                                    onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "newPassword", fieldValue: e.target.value })}
                                />
                            </PasswordFieldCol>
                            {
                                !isEmptyVariable(profileState.errors.newPassword) &&
                                <span className="err-span m-b-10">{profileState.errors.newPassword}</span>
                            }
                            <PasswordFieldCol>
                                <label>Confirm Password</label>
                                <input
                                    type="password"
                                    name="confirmNewPassword"
                                    value={profileState.confirmNewPassword}
                                    onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "confirmNewPassword", fieldValue: e.target.value })}
                                />
                            </PasswordFieldCol>
                            {
                                !isEmptyVariable(profileState.errors.confirmNewPassword) &&
                                <span className="err-span m-b-10">{profileState.errors.confirmNewPassword}</span>
                            }
                            {
                                !isEmptyVariable(profileState.errors.apiErr) &&
                                <span className="err-span m-b-10">{profileState.errors.apiErr}</span>
                            }
                            {
                                !isEmptyVariable(profileState.apiResponse) &&
                                <span className="success-span m-b-10">{profileState.apiResponse}</span>
                            }
                            <button className="save-password-btn" onClick={changePassword}>Save</button>
                        </PasswordCol>
                    </PasswordWrapper>
                </BorderBox>
            </Col>
        </Row>

        <ImageLibrary
            showDialog={profileState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />
    </ProfileWrapper>
}

export default Profile;