import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_PRODUCT_GROUPS, DELETE_PRODUCT_GROUP, UPDATE_PRODUCT_GROUP_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateGroup from "./createUpdateProductGroup";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import ProductGroupList from "./productGroupListing";


const ProductGroup = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [groupState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "collections", heading: "Collections" })
        getGroupList();
    }, []);

    useEffect(() => {
        if (groupState.refreshFlag) {
            getGroupList();
        }

    }, [groupState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productGroupId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productGroupId, "Active");
                break;
            case "Delete":
                await deleteGroup(selectedItem.productGroupId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateGroupDialog = () => {
        dispatch({
            type: "SHOW_CREATE_GROUP_DIALOG"
        });
    }

    const onClickGroupDialogCancel = () => {
        dispatch({
            type: "RESET_GROUP_DIALOG"
        });
    }

    const onClickGroupDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_GROUP_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_GROUP_DIALOG",
                    addEditGroupComp: {
                        showAddEditComp: true,
                        isEdit: true,
                        groupObj: {
                            productGroupId: item.productGroupId,
                            productGroupName: item.productGroupName,
                            productGroupDesc: item.productGroupDesc,
                            productGroupType: item.productGroupType,
                            imageAttachmentObj: {
                                productAttachmentId: item.productAttachmentId,
                                productAttachmentType: item.productAttachmentType,
                                productAttachmentPath: item.productAttachmentPath
                            },
                            seoTitle: item.seoTitle,
                            seoDescription: item.seoDescription,
                            urlHandle: item.urlHandle,
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Collection",
                        message: "Are you sure you want to deactivate " + item.productGroupName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Collection",
                        message: "Are you sure you want to activate " + item.productGroupName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Collection",
                        message: "Are you sure you want to delete " + item.productGroupName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (groupState.search != groupState.searchApiKey) {
            dispatch({ type: "RELOAD_GROUP_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, groupState.sort, groupState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getGroupList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: groupState.search,
            sort: groupState.sort,
            sortDir: groupState.sortDir,
            resultSize: groupState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? groupState.pageNo : pageNo
        }

        if (groupState.status !== "All") {
            post.status = groupState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCT_GROUPS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productGroupId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_GROUP_STATUS, {
                productGroupId: productGroupId,
                productGroupStatus: status
            }, user, logout);

            getGroupList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteGroup = async (productGroupId) => {
        try {
            await callAuthApi(DELETE_PRODUCT_GROUP, {
                productGroupId: productGroupId,
            }, user, logout);

            getGroupList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            groupState.isPageLoading &&
            <Loader />
        }

        {
            !groupState.addEditGroupComp?.showAddEditComp &&
            <ProductGroupList
                groupState={groupState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                showCreateGroupDialog={showCreateGroupDialog}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getGroupList={getGroupList}
            />
        }

        {
            groupState.addEditGroupComp?.showAddEditComp &&
            <CreateUpdateGroup
                addEditGroupComp={{
                    ...groupState.addEditGroupComp
                }}
                onClickSubmit={onClickGroupDialogSubmit}
                onClickCancel={onClickGroupDialogCancel}
            />
        }

        <AlertDialog
            alertState={{ ...groupState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default ProductGroup;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;