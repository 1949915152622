import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import styled from "styled-components";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    DELETE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable
} from "../../utils/utilFunctions";

const ProductList = (props) => {
    const { productState, onClickResultSize, onSearchInputChange, searchList,
        statusDropdownClick, showCreateDialog, onClickTableActionItem,
        sortTableLocal, getProductList } = props;

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Draft") {
            return [EDIT_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }
    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={productState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search products..."
                            value={productState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={productState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Draft" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateDialog}><BsFillPlusCircleFill />Add Product</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            productState.firstLoad &&
            <>
                {
                    !isEmptyArray(productState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <th></th>
                                <SortableTableHeader
                                    tableHeader={"Product Name"}
                                    sort={productState.sort}
                                    sortDir={productState.sortDir}
                                    tableSortCol={"p.productName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Variants"}
                                    sort={productState.sort}
                                    sortDir={productState.sortDir}
                                    tableSortCol={"pvv.variantCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Quantity"}
                                    sort={productState.sort}
                                    sortDir={productState.sortDir}
                                    tableSortCol={"pqv.totalProductQuantity"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={productState.sort}
                                    sortDir={productState.sortDir}
                                    tableSortCol={"p.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={productState.sort}
                                    sortDir={productState.sortDir}
                                    tableSortCol={"p.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                productState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td style={{ textAlign: "center", width: "70px" }}>
                                            {
                                                !isEmptyVariable(item.productAttachmentPath) &&
                                                <img src={ATTACHMENT_PATH + item.productAttachmentPath.replace("/images/", "/thumbnails/")} />
                                            }
                                        </td>
                                        <td>
                                            {item.productName}
                                            <StatusSpan
                                                status={item.productStatus}
                                            />
                                        </td>
                                        <td>{item.variantCount}</td>
                                        <td>{item.totalProductQuantity}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.productStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getProductList(e.selected + 1)}
                            pageRangeDisplayed={productState.resultSize}
                            pageCount={getPageCount(productState.response.count, productState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={productState.pageNo - 1}
                            totalRowCount = {productState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(productState.response.result) &&
                    !productState.isPageLoading &&
                    !isEmptyVariable(productState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(productState.response.result) &&
                    !productState.isPageLoading &&
                    isEmptyVariable(productState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
    </>
}

export default ProductList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;