import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import Pagination from "../../components/pagination";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import { BackButton, ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderWrapper } from "../../components/styles/listingHeaderStyles";
import { useAuth } from "../../hooks/useAuth";
import { INVENTORY_LOG } from "../../utils/apiEndPoints";
import { DEFAULT_RESULT_SIZE, INITIAL_ALERT_STATE } from "../../utils/constants";
import { callAuthApi, getLocalDateFromUTC, getPageCount, isEmptyArray, isEmptyVariable, sortTable } from "../../utils/utilFunctions";
import { Colors } from "../../components/styles/colors";
import { Col, Row } from "react-bootstrap";

const INITIAL_TYPE_STATE = {
    isPageLoading: false,
    firstLoad: false,
    search: "",
    searchApiKey: "",
    status: "All",
    sort: "",
    sortDir: "",
    resultSize: DEFAULT_RESULT_SIZE,
    pageNo: 1,
    response: {},

    alertDialog:{...INITIAL_ALERT_STATE}
}

const ProductInventoryLog = ({ productOptionValueMap, hideInventoryLog }) => {
    const [state, setState] = useState(INITIAL_TYPE_STATE);
    const { user, logout } = useAuth();

    useEffect(() => {
        console.log(productOptionValueMap);
        getInventoryLog();
    }, [])

    useEffect(() => {
        if (state.refreshFlag) {
            getInventoryLog();
        }
    }, [state.refreshFlag]);

    const getInventoryLog = async (pageNo) => {
        let post = {
            search: state.search,
            sort: state.sort,
            sortDir: state.sortDir,
            resultSize: state.resultSize,
            pageNo: isEmptyVariable(pageNo) ? state.pageNo : pageNo
        }

        try {
            let response = await callAuthApi(INVENTORY_LOG, {
                productOptionValueMapId: productOptionValueMap.productOptionValueMapId,
                ...post
            }, user, logout);

            if (isEmptyVariable(pageNo)) {
                setState({
                    ...state,
                    firstLoad:true,
                    alertDialog:{...INITIAL_ALERT_STATE},
                    refreshFlag:false, // Refresh is done so set it to false
                    isPageLoading: false,
                    response: response.data
                })
            } else {
                setState({
                    ...state,
                    alertDialog:{...INITIAL_ALERT_STATE},
                    refreshFlag:false, // Refresh is done so set it to false
                    isPageLoading: false,
                    pageNo: pageNo,
                    response: response.data,
                })
            }
        } catch (errAlert) {
            setState({
                ...state,
                alertState: errAlert
            });
        }
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, state.sort, state.sortDir);

        setState({
            ...state,
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
            refreshFlag:true,
        })
    }

    const onClickAlertCancel = () => {
        setState({
            ...state,
            alertDialog:{...INITIAL_ALERT_STATE}
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            default:
                setState({
                    ...state,
                    alertDialog: { ...INITIAL_ALERT_STATE }
                });
        }
    }
    const getVariantInfo = ({ productOption1, productOptionValue1, productOption2, productOptionValue2 }) => {
        let str = "";

        if (!isEmptyVariable(productOption1)) {
            str = productOption1 + ": " + productOptionValue1
        }
        if (!isEmptyVariable(productOption2)) {
            str = productOption2 + ": " + productOptionValue2
        }

        return str;
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={hideInventoryLog}><FaArrowLeftLong /></BackButton>
                    {
                        productOptionValueMap.hasOptions === "Y" &&
                        <h6>{"Inventory Log - " + productOptionValueMap.productName + " (" +getVariantInfo(productOptionValueMap) + ")"}</h6>
                    }
                    {
                        !productOptionValueMap.hasOptions === "Y" &&
                        <h6>{"Inventory Log - " + productOptionValueMap.productName}</h6>
                    }
                </ListingHeaderLeftLayout>

            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        
        <DashboardCardHolder>
            <Row5>
                <Col5 md="3">
                    <DashboardCard>
                        <p>Total Unavailable</p>
                        <h6>{productOptionValueMap.totalProductOptionUnavailableQty}</h6>
                    </DashboardCard>
                </Col5>
                <Col5 md="3">
                    <DashboardCard>
                        <p>Committed</p>
                        <h6>{productOptionValueMap.finalCommittedQuantity}</h6>
                    </DashboardCard>
                </Col5>
                <Col5 md="3">
                    <DashboardCard>
                        <p>Total Available</p>
                        <h6>{productOptionValueMap.totalProductOptionAvailableQty}</h6>
                    </DashboardCard>
                </Col5>
            </Row5>
        </DashboardCardHolder>

        {
            state.firstLoad &&
            <>
                {
                    !isEmptyArray(state.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Quantity"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"productQuantity"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"State"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"inventoryState"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Reason"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"reason"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Date"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"created_by"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"created_by"}
                                    sortTableLocal={sortTableLocal}
                                />
                            </tr>

                            {
                                state.response.result.map((item, idx) => {
                                    return <tr key={"inventoryLog"+idx}>
                                        <td>
                                            {item.productQuantity}
                                        </td>
                                        <td>{item.inventoryState}</td>
                                        <td>{item.reason}</td>
                                        <td>{getLocalDateFromUTC(item.created_at)}</td>
                                        <td>{item.created_by}</td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getInventoryLog(e.selected + 1)}
                            pageRangeDisplayed={state.resultSize}
                            pageCount={getPageCount(state.response.count, state.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={state.pageNo - 1}
                            totalRowCount = {state.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(state.response.result) &&
                    !state.isPageLoading &&
                    !isEmptyVariable(state.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(state.response.result) &&
                    !state.isPageLoading &&
                    isEmptyVariable(state.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }

        <AlertDialog
            alertState={{ ...state.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}
export default ProductInventoryLog;

const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;
const DashboardCardHolder = styled.div`
    padding:20px 20px 0px 20px;
`;

const DashboardCard = styled.div`
    padding: 12px 16px;
    border: 1px solid ${Colors.inputBorderColor};
    border-radius: 5px;
    margin-bottom: 10px;
    p{
        margin-bottom: 0;
    }

    h6{
        margin-bottom: 0;
    }
`;

const Row5 = styled(Row)`
    margin-left: -5px;
    margin-right: -5px;
`;
const Col5 = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`;