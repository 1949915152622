import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { ADD_PRODUCT_UDF, UPDATE_PRODUCT_UDF } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from '../../utils/utilFunctions';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const initialState = {
    productUdfName: "",
    productUdfType: "",
    errors: {
        productUdfName: "",
        productUdfType: "",
    },
    apiErr: "",
}

const CreateUpdateUdf = (props) => {
    const { productUdfDialog, onClickSubmit, onClickCancel } = props;

    const [dialogState, setDialogState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (productUdfDialog.showDialog) {
            if (productUdfDialog.isEdit) {
                setDialogState(productUdfDialog.udfObj);
            } else {
                setDialogState(initialState);
            }
            inputRef.current.focus();
        }
    }, [productUdfDialog.showDialog])

    const inputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    const productUdfTypeDropdownItemClick = (option) => {
        setDialogState({
            ...dialogState,
            productUdfType: option.productUdfType
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(dialogState.productUdfName)) {
            hasErrors = true;
            errors.productUdfName = "Please enter the custom field name"
        }

        if (isEmptyVariable(dialogState.productUdfType)) {
            hasErrors = true;
            errors.productUdfType = "Please select the custom field type"
        }

        setDialogState({
            ...dialogState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = async () => {
        if (!validateForm()) {
            try {
                if (productUdfDialog.isEdit) {
                    await updateProductUdf();
                } else {
                    await createNewProductUdf();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setDialogState({
                    ...dialogState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    setDialogState({
                        ...dialogState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setDialogState({
                    ...dialogState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createNewProductUdf = async () => {
        await callAuthApi(ADD_PRODUCT_UDF, {
            productUdfName: dialogState.productUdfName,
            productUdfType: dialogState.productUdfType,
        }, user, logout)
    }

    const updateProductUdf = async () => {
        await callAuthApi(UPDATE_PRODUCT_UDF, {
            productUdfId: productUdfDialog.udfObj.productUdfId,
            productUdfName: dialogState.productUdfName,
            productUdfType: dialogState.productUdfType,
        }, user, logout);
    }

    const getProductUdfType = () => {
        if (productUdfDialog.isEdit) {
            if (dialogState.productUdfType === "Single Line") {
                return [
                    { productUdfType: "Single Line" },
                    { productUdfType: "Multi Line" },
                    { productUdfType: "Rich Text" },
                ]
            } else if (dialogState.productUdfType === "Multi Line") {
                return [
                    { productUdfType: "Multi Line" },
                    { productUdfType: "Rich Text" },
                ]
            } else if (dialogState.productUdfType === "Rich Text") {
                return [
                    { productUdfType: "Rich Text" },
                ]
            } else if (dialogState.productUdfType === "Integer") {
                return [
                    { productUdfType: "Single Line" },
                    { productUdfType: "Multi Line" },
                    { productUdfType: "Rich Text" },
                    { productUdfType: "Integer" },
                ]
            } else if (dialogState.productUdfType === "Decimal") {
                return [
                    { productUdfType: "Single Line" },
                    { productUdfType: "Multi Line" },
                    { productUdfType: "Rich Text" },
                    { productUdfType: "Decimal" },
                ]
            } else {
                return []
            }
        } else {
            return [
                { productUdfType: "Single Line" },
                { productUdfType: "Multi Line" },
                { productUdfType: "Rich Text" },
                { productUdfType: "Integer" },
                { productUdfType: "Decimal" },
            ]
        }
    }

    return <>
        <CustomModal show={productUdfDialog.showDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{productUdfDialog.isEdit ? "Update Custom Field" : "Create Custom Field"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <input
                            placeholder={productUdfDialog.isEdit ? "Update Custom Field Name" : "Enter Custom Field Name"}
                            value={dialogState.productUdfName}
                            name="productUdfName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productUdfName) &&
                            <span className="err-span m-b-10">{dialogState.errors.productUdfName}</span>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <BasicDropdown
                            placeholder={"Select Custom Field Type"}
                            selectedValue={dialogState.productUdfType}
                            label="productUdfType"
                            options={getProductUdfType()}
                            dropdownItemClick={productUdfTypeDropdownItemClick}
                            wrapperStyles={{ marginBottom: 15 }}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productUdfType) &&
                            <span className="err-span m-b-10">{dialogState.errors.productUdfType}</span>
                        }
                    </Col>
                </Row>

                {
                    !isEmptyVariable(dialogState.apiErr) &&
                    <span className="err-span m-b-10">{dialogState.apiErr}</span>
                }
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>

    </>
}

export default CreateUpdateUdf;