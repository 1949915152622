import { Col, Row } from "react-bootstrap";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa";
import styled from "styled-components";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { FormWrapper } from "../../components/styles/addEditFormStyles";
import { Colors } from "../../components/styles/colors";
import { useAuth } from "../../hooks/useAuth";
import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import { isEmptyVariable } from "../../utils/utilFunctions";

const MerchantDetailsComp = (props) => {
    const { details, onClickDeleteProfilePhoto, onClickChangeProfilePhoto } = props;
    const { user, login, logout } = useAuth();

    return <FormWrapper>
        <Row>
            <Col md={8}>
                <table>
                    <tr>
                        <th>Name</th>
                        <td>{details.merchantName}</td>
                    </tr>
                    <tr>
                        <th>Merchant Id</th>
                        <td>{details.merchantUniqueId}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{details.merchantEmail}</td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td>{details.merchantPhone}</td>
                    </tr>
                    <tr>
                        <th>API Key</th>
                        <td>{details.merchantApiKey}</td>
                    </tr>
                    <tr>
                        <th>Subscription</th>
                        <td>{details.subscriptionPlan}</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>{details.merchantAddress}</td>
                    </tr>
                    <tr>
                        <th>City</th>
                        <td>{details.merchantCity}</td>
                    </tr>
                    <tr>
                        <th>State</th>
                        <td>{details.merchantState}</td>
                    </tr>
                    <tr>
                        <th>Logo</th>
                        <td>
                            <MerchantLogoLayout>
                                <MerchantLogoInnerLayout>
                                    {
                                        isEmptyVariable(user.merchantLogoPath) ?
                                            <ImagePlaceholder>
                                                <FaRegImage />
                                            </ImagePlaceholder>
                                            :
                                            <MerchantLogoCol>
                                                <DeleteLayout className="delete-layout"
                                                    onClick={onClickDeleteProfilePhoto}>
                                                    <FaRegTrashCan />
                                                </DeleteLayout>
                                                <img src={ATTACHMENT_PATH + user.merchantLogoPath} alt="Merchant Logo" />
                                            </MerchantLogoCol>
                                    }
                                </MerchantLogoInnerLayout>
                                {
                                    !isEmptyVariable(details?.errors?.merchantLogoUpdateErr) &&
                                    <span className="err-span m-t-10 m-b-10">{details.errors.merchantLogoUpdateErr}</span>
                                }
                                <ButtonWrapper>
                                    <button onClick={onClickChangeProfilePhoto} className="change-photo-btn">
                                        {isEmptyVariable(user.merchantLogoPath) ? "Upload Logo" : "Update Logo"}
                                    </button>
                                </ButtonWrapper>
                            </MerchantLogoLayout>
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>
                            <StatusSpan
                                status={details.merchantStatus}
                            />
                        </td>
                    </tr>
                </table>
            </Col>
        </Row>
    </FormWrapper>
}


const MerchantLogoLayout = styled.div`
    width:170px;
    // text-align:center;
    display: flex;
    align-items: center;

    button{
        padding: 6px 20px;
        margin-top:0px;
        border:none;
        background: ${Colors.themeColorPrimary};
        color: ${Colors.whiteColor};
        border-radius: 4px;
        font-size: 13px;

        :hover{
            background:${Colors.themeColorPrimaryHover};
        }
    }
`;

const MerchantLogoInnerLayout = styled.div`
    position:relative;
    flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
    flex-shrink: 0;
    margin-left: 10px;
`;

const MerchantLogoCol = styled.div`
    position:relative;

    svg{
        font-size: 150px;
    }
    img{
        width:150px;
        height: auto;
    }

    &:hover .delete-layout{
        display:flex;
        align-items:center;
        justify-content:center;
    }
`;


const DeleteLayout = styled.div`
    background:${Colors.themeColorPrimary+"99"};
    position:absolute;
    right:0;
    left:0;
    top:0;
    bottom:0;
    width:150px;
    height:auto;
    display:none;
    cursor: pointer;

    svg{
        height:40px;
        cursor:pointer;
        color:${Colors.whiteColor};
    }
`;

const ImagePlaceholder = styled.div`
    border: 1px solid #dddddd;
    margin-bottom: 0px;
    border-radius:4px;
    width:52px;
    overflow: hidden;
    padding:10px;

    svg{
        font-size:30px;
        color: ${Colors.themeColorPrimary+"66"};
        margin-bottom:0px;
    }

`;

export default MerchantDetailsComp;