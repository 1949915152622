import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import { GET_PRODUCT_GROUPS } from "../../utils/apiEndPoints";
import { RESULT_SIZE_ARRAY } from "../../utils/constants";
import { callAuthApi, getPageCount, isEmptyArray, isEmptyObject, isEmptyVariable, sortTable } from "../../utils/utilFunctions";
import AlertDialog from "../dialogs/alertDialog";
import ResultSizeDropdown from "../dropdowns/resultSizeDropdown";
import Checkbox from "../input/checkbox";
import Pagination from "../pagination";
import NoRecords from "../randomComponents/noRecords";
import NoResults from "../randomComponents/noResults";
import SortableTableHeader from "../randomComponents/sortableTableHeader";
import { CancelButton, CustomModalListing, ModalButtonLayout, SubmitButton } from "../styles/addEditDialogFormStyles";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from "../styles/listingHeaderStyles";
import { GetReducer } from "./reducer";
import { Colors } from "../styles/colors";

const ProductGroupListDialog = (props) => {
    const { showAddEditProductGroupsflag, onClickSubmit, onClickCancel, discountProductGroups } = props;
    const [dialogState, dispatch] = GetReducer();
    const { user, logout } = useAuth();

    useEffect(() => {
        if (showAddEditProductGroupsflag) {
            getProductGroupList();
        }
    }, [showAddEditProductGroupsflag]);

    useEffect(() => {
        if (dialogState.refreshFlag) {
            getProductGroupList();
        }
    }, [dialogState.refreshFlag]);

    const getProductGroupList = async (pageNo) => {

        let post = {
            search: dialogState.search,
            sort: dialogState.sort,
            sortDir: dialogState.sortDir,
            resultSize: dialogState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? dialogState.pageNo : pageNo,
            status: "Active"
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCT_GROUPS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data,
                    discountProductGroups: discountProductGroups
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo,
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (dialogState.search != dialogState.searchApiKey) {
            dispatch({ type: "RELOAD_LIST" })
        }
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, dialogState.sort, dialogState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    const selectProductItem = (item, e) => {
        dispatch({
            type: "SELECT_PRODUCT_GROUP_ITEM",
            item: item,
            checked: e.target.checked
        })
    }

    const unselectAllProductGroups = () => {
        dispatch({
            type: "UNSELECT_ALL_PRODUCT_GROUPS"
        })
    }

    return <>
        <CustomModalListing show={showAddEditProductGroupsflag} onHide={onClickCancel} dialogClassName="product-list-dialog">
            <Modal.Header closeButton>
                <Modal.Title>{"Add Collections"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ListingHeaderWrapper>
                    <ListingHeaderLayout>
                        <ListingHeaderLeftLayout>
                            <ResultSizeDropdown
                                dropdownLabel={dialogState.resultSize}
                                dropdownList={RESULT_SIZE_ARRAY}
                                onClickResultSize={onClickResultSize}
                            />

                            <SearchBox>
                                <input type="text" placeholder="Search collections..."
                                    value={dialogState.search}
                                    name="search"
                                    onChange={onSearchInputChange}
                                    onBlur={searchList}
                                    className="m-b-0"
                                    onKeyDown={
                                        (e) => {
                                            if (e.key === 'Enter') searchList();
                                        }
                                    }
                                />
                                <button><BsSearch /></button>
                            </SearchBox>

                        </ListingHeaderLeftLayout>

                        <ListingHeaderRightLayout>
                        </ListingHeaderRightLayout>
                    </ListingHeaderLayout>
                </ListingHeaderWrapper>
                {
                    !isEmptyObject(dialogState.selectedProductGroups) &&
                    <SelectedDetailsCol>
                        <p>
                            {
                                Object.keys(dialogState.selectedProductGroups).length === 1
                                ?
                                "1 Collection Selected"
                                :
                                Object.keys(dialogState.selectedProductGroups).length + " Collections Selected"
                            }
                        </p>
                        <SubmitButton onClick={unselectAllProductGroups}>Unselect All</SubmitButton>
                    </SelectedDetailsCol>
                }
                {
                    dialogState.firstLoad &&
                    <>
                        {
                            !isEmptyArray(dialogState.response.result) &&
                            <ContentTableWrap>
                                <table>
                                    <tr>
                                        <th>
                                        </th>
                                        
                                        <SortableTableHeader
                                            tableHeader={"Collection Name"}
                                            sort={dialogState.sort}
                                            sortDir={dialogState.sortDir}
                                            tableSortCol={"productGroupName"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                    </tr>

                                    {
                                        dialogState.response.result.map((item, itemIdx) => {
                                            return <tr key = {"row"+itemIdx}>
                                                <td style={{ textAlign: "center", width: "50px" }}>
                                                    <Checkbox
                                                        checkBoxId={"page-checkbox"}
                                                        checkboxLabel={""}
                                                        handleChange={(e)=>{selectProductItem(item, e)}}
                                                        checked = {!isEmptyObject(dialogState.selectedProductGroups[item.productGroupId])}
                                                    />
                                                </td>
                                                <td>
                                                    {item.productGroupName}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </table>
                                <Pagination
                                    breakLabel="..."
                                    onPageChange={(e) => getProductGroupList(e.selected + 1)}
                                    pageRangeDisplayed={dialogState.resultSize}
                                    pageCount={getPageCount(dialogState.response.count, dialogState.resultSize)}
                                    renderOnZeroPageCount={null}
                                    forcePage={dialogState.pageNo - 1}
                                    totalRowCount = {dialogState.response.count}
                                />
                            </ContentTableWrap>
                        }
                        {
                            // if result is empty after calling the api
                            isEmptyArray(dialogState.response.result) &&
                            !dialogState.isPageLoading &&
                            !isEmptyVariable(dialogState.searchApiKey) &&
                            <NoResults />
                        }

                        {
                            // if result is empty after calling the api and search string is not empty
                            isEmptyArray(dialogState.response.result) &&
                            !dialogState.isPageLoading &&
                            isEmptyVariable(dialogState.searchApiKey) &&
                            <NoRecords />
                        }
                    </>
                }

                <ModalButtonLayout className="m-b-20">
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={() => onClickSubmit(dialogState.selectedProductGroups)}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModalListing>
        <AlertDialog
            alertState={{ ...dialogState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default ProductGroupListDialog;


const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;

const SelectedDetailsCol = styled.div`
    margin: 20px 20px 0 20px;
    display: flex;
    padding: 5px 10px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    gap:20px;
    border:2px dashed ${Colors.commonBorderColor};
    background-color: ${Colors.LightGreyCOlor};

    p{
        margin-bottom: 0;
        font-size: 12px;
    }
    button{
        font-size: 13px;
        padding: 3px 10px;
    }
`;