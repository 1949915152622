import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import StatusChangeDropdown from "../../components/dropdowns/statusChangeDropdown";
import { ButtonSecondary, FieldGroupWrapper, FormWrapper } from "../../components/styles/addEditFormStyles";
import { ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper } from "../../components/styles/listingHeaderStyles";
import { useAuth } from "../../hooks/useAuth";
import { CHANGE_ORDER_STATUS, DOWNLOAD_INVOICE, GET_ORDER_DETAILS } from "../../utils/apiEndPoints";
import { callAuthApi, getLocalDateFromUTC, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import moment from "moment";


const OrderDetails = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [orderState, dispatch] = GetReducer();
    const params = useParams();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "orderdetails", heading: "Order Details "+params.orderNumber })
        getOrderDetails();
    }, []);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Changestatus":
                try {
                    let response = await callAuthApi(CHANGE_ORDER_STATUS, {
                        orderNumber: params.orderNumber,
                        status: selectedItem
                    }, user, logout);

                    dispatch({
                        type: "REFRESH_PAGE",
                        response: response.data
                    })

                } catch (errAlert) {
                    dispatch({
                        type: "SET_ALERT",
                        alertState: errAlert
                    });
                }
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const getOrderDetails = async () => {
        try {
            let response = await callAuthApi(GET_ORDER_DETAILS, {
                orderNumber: params.orderNumber
            }, user, logout);

            dispatch({
                type: "SET_RESPONSE",
                response: response.data
            })

        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusChangeDropdownClick = (item) => {
        dispatch({
            type: "SET_ALERT",
            alertState: {
                showAlertDialog: true,
                heading: "Change Order Status",
                message: "Are you sure you want to the change status to " + item + "?",
                type: "ALERT",
                cancelBtn: true,
                okayBtn: true,
                cancelBtnLabel: "Cancel",
                okayBtnLabel: "Change",
                operation: "Changestatus",
                selectedItem: item,
            }
        });
    }

    const getProductPrice = (productPrice, productDiscountedPrice) => {
        let tempPrice = productPrice;
        if (isEmptyVariable(productDiscountedPrice) || productDiscountedPrice === 0) {
        } else {
            tempPrice = productDiscountedPrice;
        }
        return tempPrice;
    }

    const getProductSubtotal = () => {
        let subTotal = 0;
        !isEmptyArray(orderState?.orderDetails?.orderItems) &&
        orderState.orderDetails.orderItems.forEach(item=>{
            subTotal += (getProductPrice(item.productPrice,item.productDiscountedPrice)*item.quantity);
        })

        return subTotal.toFixed(2);
    }

    const getDiscountStr = () => {
        if(isEmptyVariable(orderState?.orderDetails?.discountAmount)){
            return "0.00"
        }

        return "-"+(orderState?.orderDetails?.discountAmount.toFixed(2))
    }

    const downloadInvoice = async () => {
        try {
            let response = await callAuthApi(DOWNLOAD_INVOICE, {
                orderNumber: params.orderNumber,
                timezoneOffset: moment().utcOffset()
            }, user, logout);

            const link = document.createElement('a');
            link.href = response.data;
            var filename = "Order ("+params.orderNumber+")"+".pdf";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <StatusChangeDropdown
                        placeholder={"Select Status"}
                        selectedValue={orderState.orderDetails?.status}
                        options={orderState.orderNextStatus[orderState.orderDetails?.status]}
                        dropdownItemClick={statusChangeDropdownClick}
                    />
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(orderState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{orderState.apiErr}</span>
                    </Col>

                }
                <Col md="7">
                    <FieldGroupWrapper>
                        <h6>Order Details</h6>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Order No</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.orderNumber}</td>
                                </tr>
                                <tr>
                                    <td>Invoice Number</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.invoiceNumber}</td>
                                </tr>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.invoiceDate}</td>
                                </tr>
                                <tr>
                                    <td>Payment Id</td>
                                    <td>:</td>
                                    <td>{ifEmptyReturnStr(orderState.orderDetails?.paymentId,"-")}</td>
                                </tr>
                                <tr>
                                    <td>Razorpay Order Id</td>
                                    <td>:</td>
                                    <td>{ifEmptyReturnStr(orderState.orderDetails?.razorpayOrderId,"-")}</td>
                                </tr>
                                <tr>
                                    <td>Payment Mode</td>
                                    <td>:</td>
                                    <td>
                                        <PaymentSpan
                                            text = {ifEmptyReturnStr(orderState.orderDetails?.paymentMode,"-")}
                                        >
                                            {ifEmptyReturnStr(orderState.orderDetails?.paymentMode,"-")}
                                            </PaymentSpan>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Status</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.paymentStatus}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.status}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{fontWeight:"bold"}}>
                                        Delivery Address
                                    </td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.deliveryFullName}</td>
                                </tr>
                                <tr>
                                    <td>Address Line 1</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.addressLine1}</td>
                                </tr>
                                <tr>
                                    <td>Address Line 2</td>
                                    <td>:</td>
                                    <td>{ifEmptyReturnStr(orderState.orderDetails?.addressLine2,"-")}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.city}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.state}</td>
                                </tr>
                                <tr>
                                    <td>Pincode</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.zipCode}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.phoneNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </FieldGroupWrapper>
                </Col>
                <Col md="5">
                    <FieldGroupWrapper>
                        <h6>Order Status Log</h6>
                        <StatusLogList>
                            {
                                !isEmptyArray(orderState.orderStatusLog) &&
                                orderState.orderStatusLog.map((log, logIdx) => {
                                    return <li key={"statuslog"+logIdx}>
                                        <p>{log.orderStatus}</p>
                                        <span>{getLocalDateFromUTC(log.created_at)}</span>
                                    </li>
                                })
                            }
                            
                        </StatusLogList>
                    </FieldGroupWrapper>
                    <FieldGroupWrapper>
                        <h6>Customer Details</h6>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.fullName}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>:</td>
                                    <td>{orderState.orderDetails?.customerPhoneNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </FieldGroupWrapper>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FieldGroupWrapper>
                        <h6>Order Items</h6>
                        {
                            !isEmptyArray(orderState.orderDetails?.orderItems) &&
                            <>
                            <OrderTable>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:"center"}} width={"1px"}>#</th>
                                        <th>Item</th>
                                        <th style={{textAlign:"center"}} width={"1px"}>Qty</th>
                                        <th style={{textAlign:"center"}} width={"1px"}>Rate</th>
                                        <th style={{textAlign:"center"}} width={"1px"}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                       orderState.orderDetails?.orderItems.map((item, orderIdx) => {
                                            return <tr>
                                                <td>{orderIdx+1}</td>
                                                <td>{item.productName}</td>
                                                <td style={{textAlign:"center"}}>{item.quantity}</td>
                                                <td style={{textAlign:"center"}}>{getProductPrice(item.productPrice, item.productDiscountedPrice).toFixed(2)}</td>
                                                <td style={{textAlign:"right"}}>{(getProductPrice(item.productPrice, item.productDiscountedPrice) * item.quantity).toFixed(2)}</td>
                                            </tr>
                                       }) 
                                    }
                                    <tr>
                                        <td style={{textAlign:"right"}} colSpan={4}>Subtotal: </td>
                                        <td style={{textAlign:"right"}}>{getProductSubtotal()}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:"right"}} colSpan={4}>{"Discount ("+orderState.orderDetails?.discountTitle+"):"} </td>
                                        <td style={{textAlign:"right"}}>{getDiscountStr()}</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:"right",fontWeight:"bold"}} colSpan={4}>Grand Total: </td>
                                        <td style={{textAlign:"right",fontWeight:"bold"}}>{orderState.orderDetails?.grandTotal.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </OrderTable>
                            {
                                !isEmptyVariable(orderState.orderDetails.invoiceNumber) &&
                                <ButtonSecondary onClick={downloadInvoice} className="m-t-20">Download Invoice</ButtonSecondary>
                            }
                            </>
                        }
                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <AlertDialog
            alertState={{ ...orderState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}
const OrderTable = styled.table`
    th{
        padding: 10px 15px;
    }
`;
const StatusLogList = styled.ul`
    li{
        margin-bottom:20px;
    }
    li p{
        margin-bottom: 0;
        font-weight: 500;
    }
    li span{
        display:block;
        font-size: 12px;
    }
`;
const PaymentSpan = styled.span`
    display:inline-block;
    margin:0;
    font-size:11px;
    background: ${props => props.text === 'Online Payment'?"#d8d8d8":"#f9c7a0"};
    padding: 2px 6px;
    border-radius: 3px;
    color: ${props => props.text === 'Online Payment'?"#000":"#000"};
`;
export default OrderDetails;