import { useEffect, useRef, useState } from "react"
import { Modal, Row, Col } from "react-bootstrap";
import { isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";
import Checkbox from "../../components/input/checkbox";
import styled from "styled-components";
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';
import { FieldGroupAddImageWrapper } from "../../components/styles/addEditFormStyles";
import AddImageButton from "../../components/randomComponents/addImageButton";
import DisplayImageLayoutBtn from "../../components/randomComponents/displayImageLayoutBtn";
import ImageLibrary from "../../components/imageLibraryDialog";
import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";

const initialState = {
    productPrice: "",
    productDiscountedPrice: "",
    productCostPerItem: "",
    chargeTax: "N",
    taxPercentage: "",

    trackQuantity: "Y",
    productQuantity: 0,
    productSKU: "",
    productBarcode: "",

    showImageLibraryDialog: false,
    selectedImage: {},

    errors: {
        productPrice: "",
        productQuantity: "",
    }
}

const EditProductVariantProperties = (props) => {
    const { editVariantPropertiesDialog, onClickSubmit, onClickCancel } = props;
    const [dialogState, setDialogState] = useState(initialState);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editVariantPropertiesDialog.showEditVariantProperties) {
            setDialogState({
                ...initialState,
                ...editVariantPropertiesDialog.variant
            });
            inputRef.current.focus();
        }
    }, [editVariantPropertiesDialog.showEditVariantProperties]);

    //Input Change Functions
    const handleDecimalInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9.]/g, '');

        setDialogState({
            ...dialogState,
            [e.target.name]: newValue
        })
    };

    const handleIntegerInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9]/g, '');

        setDialogState({
            ...dialogState,
            [e.target.name]: newValue
        })
    };

    const chargeTaxHandleChange = (e) => {
        setDialogState({
            ...dialogState,
            chargeTax: e.target.checked ? "Y" : "N"
        })
    }

    const trackQuantityHandleChange = (e) => {
        setDialogState({
            ...dialogState,
            trackQuantity: e.target.checked ? "Y" : "N"
        })
    }

    const onInputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    //Image Library Functions
    const showImageLibrary = () => {
        setDialogState({
            ...dialogState,
            showImageLibraryDialog: true
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        setDialogState({
            ...dialogState,
            showImageLibraryDialog: false
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            setDialogState({
                ...dialogState,
                showImageLibraryDialog: false,
                selectedImage: { ...selectedImages[0] }
            })
        }
    }

    const deleteImage = () => {
        setDialogState({
            ...dialogState,
            selectedImage: {}
        })
    }

    //Util Functions
    const getProfit = () => {
        const { productPrice, productDiscountedPrice, productCostPerItem } = dialogState;
        if (isEmptyVariable(productPrice))
            return null;

        let actualPriceStr = productPrice;
        if (!isEmptyVariable(productDiscountedPrice)) {
            actualPriceStr = productDiscountedPrice;
        }

        if (isEmptyVariable(productCostPerItem)) {
            return null;
        }

        try {
            let actualPrice = parseFloat(actualPriceStr);
            let costPerItem = parseFloat(productCostPerItem);

            return (actualPrice - costPerItem) + "";

        } catch (e) {
            return null;
        }
    }

    const getProfitPercentage = () => {
        const { productPrice, productDiscountedPrice, productCostPerItem } = dialogState;
        if (isEmptyVariable(productPrice))
            return null;

        let actualPriceStr = productPrice;
        if (!isEmptyVariable(productDiscountedPrice)) {
            actualPriceStr = productDiscountedPrice;
        }

        if (isEmptyVariable(productCostPerItem)) {
            return null;
        }

        try {
            let actualPrice = parseFloat(actualPriceStr);
            let costPerItem = parseFloat(productCostPerItem);
            let profit = (actualPrice - costPerItem);
            let profitPerc = (profit / costPerItem) * 100;
            return profitPerc.toFixed(2);

        } catch (e) {
            return null;
        }
    }


    const onClickSubmitBtn = () => {
        const { productPrice } = dialogState;
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(productPrice)) {
            hasErrors = true;
            errors.productPrice = "Please enter the price"
        }

        if (!hasErrors) {
            let variant = { ...dialogState}
            delete variant.errors;
            onClickSubmit(variant);
        } else {
            setDialogState({
                ...dialogState,
                errors: { ...errors }
            })
        }
    }

    return <>
        <CustomModal show={editVariantPropertiesDialog.showEditVariantProperties} onHide={onClickCancel}
            dialogClassName="variant-edit-dialog">
            <Modal.Header closeButton>
                <Modal.Title>{"Edit Product Variant"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FieldGroupWrapper>
                    <h6>Pricing</h6>
                    <Row>
                        <Col md={4}>
                            <label>Price</label>
                            <input
                                placeholder={"0.00"}
                                value={dialogState.productPrice}
                                name="productPrice"
                                onChange={handleDecimalInputChange}
                                className="m-b-20"
                                ref={inputRef}
                            />
                            {
                                !isEmptyVariable(dialogState.errors?.productPrice) &&
                                <span className="err-span m-b-10">{dialogState.errors.productPrice}</span>
                            }
                        </Col>
                        <Col md={4}>
                            <label>Discounted Price</label>
                            <input
                                placeholder={"0.00"}
                                value={dialogState.productDiscountedPrice}
                                name="productDiscountedPrice"
                                onChange={handleDecimalInputChange}
                                className="m-b-20"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <label>Cost Per Item</label>
                            <input
                                placeholder={"0.00"}
                                value={dialogState.productCostPerItem}
                                name="productCostPerItem"
                                onChange={handleDecimalInputChange}
                                className="m-b-0"
                            />
                        </Col>
                        <Col md={4}>
                            <label>Profit</label>
                            <input
                                placeholder={"0.00"}
                                value={getProfit()}
                                className="m-b-0 input-disabled"
                                disabled
                            />
                        </Col>
                        <Col md={4}>
                            <label>Profit %</label>
                            <input
                                placeholder={"0%"}
                                value={getProfitPercentage()}
                                className="m-b-0 input-disabled"
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <label className='m-t-15'></label>
                            <Checkbox
                                checkBoxId={"chargetax"}
                                checkboxLabel={"Charge Tax"}
                                handleChange={chargeTaxHandleChange}
                                checked={dialogState.chargeTax === "Y"}
                            />
                            <div className='m-b-15'></div>
                        </Col>

                        <Col md={6}>
                            <label>Tax Percentage</label>
                            <input
                                placeholder={"0.00"}
                                value={dialogState.taxPercentage}
                                name="taxPercentage"
                                onChange={handleDecimalInputChange}
                                className={dialogState.chargeTax === "N" ? "m-b-0 input-disabled" : "m-b-0"}
                                disabled={dialogState.chargeTax === "N"}
                            />
                        </Col>
                    </Row>

                </FieldGroupWrapper>

                <Row>
                    <Col md="6">
                        <FieldGroupWrapper>
                            <h6>Inventory</h6>
                            <Row>
                                <Col md={12}>
                                    <Checkbox
                                        checkBoxId={"trackquantity"}
                                        checkboxLabel={"Track Quantity"}
                                        handleChange={trackQuantityHandleChange}
                                        checked={dialogState.trackQuantity === "Y"}
                                    />
                                    <div className='m-b-15'></div>
                                </Col>
                                <Col md={12}>
                                    <label>Quantity</label>
                                    <input
                                        placeholder={"0"}
                                        value={dialogState.productQuantity}
                                        name="productQuantity"
                                        onChange={handleIntegerInputChange}
                                        className={dialogState.trackQuantity === "N" ? "m-b-20 input-disabled" : "m-b-20"}
                                        disabled={dialogState.trackQuantity === "N"}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <label>SKU (Stock Keeping Unit)</label>
                                    <input
                                        placeholder={"Enter SKU"}
                                        value={dialogState.productSKU}
                                        name="productSKU"
                                        onChange={onInputChange}
                                        className="m-b-20"
                                    />
                                </Col>

                                <Col md={12}>
                                    <label>Barcode</label>
                                    <input
                                        placeholder={"Enter Barcode"}
                                        value={dialogState.productBarcode}
                                        name="productBarcode"
                                        onChange={onInputChange}
                                        className="m-b-0"
                                    />
                                </Col>
                            </Row>
                        </FieldGroupWrapper>
                    </Col>
                    <Col md="6">
                        <FieldGroupAddImageWrapper>
                            <h6>Variant Image</h6>
                            {
                                isEmptyVariable(dialogState.selectedImage?.productAttachmentId)
                                    ?
                                    <AddImageButton
                                        text="Add Image"
                                        showImageLibrary={showImageLibrary}
                                        layoutHeight={"200px"}
                                    />
                                    :
                                    <DisplayImageLayoutBtn
                                        showImageLibrary={showImageLibrary}
                                        deleteImage={deleteImage}
                                        imageSrc={ATTACHMENT_PATH + dialogState.selectedImage.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                        layoutHeight="200px"
                                    />
                            }
                            {
                                !isEmptyVariable(dialogState.errors?.productAttachmentId) &&
                                <span className="err-span m-t-10 m-b-10">{dialogState.errors.productAttachmentId}</span>
                            }
                        </FieldGroupAddImageWrapper>
                    </Col>
                </Row>




                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>

            <ImageLibrary
                showDialog={dialogState.showImageLibraryDialog}
                onClickSubmit={onClickImageLibraryDialogSubmit}
                onClickCancel={onClickImageLibraryDialogCancel}
                selectedImages={[{ ...dialogState.selectedImage }]}
            />
        </CustomModal>
    </>
}

export default EditProductVariantProperties;


const FieldGroupWrapper = styled.div`
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    border-radius:4px;

    h6{

    }
`;