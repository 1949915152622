import { useEffect, useRef, useState } from "react"
import { Modal, Row, Col } from "react-bootstrap";
import { isEmptyVariable } from "../../utils/utilFunctions";
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const initialState = {
    productOptionValue: "",
    errors: {
    }
}

const AddEditProductOptionValue = (props) => {
    const { addEditOptionValueDialog, onClickSubmit, onClickCancel } = props;
    const [dialogState, setDialogState] = useState(initialState);
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditOptionValueDialog.showAddEditOptionValue) {
            setDialogState({
                ...initialState,
                productOptionValue: addEditOptionValueDialog.productOptionValue
            });
            inputRef.current.focus();
        }
    }, [addEditOptionValueDialog.showAddEditOptionValue]);

    const onInputChange = (e) => {
        setDialogState({
            ...dialogState,
            productOptionValue: e.target.value
        })
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = () => {
        const { productOptionValue } = dialogState;
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(productOptionValue)) {
            hasErrors = true;
            errors.productOptionValue = "Please enter the option value"
        }

        if (!hasErrors) {
            onClickSubmit(productOptionValue, addEditOptionValueDialog.optionsIndex, addEditOptionValueDialog.optionValuesIdx);
        } else {
            setDialogState({
                ...dialogState,
                errors: { ...errors }
            })
        }
    }

    return <>
        <CustomModal show={addEditOptionValueDialog.showAddEditOptionValue} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{addEditOptionValueDialog.isEdit ? "Edit Product Option Value":"Add Product Option Value"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <label>Option Value</label>
                        <input
                            value={dialogState.productOptionValue}
                            name={"productOptionValue"}
                            onChange={onInputChange}
                            className="m-b-20"
                            autoFocus
                            ref={inputRef}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productOptionValue) &&
                            <span className="err-span m-b-10">{dialogState.errors.productOptionValue}</span>
                        }
                    </Col>
                </Row>

                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>
    </>
}

export default AddEditProductOptionValue;