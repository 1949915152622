import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';

export const ListingHeaderWrapper = styled.div`
    background:${Colors.whiteColor};
    position:sticky;
    top:0;
    z-index:1;
`;

export const ListingHeaderLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-top:1px solid ${Colors.inputBorderColor};
    border-bottom:1px solid ${Colors.inputBorderColor};
    height:46px;
    display:flex;
    align-items:center;
    padding:0px 20px;
`;

export const ListingHeaderLeftLayout = styled.div`
    display:flex;
    align-items:center;

    h6{
        margin-bottom: 0;
    }

    p{
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 13px;
        font-weight: bold;
        font-style: italic;
    }
    
`;

export const BackButton = styled.button`
    border:none;
    border-radius:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height:32px;
    width:32px;
    background: none;
    flex-shrink:0;
    margin-right:10px;

    svg{
        color:${Colors.backButtonIconColor};
    }

    &:hover{
        background: ${Colors.backButtonHoverBg};
    }
`;

export const ListingHeaderRightLayout = styled.div`
    button{
        display:flex;
        background:${Colors.themeColorPrimary};
        border:none;
        color:${Colors.whiteColor};
        padding:5px 20px;
        border-radius:4px;
        font-size:13px;
        transition:all .5s;
        align-items:center;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
            transition:all .5s;
        }

        svg{
            width:16px;
            flex-shrink:0;
            margin-right:5px;
        }
    }

    button[disabled]{
        background:${Colors.themeColorPrimary + "88"};
    }

`;

export const SearchBox = styled.div`
    display:flex;
    align-items:center;
    position: relative;
    background:${Colors.searchBackgroundColor};
    border:1px solid ${Colors.searchInputBorderColor};
    padding:0px;
    border-radius:4px;
    width:250px;
    margin-left:0px;
    height:30px;
    
    button{
        position:absolute;
        right:5px;
        background:none;
        box-shadow:none;
        padding:0;
        margin:0;
        border:none;
        line-height:1;

        &:hover{
            background-color:transparent;
        }
        
        svg{
            width:15px;
            color:${Colors.themeColorPrimary};
            flex-shrink:0;
        }
    }
    input{
        border:none;
        border-radius:4px;
        background:${Colors.searchBackgroundColor};
        height:100%;
        margin-left:0px;
        padding:5px 25px 5px 10px;

        width:100%;
        font-size:13px;

        &:focus{
            outline:0;
        }
    }
    input:placeholder{
        color:${Colors.paragraphColor};
    }
    .search-close-btn{
        margin-right:5px;
        svg{
            color:${Colors.greyColor};
        }
        &:hover{
            opacity:.8;
        }
    }
`;