import styled from "styled-components";
import { ifEmptyReturnStr, isEmptyVariable } from "../../utils/utilFunctions";
import { Colors } from "../../components/styles/colors";
import { ATTACHMENT_PATH } from "../../utils/apiEndPoints";
import { FaRegImage } from "react-icons/fa";

const VariantTable = (props) => {
    const { variants, productOptions, showEditVariantDialog } = props;
    return <>
        {
            productOptions.length === 1
                ?
                <SingleOptionTable
                    variants={variants}
                    productOptions={productOptions}
                    showEditVariantDialog={showEditVariantDialog}
                />
                :
                <TwoOptionsTable
                    variants={variants}
                    productOptions={productOptions}
                    showEditVariantDialog={showEditVariantDialog}
                />
        }
    </>
}

const SingleOptionTable = (props) => {
    const { variants, productOptions, showEditVariantDialog } = props;
    const { optionValues } = productOptions[0];
    return <>
        <ContentTableWrap>
            <table>
                <tr>
                    
                    <th></th>
                    <th>Option</th>
                    <th>Price</th>
                    <th>Discounted Price</th>
                    <th>Quantity</th>
                    <th></th>
                </tr>
                {
                    optionValues.map((option, optionIdx) => {
                        let variant = variants[option.feIndex];
                        return <tr key={"optionvalue" + optionIdx}>
                            <td style={{ textAlign: "center", width: "50px" }}>
                                {
                                    !isEmptyVariable(variant?.selectedImage?.productAttachmentPath)
                                    ?
                                    <img src={ATTACHMENT_PATH + variant?.selectedImage?.productAttachmentPath.replace("/images/", "/thumbnails/")} />
                                    :
                                    <ImagePlaceholder><FaRegImage /></ImagePlaceholder>
                                }   
                            </td>
                            <td>{option.productOptionValue}</td>
                            <td>{ifEmptyReturnStr(variant.productPrice,"0.00")}</td>
                            <td>{ifEmptyReturnStr(variant.productDiscountedPrice,"0.00")}</td>
                            <td>{ifEmptyReturnStr(variant.productQuantity,"0.00")}</td>
                            <td><button onClick={()=>showEditVariantDialog(option.feIndex)}>Edit</button></td>
                        </tr>
                    })
                }
            </table>
        </ContentTableWrap>
    </>
}

const TwoOptionsTable = (props) => {
    const { variants, productOptions, showEditVariantDialog } = props;
    return <>
        <ContentTableWrap>
            <table>
                <tr>
                    <th>Option 1</th>
                    <th>Option 2</th>
                    <th></th>
                    <th>Price</th>
                    <th>Discounted Price</th>
                    <th>Quantity</th>
                    <th></th>
                </tr>

                {
                    productOptions[0].optionValues.map((productOption1, op1ValIdx) => {
                        return productOptions[1].optionValues.map((productOption2, op2ValIdx) => {
                            let variant = variants[productOption1.feIndex][productOption2.feIndex];
                            return <tr key = {"variants"+op2ValIdx+"-"+op1ValIdx}>
                                {
                                    op2ValIdx === 0 &&
                                    <td style = {{borderRight:"1px solid #EBEBEB"}}
                                    rowspan = {op2ValIdx === 0 ? productOptions[1].optionValues.length : 1} >
                                        {productOption1.productOptionValue}
                                    </td>
                                }
                                <td>{productOption2.productOptionValue}</td>
                                <td></td>
                                <td>{ifEmptyReturnStr(variant.productPrice,"0.00")}</td>
                                <td>{ifEmptyReturnStr(variant.productDiscountedPrice,"0.00")}</td>
                                <td>{ifEmptyReturnStr(variant.productQuantity,"0")}</td>
                                <td><button onClick={()=>showEditVariantDialog(productOption1.feIndex, productOption2.feIndex)}>Edit</button></td>
                            </tr>
                        })
                    })
                }
            </table>
        </ContentTableWrap>
    </>
}
export default VariantTable;


const ContentTableWrap = styled.div`
    width:100%;
    padding:0;

    button{
        background:transparent;
        border:1px solid ${Colors.themeColorPrimary};
        color:${Colors.blackColor};
        padding:3px 12px;
        border-radius:4px;
        font-size:12px;

        &:hover{
            background:${Colors.themeColorPrimary+"22"};
        }
    }
`;

const ImagePlaceholder = styled.div`
    border: 1px solid #dddddd;
    margin-bottom: 0px;
    border-radius:4px;
    width:100%;
    overflow: hidden;
    padding:10px;

    svg{
        font-size:30px;
        color: ${Colors.themeColorPrimary+"66"};
        margin-bottom:0px;
    }

`;