import Modal from 'react-bootstrap/Modal';
import { isEmptyVariable } from '../../utils/utilFunctions';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import styled from 'styled-components';
import { CustomAlertModal } from '../styles/alertDialogStyles';

const AlertDialogWrapper = styled.div`

`;

const AlertDialog = (props) => {
    const { alertState, onClickCancel, onClickSubmit } = props;
    return <AlertDialogWrapper >
        <CustomAlertModal show={alertState.showAlertDialog}
            onHide={alertState.handleAlertDialogClose}>

            <Modal.Header closeButton onClick={() => onClickCancel()}>
            </Modal.Header>

            <Modal.Body>
                <AiOutlineExclamationCircle />
                {
                    !isEmptyVariable(alertState.heading) &&
                    <h4>{alertState.heading}</h4>
                }

                {
                    !isEmptyVariable(alertState.message) &&
                    <p>{alertState.message}</p>
                }

                {
                    alertState.cancelBtn &&
                    <button className="cancel-btn" onClick={() => onClickCancel()}>
                        {alertState.cancelBtnLabel}
                    </button>
                }
                {
                    alertState.okayBtn &&
                    <button onClick={() => onClickSubmit(alertState.operation, alertState.selectedItem)}>
                        {alertState.okayBtnLabel}
                    </button>
                }
            </Modal.Body>

        </CustomAlertModal>

    </AlertDialogWrapper>
}

export default AlertDialog;