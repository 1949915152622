import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextBaseLayout } from "./components/baseLayout";

import Login from './pages/login';
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from './pages/resetPassword';
import Dashboard from './pages/dashboard';
import MerchantUsers from './pages/merchantUsers';
import LogoutComp from './pages/logout';
import Profile from './pages/profile';
import 'bootstrap/dist/css/bootstrap.min.css';
import VendorList from "./pages/vendor";
import TagList from "./pages/tag";
import ProductTypeList from "./pages/productType";
import ProductUdfList from "./pages/productUdf";
import ProductGroupList from "./pages/productGroup";
import ProductList from "./pages/product";
import Blog from "./pages/blog";
import ApiComposer from "./pages/apiComposer";
import CustomerPages from "./pages/customerPages";
import InventoryList from "./pages/productInventory";
import Policies from "./pages/policies";
import MerchantDetails from "./pages/merchantDetails";
import ReviewList from "./pages/reviews";
import CustomerList from "./pages/customers";
import Discount from "./pages/discount";
import ProductCategory from "./pages/productCategory";
import OrderList from "./pages/order";
import OrderDetails from "./pages/orderDetails";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword/:code" element={<ResetPassword />} />
                

                <Route path="/" element={<ContextBaseLayout />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="staff" element={<MerchantUsers />} />
                    <Route path="vendors" element={<VendorList />} />
                    <Route path="tags" element={<TagList />} />
                    <Route path="producttypes" element={<ProductTypeList />} />
                    <Route path="customfields" element={<ProductUdfList />} />
                    <Route path="collections" element={<ProductGroupList />} />
                    <Route path="products" element={<ProductList />} />
                    <Route path="blogs" element={<Blog />} />
                    <Route path="customerapis" element={<ApiComposer />} />
                    <Route path="pages" element={<CustomerPages />} />
                    <Route path="inventory" element={<InventoryList />} />
                    <Route path="policies" element={<Policies />} />
                    <Route path="merchant" element={<MerchantDetails />} />
                    <Route path="reviews" element={<ReviewList />} />
                    <Route path="customers" element={<CustomerList />} />
                    <Route path="discounts" element={<Discount />} />
                    <Route path="categories" element={<ProductCategory />} />
                    <Route path="orders" element={<OrderList />} />
                    <Route path="orderdetails/:orderNumber" element={<OrderDetails />} />

                    <Route path="profile" element={<Profile />} />
                    
                    <Route path="logout" element={<LogoutComp />} />
                </Route>

            </Routes>
        </BrowserRouter>
    );
}

export default App;
