import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import styled from "styled-components";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    DELETE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable
} from "../../utils/utilFunctions";

const CustomerApiList = (props) => {
    const { customerApiState, onClickResultSize, onSearchInputChange, searchList,
        statusDropdownClick, showCreateApiComp, onClickTableActionItem,
        sortTableLocal, getCustomerApiList } = props;

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }
    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={customerApiState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search Apis..."
                            value={customerApiState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={customerApiState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateApiComp}><BsFillPlusCircleFill />Add Api</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            customerApiState.firstLoad &&
            <>
                {
                    !isEmptyArray(customerApiState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Api Name"}
                                    sort={customerApiState.sort}
                                    sortDir={customerApiState.sortDir}
                                    tableSortCol={"c.customerApiName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Modules"}
                                    sort={customerApiState.sort}
                                    sortDir={customerApiState.sortDir}
                                    tableSortCol={"pgcv.productCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={customerApiState.sort}
                                    sortDir={customerApiState.sortDir}
                                    tableSortCol={"pg.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={customerApiState.sort}
                                    sortDir={customerApiState.sortDir}
                                    tableSortCol={"pg.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                customerApiState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.customerApiName}
                                            <StatusSpan
                                                status = {item.customerApiStatus}
                                            />
                                        </td>
                                        <td>{item.customerApiModuleList.length}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.customerApiStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getCustomerApiList(e.selected + 1)}
                            pageRangeDisplayed={customerApiState.resultSize}
                            pageCount={getPageCount(customerApiState.response.count, customerApiState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={customerApiState.pageNo - 1}
                            totalRowCount = {customerApiState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(customerApiState.response.result) &&
                    !customerApiState.isPageLoading &&
                    !isEmptyVariable(customerApiState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(customerApiState.response.result) &&
                    !customerApiState.isPageLoading &&
                    isEmptyVariable(customerApiState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
    </>
}

export default CustomerApiList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;