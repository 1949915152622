import { useReducer } from 'react';

const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    errors: {},
    isLoading: false,

    showImageLibraryDialog: false,
    productAttachmentId:"",
    updateFlag:false,
    deleteFlag:false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FIELDVALUE": {
            return {
                ...state,
                [action.fieldName]: action.fieldValue
            }
        }
        case "SET_LOGIN_LOADER_FLAG": {
            return {
                ...state,
                isLoading: action.flag
            }
        }
        case "SET_VALIDATION_ERRORS": {
            return {
                ...state,
                errors: { ...action.errors }
            }
        }
        case "RESET_CHANGE_PASSWORD_FIELDS": {
            return {
                ...state,
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
                errors: {},
                isLoading: false,
                apiResponse: action.response,
            }
        }
        case "HIDE_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: false
            }
        }
        case "SHOW_IMAGE_LIBRARY": {
            return {
                ...state,
                showImageLibraryDialog: true
            }
        }
        case "IMAGE_LIBRARY_SUBMIT": {
            return {
                ...state,
                showImageLibraryDialog: false,
                productAttachmentId: action.productAttachmentId,
                updateFlag: true
            }
        }
        case "UPDATE_COMPLETE": {
            return {
                ...state,
                productAttachmentId: "",
                updateFlag: false
            }
        }
        case "DELETE_PROFILE_PIC_CLICK": {
            return {
                ...state,
                deleteFlag: true
            }
        }
        case "DELETE_PROFILE_PIC_COMPLETE": {
            return {
                ...state,
                deleteFlag: false
            }
        }
        default: {

        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};