import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import styled from "styled-components";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    getLocalDateFromUTC,
    getPageCount,
    isEmptyArray,
    isEmptyVariable
} from "../../utils/utilFunctions";

const DiscountList = (props) => {
    const { discountState, onClickResultSize, onSearchInputChange, searchList,
        statusDropdownClick, displayDiscountTypeDialog, onClickTableActionItem,
        sortTableLocal, getDiscountList } = props;

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }
    const getDiscountStr = (item) => {
        let str = "";

        if(item.discountValueType === "Fixed Amount"){
            str = item.discountFixedAmount + " Rs OFF";
        } else if(item.discountValueType === "Percentage"){
            str = item.discountPercentage + "% OFF";
        } else{

        }

        if(item.minimumRequirements === "No"){

        } else if(item.minimumRequirements === "Min Order Amount"){
            str += " for a minimum purchase of "+ item.minimumPurchaseAmount; 
        } else if(item.minimumRequirements === "No"){

        }

        return str;
    }
    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={discountState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search Discounts..."
                            value={discountState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={discountState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={displayDiscountTypeDialog}><BsFillPlusCircleFill />Add Discount</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            discountState.firstLoad &&
            <>
                {
                    !isEmptyArray(discountState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Title"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.discountTitle"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Discount Type"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.discountType"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Discount"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.discountValueType"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Discount To"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.discountTo"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Customers"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.allCustomers"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Usage"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.usageLimit"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Start Date"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.startDateTime"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"End Date"}
                                    sort={discountState.sort}
                                    sortDir={discountState.sortDir}
                                    tableSortCol={"d.endDateTime"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                discountState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.discountTitle}
                                            {
                                                item.isCoupon === "Y" &&
                                                <span style={{ display: "block", margin: 0, fontSize: 12, fontWeight: "bold" }}>
                                                    {item.couponCode}
                                                </span>
                                            }
                                            <StatusSpan
                                                status={item.discountStatus}
                                            />
                                        </td>
                                        <td>{item.discountType}</td>
                                        <td>
                                            {getDiscountStr(item)}
                                        </td>
                                        <td>{item.discountTo}</td>
                                        <td>{item.allCustomers === "Y" ? "All" : "Specific"}</td>
                                        <td>{item.usageLimit}</td>
                                        <td>{getLocalDateFromUTC(item.startDateTime)}</td>
                                        <td>{isEmptyVariable(item.endDateTime) ? "-" : getLocalDateFromUTC(item.endDateTime)}</td>
                                        <td>
                                            <TableDropdown
                                                actionArr={getActionArr(item.discountStatus)}
                                                onClickTableActionItem={onClickTableActionItem}
                                                item={item}
                                            />
                                        </td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getDiscountList(e.selected + 1)}
                            pageRangeDisplayed={discountState.resultSize}
                            pageCount={getPageCount(discountState.response.count, discountState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={discountState.pageNo - 1}
                            totalRowCount = {discountState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(discountState.response.result) &&
                    !discountState.isPageLoading &&
                    !isEmptyVariable(discountState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(discountState.response.result) &&
                    !discountState.isPageLoading &&
                    isEmptyVariable(discountState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
    </>
}

export default DiscountList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;