import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import styled from "styled-components";
import { Colors } from "../styles/colors";

const LoadingWrapper = styled.div`
    position:absolute;
    top:0;
    left:50%;
`;

const LoadingBox = styled.div`
    display:flex;
    align-items:center;
    background-color:${Colors.themeColorPrimaryHover+"33"};
    padding:5px 20px;
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    p{
        margin:0;
        font-size:13px;
        margin-right:5px;
        color:${Colors.blackColor};
    }
    @keyframes rotate{
        to{ transform: rotate(360deg); }
    }
    svg{
        width:15px;
        color:${Colors.themeColorPrimary};
        animation: rotate 2.5s linear infinite; 
    }
`;

const Loader = () =>{
    return <LoadingWrapper>
            <LoadingBox>
                <p>Loading</p>
                <BiLoaderCircle/>
            </LoadingBox>
    </LoadingWrapper>
}
export default Loader;