import { useEffect } from "react";
import { isEmptyVariable } from "../../utils/utilFunctions";
import styled from "styled-components";
import { Colors } from "../styles/colors";

const SnackBar = ({ showSnackbar, message, closeSnackbar, timeStamp}) => {
    let timer = null;

    useEffect(()=>{
        if(showSnackbar){
            if(!isEmptyVariable(timer)){
                clearTimeout(timer);
            }

            timer = setTimeout(()=>{
                closeSnackbar();
                timer = null;
            }, 5000)
        }

        return () => clearTimeout(timer);
    }, [timeStamp]);

    return <>
        {
            showSnackbar && !isEmptyVariable(message) &&
            <SnackbarWrapper>
                <div className={showSnackbar ? "show" : ""}>{message}</div>
            </SnackbarWrapper>
        }

    </>
}
export default SnackBar;

const SnackbarWrapper = styled.div`
    div{
        min-width: 250px;
        margin-left: -125px;
        background-color: ${Colors.themeColorPrimary+"dd"};
        color: #fff;
        text-align: center;
        border-radius: 2px;
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 30px;
        font-size: 14px;
        line-height: 1;
        border-radius: 4px;
        padding: 15px 20px;
    }

    .show{
        // -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
        // animation: fadein 0.5s, fadeout 0.5s 4.5s;
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;
    }

    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
`;