import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_PRODUCTS, DELETE_PRODUCT, UPDATE_PRODUCT_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateProduct from "./addEditProduct";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import ProductList from "./productListing";


const Product = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [productState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "products", heading: "Products" })
        getProductList();
    }, []);

    useEffect(() => {
        if (productState.refreshFlag) {
            getProductList();
        }

    }, [productState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productUniqueId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productUniqueId, "Active");
                break;
            case "Delete":
                await deleteProduct(selectedItem.productUniqueId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateDialog = () => {
        dispatch({
            type: "SHOW_CREATE_DIALOG"
        });
    }

    const onClickDialogCancel = () => {
        dispatch({
            type: "RESET_DIALOG"
        });
    }

    const onClickDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_DIALOG",
                    addEditComp: {
                        showAddEditComp: true,
                        isProductEdit: true,
                        productUniqueId: item.productUniqueId,
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate ",
                        message: "Are you sure you want to deactivate " + item.productName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate ",
                        message: "Are you sure you want to activate " + item.productName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete ",
                        message: "Are you sure you want to delete " + item.productName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (productState.search != productState.searchApiKey) {
            dispatch({ type: "RELOAD_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, productState.sort, productState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getProductList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: productState.search,
            sort: productState.sort,
            sortDir: productState.sortDir,
            resultSize: productState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? productState.pageNo : pageNo,
            masterData: "Y"
        }

        if (productState.status !== "All") {
            post.status = productState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCTS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productUniqueId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_STATUS, {
                productUniqueId: productUniqueId,
                productStatus: status
            }, user, logout);

            getProductList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteProduct = async (productUniqueId) => {
        try {
            await callAuthApi(DELETE_PRODUCT, {
                productUniqueId: productUniqueId,
            }, user, logout);

            getProductList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            productState.isPageLoading &&
            <Loader />
        }

        {
            !productState.addEditComp?.showAddEditComp &&
            <ProductList
                productState={productState}
                onClickResultSize={onClickResultSize}
                showCreateDialog={showCreateDialog}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getProductList={getProductList}
            />
        }

        {
            productState.addEditComp?.showAddEditComp &&
            <CreateUpdateProduct
                addEditComp={{
                    ...productState.addEditComp
                }}
                onClickSubmit={onClickDialogSubmit}
                onClickCancel={onClickDialogCancel}
            />
        }

        <AlertDialog
            alertState={{ ...productState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default Product;


//STYLES
const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;