import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ButtonIconLayout, ButtonSecondary } from "../../components/styles/addEditFormStyles";

export const MediaLayout = styled.div`
    margin-bottom: 10px;
    position:relative;
    img{
        height:140px;
        margin-bottom: 5px;
        border-radius: 4px;
    }
`;
export const Row5 = styled(Row)`
    margin-left: -5px;
    margin-right: -5px;
`;
export const Col5 = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`;
export const OptionWrapper = styled.div`
    margin-bottom:20px;

    &:last-child{
        margin-bottom:0;
    }
`;
export const OptionNameLayout = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h6{
        margin:0;
        font-size: 16px;
        line-height: 16px;
    }
`;
export const OptionNameBtnLayout = styled.div`
    display:flex;
    align-items:center;
`;
export const OptionValueWrapper = styled.div`
    display:flex;
    align-items:center;
`;
export const OptionValueLayout = styled.div`
    display:flex;
    align-items:center;
    background: #f5f5f5;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 12px;

    &:first-child{
        margin-left: 0;
    }

    span{
        margin:0;
        line-height:1;
    }
`;
export const OptionValueBtnLayout = styled.div`
    display:flex;
    align-items:center;
    margin-left:12px;
`;
export const AddOptionButton = styled(ButtonSecondary)`
    width: 100%;
    background: #fbfbfb;
    padding: 5px;
    color: #222;
    border: 1px solid #0002;
    margin-top: 15px;

    &:hover{
        background: #ddd;
    }
`;
export const ButtonIconLayoutOptions = styled(ButtonIconLayout)`
    padding: 4px;
    line-height:1;

    svg{
        font-size: 14px;
    }
`;
export const AddNewOptionValueLayout = styled(ButtonIconLayout)`
    margin-left: 10px;
    border: 1px solid #ddd;
`;