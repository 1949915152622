import { DisplayImageLoaderWrapper, DisplayImageLoaderLayout } from "./imagelibrarydialogstyle";
import { TbLoader } from "react-icons/tb";

const DisplayImageLoder = () => {
    return <DisplayImageLoaderLayout>
        <DisplayImageLoaderWrapper>
            <TbLoader />
        </DisplayImageLoaderWrapper>
    </DisplayImageLoaderLayout>
}
export default DisplayImageLoder;