import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import styled from 'styled-components';
import { Colors } from '../styles/colors';

const DatetimePickerComp = (props) => {
    const {onChange, value} = props;
    return <DateTimePickerLayout>
        <DateTimePicker 
            onChange={onChange} 
            value={value} 
        />
    </DateTimePickerLayout>
}

export default DatetimePickerComp;

const DateTimePickerLayout = styled.div`
    input {
        border: none;
        border-radius:0;
        padding:1px;
        margin-bottom:0px;
        height:calc(100% - 2px);
        box-sizing: content-box;
        font-size:13px;
    }
    .react-datetime-picker__inputGroup__input--hasLeadingZero{
        padding-left: calc(1px + 0.54em);
    }
    .react-datetime-picker__inputGroup__leadingZero{
        font-size: 13px;
    }
    .react-datetime-picker__clock{
        border: thin solid #eee;
        box-shadow: 0px 0px 5px 2px #ddd;
    }
    .react-datetime-picker__calendar .react-calendar{
        border: thin solid #eee;
        box-shadow: 0px 0px 5px 2px #ddd;
    }
    button:hover{
        background-color: ${Colors.datePickerButtonBgColorHover};
    }
    span{
        margin-bottom:0;
    }
    .react-calendar__tile, .react-calendar__navigation__arrow{ 
        color: ${Colors.datePickerCalenderDayColor};
    }
    .react-datetime-picker{
        width: 100%;
    }
    .react-datetime-picker__wrapper{
        border-radius:4px;
        border: 1px solid ${Colors.modalInputBorderColor};
        padding: 5px 8px;
    }
`;