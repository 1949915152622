import styled from 'styled-components';
import { Colors } from '../styles/colors';

export const SidebarSection = styled.section`
    border-right:1px solid ${Colors.mainSidebarBorderColor};
    height: calc(100vh - 105px);
    width:270px;
    flex-shrink:0;
    overflow-y:auto;
    height:100vh;
    
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        // border-radius:25px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const SidebarWrap = styled.div`

`;

export const LogoBox = styled.div`
    padding:10px 15px;
    border-bottom:1px solid ${Colors.mainSidebarLogoBottomBorderColor};
    height:58.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        max-width:230px;
        max-height:37.5px;
    }
    p{
        font-size: 18px;
        font-weight: bold;
        margin: 0;
    }
`;

export const DashBoardMenuBox = styled.div`
    max-height: 500px;
    overflow-y: auto;

    ul{
        list-style:none;
        padding:0;
        margin:0;
    }
    ul li{
        display:flex;
        align-items:center;
        list-style:none;
        position:relative;

        &:hover{
            background-color:${Colors.themeColorPrimary + "11"};
        }
    }
    ul li:last-child{
        margin-bottom:0;
    }
    ul li.active{
        background-color:${Colors.themeColorPrimary + "11"};
        position:relative;
    }
    ul li.active::before{
        content:"";
        border-left:5px solid ${Colors.themeColorPrimary};
        position:absolute;
        display:inline-block;
        width:10px;
        height:100%;
        left:0;
    }
    ul li a{
        display:flex;
        text-decoration:none;
        align-items:center;
        width:100%;
        padding: 8px 20px;

        span{
            margin-left:8px;
            font-size:13px;
            line-height:1;
            margin-bottom:0;
            color:${Colors.mainSidebarTextColor};
        }
        svg{
            font-size: 20px;
            color:${Colors.mainSidebarIconColor};
        }
    }

    .item-selected{
        background:${Colors.themeColorPrimary + "11"};
        span{
            color:${Colors.themeColorPrimary};
            font-weight: 500;
        }
        svg{
            color:${Colors.themeColorPrimary};
        }
    }
`;

export const AccordionWrapper = styled.div`
    .accordion{
        border-radius: 0;
        border: none;
    }
    .accordion-body{
        padding:0;
    }
    .accordion-item{
        border-radius:0;
        border:0px solid;
    }
    .accordion-header{
        border-radius:0;
    }
    .accordion-item:first-of-type .accordion-button{
        border-radius:0;
    }
    .accordion-button::after{
        width:18px;
        height:18px;
        background-size:18px;
    }
    .accordion-button{
        display:flex;
        align-items:center;
        padding:8px 20px;
        background:${Colors.themeColorPrimary + "11"};
        line-height:1;
        
        span{
            margin-left: 8px;
            font-size: 13px;
            margin-bottom: 0;
            line-height: 1;
            color:${Colors.accordionHeaderTextColor};
        }

        svg{
            font-size: 20px;
            color: ${Colors.accordionHeaderIconColor};
        }
    }
    .accordion-button.collapsed{
        background:${Colors.whiteColor};
        span{
            color: ${Colors.accordionHeaderTextColor};
        }

        svg{
            color: ${Colors.accordionHeaderIconColor};
        }
    }
    .accordion-button:focus{
        border:none;
        box-shadow:none;
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%333%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e') !important;
    }    
`;

export const AccordionBodyLayout = styled.div`
    margin:0px 0px;
    ul{
        padding:0;
        margin:0;
        position:relative;
    }

    ul li{
        position:relative;
        &:hover{
            background-color:${Colors.themeColorPrimary + "11"};
        }
    }
    ul li a{
        color:${Colors.blackColor};
        font-size:13px;
        display:flex;
        align-items:center;
        list-style:none;
        padding: 6px 20px 6px 45px;
        margin-bottom:0;
        line-height: 1.45;
    }
    ul li a svg{
        margin-right:5px;
        color:${Colors.themeColorPrimary};
    }
    span{
        margin-bottom:0;
        font-size:13px;
        color: ${Colors.accordionHeaderIconColor};
    }
    .item-selected{
        background-color:${Colors.themeColorPrimary + "11"};
        span{
            color: ${Colors.blackColor};
            font-weight: 500;
        }
    }
`;
