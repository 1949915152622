import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import { useAuth } from "../../hooks/useAuth";
import { GET_INVENTORY, DELETE_PRODUCT_TYPE, UPDATE_PRODUCT_TYPE_STATUS } from "../../utils/apiEndPoints";
import {
    UPDATE_AVAILABLE, UPDATE_UNAVAILABLE, RESULT_SIZE_ARRAY,
    INVENTORY_LOG
} from "../../utils/constants";
import {
    callAuthApi, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import UpdateProductInventory from "./updateProductInventory";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderWrapper, ListingHeaderLayout,
    ListingHeaderLeftLayout, SearchBox
} from '../../components/styles/listingHeaderStyles';
import { BsSearch } from "react-icons/bs";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import Loader from "../../components/randomComponents/loader";
import NoResults from "../../components/randomComponents/noResults";
import NoRecords from "../../components/randomComponents/noRecords";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import StatusSpan from "../../components/randomComponents/statusSpan";
import ProductInventoryLog from "./productInventoryLog";

const InventoryList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [inventoryState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "inventory", heading: "Inventory" })
        getInventoryList();
    }, []);

    useEffect(() => {
        if (inventoryState.refreshFlag) {
            getInventoryList();
        }

    }, [inventoryState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productTypeId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productTypeId, "Active");
                break;
            case "Delete":
                await deleteType(selectedItem.productTypeId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showupdateQtyDialog = () => {
        dispatch({
            type: "SHOW_CREATE_TYPE_DIALOG"
        });
    }

    const onClickTypeDialogCancel = () => {
        dispatch({
            type: "RESET_UPDATE_QTY_DIALOG"
        });
    }

    const onClickTypeDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_UPDATE_QTY_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Update Available":
                dispatch({
                    type: "SET_UPDATE_QTY_DIALOG",
                    updateQtyDialog: {
                        showDialog: true,
                        isUnavailableDialog: false,
                        productQuantity: item.totalProductOptionAvailableQty,
                        productOptionValueMapId: item.productOptionValueMapId
                    }
                });
                break;
            case "Update Unavailable":
                dispatch({
                    type: "SET_UPDATE_QTY_DIALOG",
                    updateQtyDialog: {
                        showDialog: true,
                        isUnavailableDialog: true,
                        productQuantity: item.totalProductOptionUnavailableQty,
                        productOptionValueMapId: item.productOptionValueMapId
                    }
                });
                break;

            case "Inventory Log":
                dispatch({
                    type: "SHOW_INVENTORY_LOG",
                    productOptionValueMap: item,
                });
                break;
            }
    }

    const hideInventoryLog = () => {
        dispatch({
            type: "HIDE_INVENTORY_LOG",
        });
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (inventoryState.search != inventoryState.searchApiKey) {
            dispatch({ type: "RELOAD_TYPE_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, inventoryState.sort, inventoryState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getInventoryList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: inventoryState.search,
            sort: inventoryState.sort,
            sortDir: inventoryState.sortDir,
            resultSize: inventoryState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? inventoryState.pageNo : pageNo
        }

        if (inventoryState.status !== "All") {
            post.status = inventoryState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_INVENTORY, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productTypeId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_TYPE_STATUS, {
                productTypeId: productTypeId,
                productTypeStatus: status
            }, user, logout);

            getInventoryList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteType = async (productTypeId) => {
        try {
            await callAuthApi(DELETE_PRODUCT_TYPE, {
                productTypeId: productTypeId,
            }, user, logout);

            getInventoryList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = () => {
        return [UPDATE_AVAILABLE, UPDATE_UNAVAILABLE, INVENTORY_LOG];
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }

    const getVariantInfo = ({ productOption1, productOptionValue1, productOption2, productOptionValue2 }) => {
        let str = "";

        if (!isEmptyVariable(productOption1)) {
            str = productOption1 + ": " + productOptionValue1
        }
        if (!isEmptyVariable(productOption2)) {
            str = productOption2 + ": " + productOptionValue2
        }

        return str;
    }
    return <ContentSection>
        {
            inventoryState.isPageLoading &&
            <Loader />
        }
        {
            !inventoryState.showInventoryLog &&
            <>
                <ListingHeaderWrapper>
                    <ListingHeaderLayout>
                        <ListingHeaderLeftLayout>
                            <ResultSizeDropdown
                                dropdownLabel={inventoryState.resultSize}
                                dropdownList={RESULT_SIZE_ARRAY}
                                onClickResultSize={onClickResultSize}
                            />

                            <SearchBox>
                                <input type="text" placeholder="Search product..."
                                    value={inventoryState.search}
                                    name="search"
                                    onChange={onSearchInputChange}
                                    onBlur={searchList}
                                    onKeyDown={
                                        (e) => {
                                            if (e.key === 'Enter') searchList();
                                        }
                                    }
                                />
                                <button><BsSearch /></button>
                            </SearchBox>

                            <FilterDropdown
                                laceholder={"Select Status"}
                                selectedValue={inventoryState.status}
                                label="status"
                                options={[{ status: "All" }, { status: "Active" }, { status: "Draft" }, { status: "Inactive" }]}
                                dropdownItemClick={statusDropdownClick}
                            />
                        </ListingHeaderLeftLayout>
                    </ListingHeaderLayout>
                </ListingHeaderWrapper>
                {
                    inventoryState.firstLoad &&
                    <>
                        {
                            !isEmptyArray(inventoryState.response.result) &&
                            <ContentTableWrap>
                                <table>
                                    <tr>
                                        
                                        <SortableTableHeader
                                            tableHeader={"Product"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"p.productName"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Product SKU"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pov.productSKU"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Price"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pov.productPrice"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Discounted Price"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pov.productDiscountedPrice"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Unavailable"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pquav.totalProductOptionQuantity"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Committed"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pqav.finalCommittedQuantity"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"Available"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"pqav.totalProductOptionQuantity"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <SortableTableHeader
                                            tableHeader={"On hand"}
                                            sort={inventoryState.sort}
                                            sortDir={inventoryState.sortDir}
                                            tableSortCol={"totalQty"}
                                            sortTableLocal={sortTableLocal}
                                        />
                                        <th width="60px"></th>
                                    </tr>

                                    {
                                        inventoryState.response.result.map((item, idx) => {
                                            return <tr>
                                                <td>
                                                    {item.productName}
                                                    {
                                                        item.hasOptions === "Y" &&
                                                        <VariantSpan>
                                                            {getVariantInfo(item)}
                                                        </VariantSpan>
                                                    }
                                                    {/* <StatusSpan
                                                        status={item.productStatus}
                                                    /> */}
                                                </td>
                                                <td>{item.productSKU}</td>
                                                <td>{item.productPrice}</td>
                                                <td>{item.productDiscountedPrice}</td>
                                                <td>{item.totalProductOptionUnavailableQty}</td>
                                                <td>{item.finalCommittedQuantity}</td>
                                                <td>{item.totalProductOptionAvailableQty}</td>
                                                <td>{item.totalQty}</td>
                                                <td><TableDropdown
                                                    actionArr={getActionArr(item.productTypeStatus)}
                                                    onClickTableActionItem={onClickTableActionItem}
                                                    item={item}
                                                    minWidth={"180px"}
                                                /></td>
                                            </tr>
                                        })
                                    }
                                </table>
                                <Pagination
                                    breakLabel="..."
                                    onPageChange={(e) => getInventoryList(e.selected + 1)}
                                    pageRangeDisplayed={inventoryState.resultSize}
                                    pageCount={getPageCount(inventoryState.response.count, inventoryState.resultSize)}
                                    renderOnZeroPageCount={null}
                                    forcePage={inventoryState.pageNo - 1}
                                    totalRowCount = {inventoryState.response.count}
                                />
                            </ContentTableWrap>
                        }
                        {
                            // if result is empty after calling the api
                            isEmptyArray(inventoryState.response.result) &&
                            !inventoryState.isPageLoading &&
                            !isEmptyVariable(inventoryState.searchApiKey) &&
                            <NoResults />
                        }

                        {
                            // if result is empty after calling the api and search string is not empty
                            isEmptyArray(inventoryState.response.result) &&
                            !inventoryState.isPageLoading &&
                            isEmptyVariable(inventoryState.searchApiKey) &&
                            <NoRecords />
                        }
                    </>
                }

                <AlertDialog
                    alertState={{ ...inventoryState.alertDialog }}
                    onClickCancel={onClickAlertCancel}
                    onClickSubmit={onClickAlertSubmit}
                />

                {
                    inventoryState.updateQtyDialog?.showDialog &&
                    <UpdateProductInventory
                        updateInventoryQtyDialog={{
                            ...inventoryState.updateQtyDialog,
                        }}
                        onClickCancel={onClickTypeDialogCancel}
                        onClickSubmit={onClickTypeDialogSubmit}
                    />
                }
            </>
        }

        {
            inventoryState.showInventoryLog &&
            <ProductInventoryLog
                productOptionValueMap = {inventoryState.selectedProductOptionValueMap}
                hideInventoryLog = {hideInventoryLog}
            />
        }
    </ContentSection>
}

export default InventoryList;


//STYLES
const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;

const VariantSpan = styled.span`
    display:block;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: bold;
    color: ${Colors.themeColorPrimary+"aa"};
`;