import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { BiFilterAlt } from "react-icons/bi";
import styled from "styled-components";
import { Colors } from '../styles/colors';
import { isEmptyArray, isEmptyVariable } from "../../utils/utilFunctions";

const FilterDropdownWrapper = styled.div`
    height:30px;
    margin-left:10px;
    svg{
        width:16px;
        color:${Colors.paragraphColor};
    }
    span{
        font-size:12px;
        margin-left:5px;
        color:${Colors.paragraphColor};
        margin-bottom:0;
    }

    .dropdown .dropdown-toggle.btn{
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background:${Colors.dropdownBtnBg};
        border:none;
        padding:3px 10px;
        font-size:13px;
        color:${Colors.dropdownBtnTextColor};
        border:none;
        height:30px;
        border-radius:4px;
        border:1px solid ${Colors.dropdownBtnBorderColor};
    }
    .dropdown .dropdown-toggle.btn.show{
        background:${Colors.dropdownBtnBgHover};
    }
    .dropdown .dropdown-toggle.btn:hover{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .dropdown-toggle::after{
        content:none;
    }
    .dropdown-menu.show{
        padding:0;
        border-radius:5px;
        border:1px solid ${Colors.dropdownBorderColor};
        box-shadow:${Colors.dropdownBoxShadowColor};
        min-width: 80px;
        overflow: hidden;
        width:100%;
    }
    .dropdown-item{
        padding:10px 20px;
        background:${Colors.dropdownItemBg};
        font-size:13px;
        display:flex;
        align-items:center;
        color:${Colors.dropdownItemTextColor};

        &:first-child{
            border-top-left-radius:5px;
            border-top-right-radius:5px;
        }
        &:last-child{
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
        }

        svg{
            width:20px;
            margin-right:5px;
            color:${Colors.paragraphColor};
        }
    }
    .dropdown-item:hover{
        background:${Colors.dropdownItemBgHover};
    }
`;

const FilterDropdown = (props) => {
    const { placeholder, selectedValue, label, options, dropdownItemClick } = props;
    return (<FilterDropdownWrapper>
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
                <BiFilterAlt />
                <span>{isEmptyVariable(selectedValue) ? placeholder : selectedValue}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    !isEmptyArray(options) &&
                    options.map((option, idx) => (
                        <Dropdown.Item key={"filter" + idx}
                            as="button" onClick={() => { dropdownItemClick(option) }}>
                            {option[label]}
                        </Dropdown.Item>
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    </FilterDropdownWrapper>
    );
}

export default FilterDropdown;