import {useReducer} from 'react';
import {DEFAULT_RESULT_SIZE, INITIAL_ALERT_STATE} from '../../utils/constants'

const INITIAL_DIALOG_STATE = {
    showAddEditComp: false,
    isProductEdit: false,
    groupObj: {}
}

const INITIAL_STATE = {
    isPageLoading: false,
    firstLoad:false,
    search: "",
    searchApiKey:"",
    status:"All",
    sort: "",
    sortDir: "",
    resultSize: DEFAULT_RESULT_SIZE,
    pageNo: 1,
    response: {}
}

const initialState = {
    ...INITIAL_STATE,

    isPageLoading:false,
    refreshFlag:false,

    //DIALOG
    addEditComp:{...INITIAL_DIALOG_STATE},

    //ALERT STATE
    alertDialog:{...INITIAL_ALERT_STATE}
}

const reducer = (state,action) => {
    switch(action.type){
        case "RESET_ALERT" : {
            return {
                ...state,
                alertDialog:{...INITIAL_ALERT_STATE}
            }
        }
        case "SET_ALERT" : {
            return {
                ...state,
                alertDialog:{...INITIAL_ALERT_STATE},
                alertDialog:{...action.alertState},
            }
        }
        case "RESET_DIALOG" : {
            return {
                ...state,
                addEditComp:{...INITIAL_DIALOG_STATE},
                refreshFlag:action.refreshFlag?true:false
            }
        }
        case "SET_DIALOG" : {
            return {
                ...state,
                addEditComp:{...action.addEditComp},
            }
        }
        case "SHOW_CREATE_DIALOG" : {
            return {
                ...state,
                addEditComp:{
                    ...INITIAL_DIALOG_STATE,
                    showAddEditComp: true,
                }
            }
        }
        case "SET_RESPONSE" : {
            return {
                ...state,
                firstLoad:true,
                alertDialog:{...INITIAL_ALERT_STATE},
                refreshFlag:false, // Refresh is done so set it to false
                isPageLoading: false,
                response: action.response,
            }
        }
        case "SET_RESPONSE_AND_PAGE" : {
            return {
                ...state,
                alertDialog:{...INITIAL_ALERT_STATE},
                refreshFlag:false, // Refresh is done so set it to false
                isPageLoading: false,
                pageNo: action.pageNo,
                response: action.response,
            }
        }
        case "SHOW_PAGE_LOADER" : {
            return {
                ...state,
                isPageLoading: true,
            }
        }
        case "HIDE_PAGE_LOADER" : {
            return {
                ...state,
                isPageLoading: false,
            }
        }
        case "CHANGE_RESULT_SIZE" : {
            return {
                ...state,
                resultSize: action.resultSize,
                refreshFlag: true, // Whenever the result size is changed we need to refresh the list
            }
        }
        case "SEARCH_INPUT_CHANGE" : {
            return {
                ...state,
                search: action.value,
            }
        }
        case "SET_SORT_AND_DIR" : {
            return {
                ...state,
                sort: action.sort,
                sortDir: action.sortDir,
                refreshFlag: true,
            }
        }
        case "RELOAD_LIST": {
            return {
                ...state,
                pageNo: 1,
                searchApiKey:state.search,
                refreshFlag:true,
            }
        }
        case "SET_STATUS_FILTER":{
            return {
                ...state,
                pageNo: 1,
                status:action.status,
                refreshFlag:true,
            }
        }
        case "SET_IMAGE":{
            return {
                ...state,
                pageNo: 1,
                status:action.status,
                refreshFlag:true,
            }
        }
        default:{
            return {
                ...state
            }
        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};