import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { ADD_PRODUCT_GROUP, ATTACHMENT_PATH, GET_PRODUCT_GROUP_DETAILS, UPDATE_PRODUCT_GROUP } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyObject, isEmptyVariable } from '../../utils/utilFunctions';
import { ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper, BackButton } from '../../components/styles/listingHeaderStyles';
import RichTextEditorField from '../../components/input/richTextEditor';
import styled from 'styled-components';
import { FormWrapper, FieldGroupWrapper, FieldGroupAddImageWrapper, ButtonSecondary } from '../../components/styles/addEditFormStyles';
import ImageLibrary from '../../components/imageLibraryDialog';
import { FaArrowLeftLong } from "react-icons/fa6";
import AddImageButton from '../../components/randomComponents/addImageButton';
import DisplayImageLayoutBtn from '../../components/randomComponents/displayImageLayoutBtn';
import ProductListDialog from '../../components/productListDialog/productListDialog';

const initialState = {
    productGroupName: "",
    productGroupDesc: "",
    productGroupStatus: "",
    seoTitle: "",
    seoDescription: "",
    urlHandle: "",
    imageAttachmentObj: {

    },
    bannerImageAttachmentObj: {

    },

    showAddEditProductsflag: false,
    discountProducts: {},

    errors: {
        productGroupName: "",
        productGroupDesc: "",
        productGroupStatus: "",
        seoTitle: "",
        seoDescription: "",
        urlHandle: "",
    },
    apiErr: "",
}

const CreateUpdateGroup = (props) => {
    const { addEditGroupComp, onClickSubmit, onClickCancel } = props;

    const [componentState, setComponentState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditGroupComp.showAddEditComp) {
            if (addEditGroupComp.isEdit) {
                getProductGroupDetails();
            } else {
                setComponentState(initialState);
            }
            inputRef.current.focus();
        }
    }, [addEditGroupComp.showAddEditComp])

    const getProductGroupDetails = async () => {
        try {
            let response = await callAuthApi(GET_PRODUCT_GROUP_DETAILS, {
                productGroupId: addEditGroupComp.groupObj.productGroupId
            }, user, logout);

            const { data } = response;
            let discountProducts = {};
            if (!isEmptyArray(data.products)) {
                data.products.forEach(item => {
                    discountProducts[item.productUniqueId] = {
                        productUniqueId: item.productUniqueId,
                        productName: item.productName
                    }
                })
            }

            setComponentState({
                ...initialState,

                productGroupName: data.productGroupName,
                productGroupDesc: data.productGroupDesc,
                productGroupStatus: data.productGroupStatus,
                seoTitle: data.seoTitle,
                seoDescription: data.seoDescription,
                urlHandle: data.urlHandle,
                imageAttachmentObj: {
                    productAttachmentId: data.productAttachmentId,
                    productAttachmentPath: data.productAttachmentPath,
                },
                bannerImageAttachmentObj: {
                    productAttachmentId: data.bannerImageAttachmentId,
                    productAttachmentPath: data.bannerImageAttachmentPath,
                },

                discountProducts: discountProducts,
            })

        } catch (alertErr) {
            setComponentState({
                ...componentState,
                apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            })
        }
    }

    const inputChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.value
        })
    }

    const handleUrlHandle = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, '-');
        setComponentState({
            ...componentState,
            [e.target.name]: newValue.toLowerCase()
        })
    }

    const editorOnChange = (value) => {
        setComponentState({
            ...componentState,
            productGroupDesc: value
        })
    }

    const productGroupStatusDropdownItemClick = (option) => {
        setComponentState({
            ...componentState,
            productGroupStatus: option.productGroupStatus
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.productGroupName)) {
            hasErrors = true;
            errors.productGroupName = "Please enter the collection name"
        }

        if (isEmptyVariable(componentState.productGroupDesc)) {
            hasErrors = true;
            errors.productGroupDesc = "Please enter the description"
        }

        if (isEmptyVariable(componentState.seoTitle)) {
            hasErrors = true;
            errors.seoTitle = "Please enter the SEO title"
        }

        if (isEmptyVariable(componentState.seoDescription)) {
            hasErrors = true;
            errors.seoDescription = "Please enter the SEO description"
        }

        if (isEmptyVariable(componentState.urlHandle)) {
            hasErrors = true;
            errors.urlHandle = "Please enter the URL handle"
        }

        if (isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)) {
            hasErrors = true;
            errors.productAttachmentId = "Please select an image"
        }

        setComponentState({
            ...componentState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const showImageLibrary = (imageSourceType) => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: true,
            imageSourceType: isEmptyVariable(imageSourceType) ? "Thumbnail" : imageSourceType
        })
    }

    const onClickImageLibraryDialogCancel = () => {
        setComponentState({
            ...componentState,
            showImageLibraryDialog: false
        })
    }

    const onClickImageLibraryDialogSubmit = (selectedImages) => {
        if (!isEmptyArray(selectedImages)) {
            if (componentState.imageSourceType === "Banner") {
                setComponentState({
                    ...componentState,
                    bannerImageAttachmentObj: selectedImages[0],
                    showImageLibraryDialog: false
                })
            } else {
                setComponentState({
                    ...componentState,
                    imageAttachmentObj: selectedImages[0],
                    showImageLibraryDialog: false
                })
            }
        }
    }

    const deleteImage = (imageSourceType) => {
        if (imageSourceType === "Banner") {
            setComponentState({
                ...componentState,
                bannerImageAttachmentObj: {},
            })
        } else if (imageSourceType === "Thumbnail") {
            setComponentState({
                ...componentState,
                imageAttachmentObj: {},
            })
        }
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditGroupComp.isEdit) {
                    await updateProductGroup();
                } else {
                    await createNewProductGroup();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setComponentState({
                    ...componentState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    setComponentState({
                        ...componentState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setComponentState({
                    ...componentState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    const onClickAddEditProductsSubmit = (discountProducts) => {
        setComponentState({
            ...componentState,
            showAddEditProductsflag: false,
            discountProducts: {
                ...discountProducts
            }
        })
    }

    const onClickAddEditProductsCancel = () => {
        setComponentState({
            ...componentState,
            showAddEditProductsflag: false,
        })
    }

    const displayAddEditProducts = () => {
        setComponentState({
            ...componentState,
            showAddEditProductsflag: true,
        })
    }

    //API Calls
    const createNewProductGroup = async () => {
        await callAuthApi(ADD_PRODUCT_GROUP, {
            productGroupName: componentState.productGroupName,
            productGroupDesc: componentState.productGroupDesc,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            bannerImageAttachmentId: componentState.bannerImageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            productUniqueIds: isEmptyObject(componentState.discountProducts) ? JSON.stringify([]) : JSON.stringify(Object.keys(componentState.discountProducts))
        }, user, logout)
    }

    const updateProductGroup = async () => {
        await callAuthApi(UPDATE_PRODUCT_GROUP, {
            productGroupId: addEditGroupComp.groupObj.productGroupId,
            productGroupName: componentState.productGroupName,
            productGroupDesc: componentState.productGroupDesc,
            productAttachmentId: componentState.imageAttachmentObj.productAttachmentId,
            bannerImageAttachmentId: componentState.bannerImageAttachmentObj.productAttachmentId,
            seoTitle: componentState.seoTitle,
            seoDescription: componentState.seoDescription,
            urlHandle: componentState.urlHandle,
            productUniqueIds: isEmptyObject(componentState.discountProducts) ? JSON.stringify([]) : JSON.stringify(Object.keys(componentState.discountProducts))
        }, user, logout);
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{addEditGroupComp.isEdit ? "Edit Collection" : "Add Collection"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={8}>
                    <FieldGroupWrapper>
                        <label>Collection Name*</label>
                        <input
                            value={componentState.productGroupName}
                            name="productGroupName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productGroupName) &&
                            <span className="err-span m-b-10">{componentState.errors.productGroupName}</span>
                        }
                        <label>Description*</label>
                        <RichTextEditorField
                            value={componentState.productGroupDesc}
                            editorOnChange={editorOnChange}
                        />
                        {
                            !isEmptyVariable(componentState.errors?.productGroupDesc) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productGroupDesc}</span>
                        }

                        <label>&nbsp;</label>
                        <FlexLayout>
                            <ButtonSecondary onClick={displayAddEditProducts}>
                                Browse Products
                            </ButtonSecondary>
                            {
                                !isEmptyObject(componentState.discountProducts) &&
                                <p>
                                    {
                                        Object.keys(componentState.discountProducts).length === 1
                                            ?
                                            "1 Product Selected"
                                            :
                                            Object.keys(componentState.discountProducts).length + " Products Selected"
                                    }
                                </p>
                            }
                        </FlexLayout>
                        {
                            !isEmptyVariable(componentState.errors?.discountProducts) &&
                            <span className="err-span m-t-10 m-b-0">{componentState.errors.discountProducts}</span>
                        }
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Search Engine</h6>
                        <label>SEO Title*</label>
                        <input
                            value={componentState.seoTitle}
                            name="seoTitle"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoTitle.length > 60 ? "red" : "#555555" }}>
                            {componentState.seoTitle.length + " of 60 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoTitle) &&
                            <span className="err-span m-b-10">{componentState.errors.seoTitle}</span>
                        }

                        <label>SEO Description*</label>
                        <textarea
                            value={componentState.seoDescription}
                            name="seoDescription"
                            onChange={inputChange}
                            className="m-b-0"
                        />
                        <span className='help-span m-b-20 m-t-5'
                            style={{ color: componentState.seoDescription.length > 320 ? "red" : "#555555" }}>
                            {componentState.seoDescription.length + " of 320 characters used"}
                        </span>
                        {
                            !isEmptyVariable(componentState.errors?.seoDescription) &&
                            <span className="err-span m-b-10">{componentState.errors.seoDescription}</span>
                        }

                        <label>URL Handle*</label>
                        <input
                            value={componentState.urlHandle}
                            name="urlHandle"
                            onChange={handleUrlHandle}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.urlHandle) &&
                            <span className="err-span m-b-10">{componentState.errors.urlHandle}</span>
                        }

                    </FieldGroupWrapper>
                </Col>

                <Col md={4}>
                    <FieldGroupAddImageWrapper>
                        <h6>Banner Image*</h6>
                        {
                            isEmptyVariable(componentState.bannerImageAttachmentObj?.productAttachmentId)
                                ?
                                <AddImageButton
                                    text="Add Image"
                                    showImageLibrary={() => showImageLibrary("Banner")}
                                />
                                :
                                <DisplayImageLayoutBtn
                                    showImageLibrary={() => showImageLibrary("Banner")}
                                    deleteImage={() => deleteImage("Banner")}
                                    imageSrc={ATTACHMENT_PATH + componentState.bannerImageAttachmentObj.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                />
                        }
                        {
                            !isEmptyVariable(componentState.errors?.bannerProductAttachmentId) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.bannerProductAttachmentId}</span>
                        }
                    </FieldGroupAddImageWrapper>

                    <FieldGroupAddImageWrapper>
                        <h6>Thumbnail Image*</h6>
                        {
                            isEmptyVariable(componentState.imageAttachmentObj?.productAttachmentId)
                                ?
                                <AddImageButton
                                    text="Add Image"
                                    showImageLibrary={() => showImageLibrary("Thumbnail")}
                                />
                                :
                                <DisplayImageLayoutBtn
                                    showImageLibrary={() => showImageLibrary("Thumbnail")}
                                    deleteImage={() => deleteImage("Thumbnail")}
                                    imageSrc={ATTACHMENT_PATH + componentState.imageAttachmentObj.productAttachmentPath.replace("/images/", "/thumbnails/")}
                                />
                        }
                        {
                            !isEmptyVariable(componentState.errors?.productAttachmentId) &&
                            <span className="err-span m-t-10 m-b-10">{componentState.errors.productAttachmentId}</span>
                        }
                    </FieldGroupAddImageWrapper>
                </Col>
            </Row>
        </FormWrapper>

        <ImageLibrary
            showDialog={componentState.showImageLibraryDialog}
            onClickSubmit={onClickImageLibraryDialogSubmit}
            onClickCancel={onClickImageLibraryDialogCancel}
        />

        {
            componentState.showAddEditProductsflag &&
            <ProductListDialog
                showAddEditProductsflag={componentState.showAddEditProductsflag}
                onClickSubmit={onClickAddEditProductsSubmit}
                onClickCancel={onClickAddEditProductsCancel}
                discountProducts={componentState.discountProducts}
            />
        }
    </>
}

export default CreateUpdateGroup;

const FlexLayout = styled.div`
    display:flex;
    align-items: center;

    p{
        margin-bottom: 0;
        margin-left: 10px;
    }
`;