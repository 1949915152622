import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_CUSTOMER_APIS, DELETE_CUSTOMER_API, UPDATE_CUSTOMER_API_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateCustomerApi from "./createUpdateCustomerApi";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import CustomerApiList from "./customerApiListing";


const ApiComposer = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [customerApiState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "customerapis", heading: "Customer APIs" })
        getCustomerApiList();
    }, []);

    useEffect(() => {
        if (customerApiState.refreshFlag) {
            getCustomerApiList();
        }

    }, [customerApiState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.customerApiId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.customerApiId, "Active");
                break;
            case "Delete":
                await deleteCustomerApi(selectedItem.customerApiId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateApiComp = () => {
        dispatch({
            type: "SHOW_CREATE_CUSTOMER_API_COMP"
        });
    }

    const onClickCustomerApiCompCancel = () => {
        dispatch({
            type: "RESET_CUSTOMER_API_COMP"
        });
    }

    const onClickCustomerApiCompSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_CUSTOMER_API_COMP",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_CUSTOMER_API_COMP",
                    addEditCustomerApiComp: {
                        showAddEditCustomerApiComp: true,
                        isEdit: true,
                        apiObj: {
                            customerApiId: item.customerApiId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Api",
                        message: "Are you sure you want to deactivate " + item.customerApiName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Api",
                        message: "Are you sure you want to activate " + item.customerApiName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Api",
                        message: "Are you sure you want to delete " + item.customerApiName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (customerApiState.search != customerApiState.searchApiKey) {
            dispatch({ type: "RELOAD_CUSTOMER_API_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, customerApiState.sort, customerApiState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getCustomerApiList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: customerApiState.search,
            sort: customerApiState.sort,
            sortDir: customerApiState.sortDir,
            resultSize: customerApiState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? customerApiState.pageNo : pageNo
        }

        if (customerApiState.status !== "All") {
            post.status = customerApiState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_CUSTOMER_APIS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (customerApiId, status) => {
        try {
            await callAuthApi(UPDATE_CUSTOMER_API_STATUS, {
                customerApiId: customerApiId,
                customerApiStatus: status
            }, user, logout);

            getCustomerApiList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteCustomerApi = async (customerApiId) => {
        try {
            await callAuthApi(DELETE_CUSTOMER_API, {
                customerApiId: customerApiId,
            }, user, logout);

            getCustomerApiList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            customerApiState.isPageLoading &&
            <Loader />
        }

        {
            !customerApiState.addEditCustomerApiComp?.showAddEditCustomerApiComp &&
            <CustomerApiList
                customerApiState={customerApiState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                showCreateApiComp={showCreateApiComp}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getCustomerApiList={getCustomerApiList}
            />
        }

        {
            customerApiState.addEditCustomerApiComp?.showAddEditCustomerApiComp &&
            <CreateUpdateCustomerApi
                addEditCustomerApiComp={{
                    ...customerApiState.addEditCustomerApiComp
                }}
                onClickSubmit={onClickCustomerApiCompSubmit}
                onClickCancel={onClickCustomerApiCompCancel}
            />
        }

        <AlertDialog
            alertState={{ ...customerApiState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default ApiComposer;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;