import CreatableSelect from 'react-select/creatable';
import { ifEmptyReturnStr } from '../../utils/utilFunctions';

const ReactCreatableSelect = (props) => {
    const { isDisabled, isLoading, isClearable, isSearchable, closeMenuOnSelect, reactSelectName, options,
        isMulti, placeholder, labelField, valueField, onChange, value } = props;
    return <>
        <CreatableSelect
            className="react-creatable-select"
            classNamePrefix="rSelect"

            isDisabled={ifEmptyReturnStr(isDisabled, false)}
            isLoading={ifEmptyReturnStr(isLoading, false)}
            isClearable={ifEmptyReturnStr(isClearable, false)}
            isSearchable={ifEmptyReturnStr(isSearchable, false)}

            closeMenuOnSelect={closeMenuOnSelect?closeMenuOnSelect:true}
            name={reactSelectName}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            getOptionLabel={optionObj => ifEmptyReturnStr(optionObj[labelField], optionObj["label"])}
            getOptionValue={optionObj => optionObj[valueField]}
            onChange={onChange}
            value={value}
        />
    </>
}


export default ReactCreatableSelect;