import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';

const RadioButtonLayout = styled.div`
    display:flex;
    align-items:center;

    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.whiteColor};
        margin: 0;
        width: ${props => props.backgroundWidth ? props.backgroundWidth : "20px"};
        height: ${props => props.backgroundHeight ? props.backgroundHeight : "20px"};
        padding:0;
        border: 1px solid ${Colors.inputBorderColor};
        border-radius: 100%;
        display: grid;
        place-content: center;
        cursor:pointer;
    }

    input[type="radio"]:disabled{
        background:${Colors.inputDisabledBgColor};
    }
    
    input[type="radio"]::before {
        content: "";
        width: ${props => props.dotWidth ? props.dotWidth : "10px"};
        height: ${props => props.dotHeight ? props.dotHeight : "10px"};
        border-radius: 100%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px ${props => props.dotColor ? props.dotColor : Colors.themeColorPrimary};
        cursor:pointer;
    }
    
    input[type="radio"]:checked::before {
        transform: scale(1);
    }
    
    input[type="radio"]:focus {
        /* outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);*/
    }

    label{
        margin-left:5px;
        font-size:14px;
        cursor:pointer;
    }
    input,label{
        margin-bottom:5px !important;
        cursor:pointer;
    }
`;


const RadioButton = (props) => {
    const { backgroundWidth, backgroundHeight, backgroundColor,
        dotWidth, dotHeight, dotColor, radioButtonId, radioButtonLabel,
        handleChange, checked, name, disabled } = props;

    return <RadioButtonLayout
        backgroundHeight={backgroundHeight}
        backgroundWidth={backgroundWidth}
        backgroundColor={backgroundColor}
        dotHeight={dotHeight}
        dotWidth={dotWidth}
        dotColor={dotColor}
    >
        <input name={name} id={radioButtonId} onChange={handleChange}
            checked={checked} type="radio" disabled={disabled} />
        <label for={radioButtonId}>{radioButtonLabel}</label>
    </RadioButtonLayout>
}

export default RadioButton;