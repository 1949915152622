import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { CREATE_CUSTOMER_API, GET_API_MODULES, UPDATE_CUSTOMER_API } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT, INITIAL_ALERT_STATE } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import {
    ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper,
    BackButton
} from '../../components/styles/listingHeaderStyles';
import { FaTrashCan } from "react-icons/fa6";
import { FormWrapper, FieldGroupWrapper, ButtonIconLayout, ButtonSecondary } from '../../components/styles/addEditFormStyles';
import { FaArrowLeftLong } from "react-icons/fa6";
import styled from 'styled-components';
import AlertDialog from '../../components/dialogs/alertDialog';

const initialModuleState = {
    apiModuleName: "",
    apiResponsePropertyName: "",
    sort: "",
    sortDir: "",
    resultSize: "",
    pageNo: "",
    search: "",
    moduleId: "",
    status: "",
    apiModuleNameErr: "",
    apiResponsePropertyNameErr: "",
}
const initialState = {
    customerApiName: "",
    apiModules: [],
    modules: [
        {
            ...initialModuleState
        }
    ],
    errors: {
        customerApiName: "",
    },
    apiErr: "",

    alertDialog: { ...INITIAL_ALERT_STATE }
}

const CreateUpdateCustomerApi = (props) => {
    const { addEditCustomerApiComp, onClickSubmit, onClickCancel } = props;

    const [componentState, setComponentState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (addEditCustomerApiComp.showAddEditCustomerApiComp) {
            getApiModules();
        }
    }, [addEditCustomerApiComp.showAddEditCustomerApiComp]);

    const getApiModules = async () => {
        try {
            let post = {};
            if (addEditCustomerApiComp.apiObj?.customerApiId) {
                post.customerApiId = addEditCustomerApiComp.apiObj.customerApiId
            }
            let response = await callAuthApi(GET_API_MODULES, post, user, logout);

            if (addEditCustomerApiComp.isEdit) {
                setComponentState({
                    ...initialState,
                    apiModules: [...response.data.apiModules],
                    customerApiName: response.data.customerApi?.customerApiName,
                    modules: response.data.customerApi?.modules,
                })
            } else {
                setComponentState({
                    ...initialState,
                    apiModules: [...response.data.apiModules],
                })
            }
            inputRef.current.focus();
        } catch (alertErr) {
            setComponentState({
                ...initialState,
                apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
            });
        }
    }

    //ALERT Dialog
    const onClickAlertCancel = () => {
        setComponentState({
            ...componentState,
            alertDialog: {
                ...INITIAL_ALERT_STATE
            }
        })
    }

    const showDeleteAlert = (module, idx) => {
        setComponentState({
            ...componentState,
            alertDialog: {
                showAlertDialog: true,
                heading: "Delete Module",
                message: "Are you sure you want to delete " + module.apiModuleName + " module?",
                type: "ALERT",
                cancelBtn: true,
                okayBtn: true,
                cancelBtnLabel: "Cancel",
                okayBtnLabel: "Delete",
                operation: "Delete",
                selectedItem: idx,
            }
        })
    }

    const onClickAlertSubmit = async (operation, idx) => {
        switch (operation) {
            case "Delete":
                deleteModule(idx)
                break;
            default:
                break;
        }
    }

    const inputChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.value
        })
    }

    const moduleFieldInputChange = (e, moduleIdx) => {
        let module = { ...componentState.modules[moduleIdx] };
        module[e.target.name] = e.target.value;
        setComponentState({
            ...componentState,
            modules: [
                ...componentState.modules.slice(0, moduleIdx),
                { ...module },
                ...componentState.modules.slice(moduleIdx + 1)
            ]
        })
    };

    const handleIntegerInputChange = (e, moduleIdx) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9]/g, '');

        let module = { ...componentState.modules[moduleIdx] };
        module[e.target.name] = newValue;
        setComponentState({
            ...componentState,
            modules: [
                ...componentState.modules.slice(0, moduleIdx),
                { ...module },
                ...componentState.modules.slice(moduleIdx + 1)
            ]
        })
    };

    const apiModuleNameDropdownItemClick = (option, moduleIdx) => {
        let module = { ...componentState.modules[moduleIdx] };
        module.apiModuleName = option.apiModuleName;
        setComponentState({
            ...componentState,
            modules: [
                ...componentState.modules.slice(0, moduleIdx),
                { ...module },
                ...componentState.modules.slice(moduleIdx + 1)
            ]
        })
    }

    const sortDirDropdownItemClick = (option, moduleIdx) => {
        let module = { ...componentState.modules[moduleIdx] };
        module.sortDir = option.sortDir;
        setComponentState({
            ...componentState,
            modules: [
                ...componentState.modules.slice(0, moduleIdx),
                { ...module },
                ...componentState.modules.slice(moduleIdx + 1)
            ]
        })
    }

    const statusDropdownItemClick = (option, moduleIdx) => {
        let module = { ...componentState.modules[moduleIdx] };
        module.status = option.status;
        setComponentState({
            ...componentState,
            modules: [
                ...componentState.modules.slice(0, moduleIdx),
                { ...module },
                ...componentState.modules.slice(moduleIdx + 1)
            ]
        })
    }

    const addNewModule = () => {
        let modules = [...componentState.modules];
        modules.push({ ...initialModuleState })
        setComponentState({
            ...componentState,
            modules: modules
        })
    }

    const deleteModule = (idx) => {
        let modules = [...componentState.modules.slice(0, idx), ...componentState.modules.slice(idx + 1)];

        setComponentState({
            ...componentState,
            modules: modules,
            alertDialog: {
                ...INITIAL_ALERT_STATE
            }
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.customerApiName)) {
            hasErrors = true;
            errors.customerApiName = "Please enter the Api name"
        }

        if (isEmptyArray(componentState.modules)) {
            hasErrors = true;
            errors.modulesErr = "Please enter atleast one module"
        }

        let newModules = [];
        if (!isEmptyArray(componentState.modules)) {
            newModules = componentState.modules.map(module => {
                if (isEmptyVariable(module.apiModuleName)) {
                    hasErrors = true
                    module.apiModuleNameErr = "Please select the module";
                }
                if (isEmptyVariable(module.apiResponsePropertyName)) {
                    hasErrors = true
                    module.apiResponsePropertyNameErr = "Please enter property name";
                }
                return module;
            })
        }

        setComponentState({
            ...componentState,
            modules: newModules,
            errors: errors
        })
        return hasErrors;
    }

    const onClickSave = async () => {
        if (!validateForm()) {
            try {
                if (addEditCustomerApiComp.isEdit) {
                    await updateCustomerApi();
                } else {
                    await createCustomerApi();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setComponentState({
                    ...componentState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })
                setTimeout(() => {
                    setComponentState({
                        ...componentState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            // setTimeout(() => {
            //     setComponentState({
            //         ...componentState,
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createCustomerApi = async () => {
        await callAuthApi(CREATE_CUSTOMER_API, {
            customerApiName: componentState.customerApiName,
            modules: JSON.stringify(componentState.modules)
        }, user, logout)
    }

    //API Calls
    const updateCustomerApi = async () => {
        await callAuthApi(UPDATE_CUSTOMER_API, {
            customerApiId: addEditCustomerApiComp.apiObj.customerApiId,
            customerApiName: componentState.customerApiName,
            modules: JSON.stringify(componentState.modules)
        }, user, logout)
    }

    return <>
        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{"Add Customer Api"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSave}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>

        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={12}>
                    <FieldGroupWrapper>
                        <label>Api Name*</label>
                        <input
                            value={componentState.customerApiName}
                            name="customerApiName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            className="m-b-20"
                        />
                        {
                            !isEmptyVariable(componentState.errors?.customerApiName) &&
                            <span className="err-span m-b-10">{componentState.errors.customerApiName}</span>
                        }
                    </FieldGroupWrapper>
                    <FieldGroupWrapper>
                        <h6>Modules</h6>
                        {
                            componentState.modules.map((module, moduleIdx) => {
                                return <><Row key={"apimodule" + moduleIdx}>
                                    <Col md={3}>
                                        <label>Module Name*</label>
                                        <BasicDropdown
                                            placeholder={"Select Module"}
                                            selectedValue={module.apiModuleName}
                                            label="apiModuleName"
                                            options={componentState.apiModules}
                                            dropdownItemClick={apiModuleNameDropdownItemClick}
                                            wrapperStyles={{ marginBottom: 15 }}
                                            udf={moduleIdx}
                                            minWidth={"100px"}
                                        />
                                        {
                                            !isEmptyVariable(module.apiModuleNameErr) &&
                                            <span className="err-span m-b-10">{module.apiModuleNameErr}</span>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        <label>Property Name</label>
                                        <input
                                            value={module.apiResponsePropertyName}
                                            name="apiResponsePropertyName"
                                            onChange={(e) => moduleFieldInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />
                                        {
                                            !isEmptyVariable(module.apiResponsePropertyNameErr) &&
                                            <span className="err-span m-b-10">{module.apiResponsePropertyNameErr}</span>
                                        }
                                    </Col>
                                    <Col md={2}>
                                        <label>Page No</label>
                                        <input
                                            value={module.pageNo}
                                            name="pageNo"
                                            onChange={(e) => handleIntegerInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <label>Result Size</label>
                                        <input
                                            value={module.resultSize}
                                            name="resultSize"
                                            onChange={(e) => handleIntegerInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <label>Sort Column</label>
                                        <input
                                            value={module.sort}
                                            name="sort"
                                            onChange={(e) => moduleFieldInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />

                                    </Col>
                                    <Col md={2}>
                                        <label>Sort Dir</label>
                                        <BasicDropdown
                                            placeholder={"Select Direction"}
                                            selectedValue={module.sortDir}
                                            label="sortDir"
                                            options={[{ sortDir: "asc" }, { sortDir: "desc" }]}
                                            dropdownItemClick={sortDirDropdownItemClick}
                                            wrapperStyles={{ marginBottom: 15 }}
                                            udf={moduleIdx}
                                            minWidth={"100px"}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>Search</label>
                                        <input
                                            value={module.search}
                                            name="search"
                                            onChange={(e) => moduleFieldInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />

                                    </Col>
                                    <Col md={2}>
                                        <label>Module Id</label>
                                        <input
                                            value={module.moduleId}
                                            name="moduleId"
                                            onChange={(e) => handleIntegerInputChange(e, moduleIdx)}
                                            className="m-b-20"
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <label>Status</label>
                                        <BasicDropdown
                                            placeholder={"Select Status"}
                                            selectedValue={module.status}
                                            label="status"
                                            options={[{ status: "Active" }, { status: "Inactive" }]}
                                            dropdownItemClick={statusDropdownItemClick}
                                            wrapperStyles={{ marginBottom: 15 }}
                                            udf={moduleIdx}
                                            minWidth={"100px"}
                                        />
                                    </Col>

                                    <Col md={2}>
                                        <FlexCenterLayout>
                                            <ButtonIconLayout onClick={() => showDeleteAlert(module, moduleIdx)}>
                                                <FaTrashCan />
                                            </ButtonIconLayout>
                                        </FlexCenterLayout>
                                    </Col>
                                </Row>
                                    <hr style={{ margin: "20px 0 35px 0" }} />
                                </>
                            })
                        }

                        <ButtonSecondary onClick={addNewModule}>Add Module</ButtonSecondary>
                    </FieldGroupWrapper>
                </Col>
            </Row>
        </FormWrapper>
        <AlertDialog
            alertState={{ ...componentState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </>
}

export default CreateUpdateCustomerApi;

const FlexCenterLayout = styled.div`
    display:flex;
    align-items:center;
    height: 100%;
`;