import styled from 'styled-components';
import { Colors } from '../styles/colors';

const CheckBoxLayout = styled.div`
    display:flex;
    align-items:center;

    label{
        font-size:12px;
        margin-left:5px;
        cursor:pointer;
        margin-bottom: 0 !important;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0 !important;
        width: ${props => props.backgroundWidth ? props.backgroundWidth : "25px"} !important;
        height: ${props => props.backgroundHeight ? props.backgroundHeight : "25px"} !important;
        padding:0;
        border: 2px solid ${Colors.inputBorderColor};
        border-radius: 5px;
        display: grid;
        place-content: center;
        background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.whiteColor};
        cursor:pointer;
    }

    input[type="checkbox"]::before {
        content: "";
        width: ${props => props.tickWidth ? props.tickWidth : "15px"};
        height: ${props => props.tickHeight ? props.tickHeight : "15px"};
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(${Colors.inputBorderColor});
        background-color: ${props => props.tickColor ? props.tickColor : Colors.themeColorPrimary};
        cursor:pointer;
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    input[type="checkbox"]:focus {
       /* outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em); */
    }

    input[type="checkbox"]:disabled {
        --form-control-color: var(--form-control-disabled);
        color: var(--form-control-disabled);
        cursor: not-allowed;
    }
`;

const Checkbox = (props) => {
    const { backgroundWidth, backgroundHeight, backgroundColor,
        tickWidth, tickHeight, tickColor, checkBoxId, checkboxLabel, 
        handleChange, checked } = props;

    return <CheckBoxLayout
        backgroundHeight={backgroundHeight}
        backgroundWidth={backgroundWidth}
        backgroundColor={backgroundColor}
        tickHeight={tickHeight}
        tickWidth={tickWidth}
        tickColor={tickColor}
    >
        <input id={checkBoxId} onChange={handleChange} checked={checked}type="checkbox" />
        <label for={checkBoxId}>{checkboxLabel}</label>
    </CheckBoxLayout>
}

export default Checkbox;