import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import AlertDialog from "../../components/dialogs/alertDialog";
import { useAuth } from "../../hooks/useAuth";
import { GET_BLOGS, DELETE_BLOG, UPDATE_BLOG_STATUS } from "../../utils/apiEndPoints";
import {
    callAuthApi, isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateBlog from "./createUpdateBlog";
import { GetReducer } from "./reducer";
import styled from "styled-components";
import { Colors } from "../../components/styles/colors";
import Loader from "../../components/randomComponents/loader";
import BlogList from "./blogListing";


const Blog = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [blogState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "blogs", heading: "Blogs" })
        getBlogList();
    }, []);

    useEffect(() => {
        if (blogState.refreshFlag) {
            getBlogList();
        }

    }, [blogState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.blogId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.blogId, "Active");
                break;
            case "Delete":
                await deleteBlog(selectedItem.blogId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateBlogDialog = () => {
        dispatch({
            type: "SHOW_CREATE_BLOG_DIALOG"
        });
    }

    const onClickBlogDialogCancel = () => {
        dispatch({
            type: "RESET_BLOG_DIALOG"
        });
    }

    const onClickBlogDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_BLOG_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_BLOG_DIALOG",
                    addEditBlogComp: {
                        showAddEditComp: true,
                        isEdit: true,
                        blogId: item.blogId,
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Blog",
                        message: "Are you sure you want to deactivate " + item.blogTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Blog",
                        message: "Are you sure you want to activate " + item.blogTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Blog",
                        message: "Are you sure you want to delete " + item.blogTitle + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (blogState.search != blogState.searchApiKey) {
            dispatch({ type: "RELOAD_BLOG_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, blogState.sort, blogState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getBlogList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: blogState.search,
            sort: blogState.sort,
            sortDir: blogState.sortDir,
            resultSize: blogState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? blogState.pageNo : pageNo
        }

        if (blogState.status !== "All") {
            post.status = blogState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_BLOGS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (blogId, status) => {
        try {
            await callAuthApi(UPDATE_BLOG_STATUS, {
                blogId: blogId,
                blogStatus: status
            }, user, logout);

            getBlogList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteBlog = async (blogId) => {
        try {
            await callAuthApi(DELETE_BLOG, {
                blogId: blogId,
            }, user, logout);

            getBlogList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {   
            blogState.isPageLoading &&
            <Loader />
        }

        {
            !blogState.addEditBlogComp?.showAddEditComp &&
            <BlogList
                blogState={blogState}
                onClickResultSize={onClickResultSize}
                onSearchInputChange={onSearchInputChange}
                searchList={searchList}
                statusDropdownClick={statusDropdownClick}
                showCreateBlogDialog={showCreateBlogDialog}
                onClickTableActionItem={onClickTableActionItem}
                sortTableLocal={sortTableLocal}
                getBlogList={getBlogList}
            />
        }

        {
            blogState.addEditBlogComp?.showAddEditComp &&
            <CreateUpdateBlog
                addEditBlogComp={{
                    ...blogState.addEditBlogComp
                }}
                onClickSubmit={onClickBlogDialogSubmit}
                onClickCancel={onClickBlogDialogCancel}
            />
        }

        <AlertDialog
            alertState={{ ...blogState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default Blog;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;