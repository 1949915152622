import { useOutletContext } from "react-router-dom";
import Loader from "../../components/randomComponents/loader";
import { useAuth } from "../../hooks/useAuth";
import { GET_DASHBOARD } from "../../utils/apiEndPoints";
import { callAuthApi, getNowUtcStartTimeWrtTimeZone, isEmptyArray, 
    isEmptyVariable, sortTable } from "../../utils/utilFunctions";
import { GetReducer } from "./reducer";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import NoResults from "../../components/randomComponents/noResults";
import NoRecords from "../../components/randomComponents/noRecords";
import AlertDialog from "../../components/dialogs/alertDialog";
import styled from "styled-components";
import { useEffect } from "react";
import { Colors } from "../../components/styles/colors";
import { Col, Row } from "react-bootstrap";

const Dashboard = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [state, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "dashboard", heading: "Dashboard" })
        getDashboard();
    }, []);

    const getDashboard = async () => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        try {
            const response = await callAuthApi(GET_DASHBOARD, {
                currentDate: getNowUtcStartTimeWrtTimeZone()
            }, user, logout);

            dispatch({
                type: "SET_RESPONSE",
                response: response.data
            })

        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, state.sort, state.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation) => {
        switch (operation) {
            // case "Delete":
                // await deleteOrder(selectedItem.productOrderId);
            //     break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    console.log(state);
    return <ContentSection>
        {
            state.isPageLoading &&
            <Loader />
        }
        {
            state.firstLoad &&
            <>
                <DashboardCardHolder>
                    <Row5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Total Orders</p>
                                <h6>{state.response.totalOrdersCount}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Total Sales</p>
                                <h6>{state.response.totalSales}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Total Customers</p>
                                <h6>{state.response.totalActiveCustomers}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Total Products</p>
                                <h6>{state.response.totalProductCount}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Orders Today</p>
                                <h6>{state.response.totalOrdersPlacedToday}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Orders Yesterday</p>
                                <h6>{state.response.totalOrdersPlacedYesterday}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Orders in Last 7 Days</p>
                                <h6>{state.response.totalOrdersPlacedThisWeek}</h6>
                            </DashboardCard>
                        </Col5>
                        <Col5 md="3">
                            <DashboardCard>
                                <p>Orders in Last 30 Days</p>
                                <h6>{state.response.totalOrdersPlacedThisMonth}</h6>
                            </DashboardCard>
                        </Col5>
                    </Row5>
                </DashboardCardHolder>

                {
                    !isEmptyArray(state.response.orders) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                <th colSpan={6}>
                                    <TableHeaderLayout>
                                        Orders
                                        <a href="/orders">View Orders</a>
                                    </TableHeaderLayout>
                                </th>
                            </tr>
                            <tr>
                                <SortableTableHeader
                                    tableHeader={"Order Id"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"o.orderNumber"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Customer"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"fullName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Total"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"o.grandTotal"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Items"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"oicv.orderItemCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Payment"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"o.paymentMode"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Status"}
                                    sort={state.sort}
                                    sortDir={state.sortDir}
                                    tableSortCol={"o.status"}
                                    sortTableLocal={sortTableLocal}
                                />
                            </tr>

                            {
                                state.response.orders.map((item, idx) => {
                                    return <tr key={"ordertable" + idx}>
                                        <td>
                                            <OrderLink
                                                href = {"/orderdetails/"+item.orderNumber}>
                                                {item.orderNumber}
                                            </OrderLink>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>{item.grandTotal.toFixed(2)}</td>
                                        <td>{item.orderItemCount}</td>
                                        <td>
                                            <PaymentSpan text={item.paymentMode}>{item.paymentMode}</PaymentSpan>
                                        </td>
                                        {/* TODO - show different colors to different status  */}
                                        <td>{item.status}</td>
                                    </tr>
                                })
                            }
                        </table>
                        {/* <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getOrderList(e.selected + 1)}
                            pageRangeDisplayed={state.resultSize}
                            pageCount={getPageCount(state.response.count, state.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={state.pageNo - 1}
                            totalRowCount={state.response.count}
                        /> */}
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(state.response.orders) &&
                    !state.isPageLoading &&
                    !isEmptyVariable(state.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(state.response.orders) &&
                    !state.isPageLoading &&
                    isEmptyVariable(state.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...state.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
    </ContentSection>
}

export default Dashboard;


//STYLES
const ContentSection = styled.div`
    border-top: 1px solid ${Colors.inputBorderColor};
    background:${Colors.MainContentBgColor};
`;

const ContentTableWrap = styled.div`
    width:100%;
    padding:10px 20px 20px;
`;

const PaymentSpan = styled.span`
    display:inline-block;
    margin:0;
    font-size:11px;
    background: ${props => props.text === 'Online Payment'?"#d8d8d8":"#f9c7a0"};
    padding: 2px 6px;
    border-radius: 3px;
    color: ${props => props.text === 'Online Payment'?"#000":"#000"};
`;

const DashboardCardHolder = styled.div`
    padding:20px 20px 0px 20px;
`;

const DashboardCard = styled.div`
    padding: 12px 16px;
    border: 1px solid ${Colors.inputBorderColor};
    border-radius: 5px;
    margin-bottom: 10px;
    p{
        margin-bottom: 0;
    }

    h6{
        margin-bottom: 0;
    }
`;

const Row5 = styled(Row)`
    margin-left: -5px;
    margin-right: -5px;
`;
const Col5 = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
`;
const TableHeaderLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding: 3px 15px;

    a{
        display:flex;
        background:${Colors.themeColorPrimary};
        border:none;
        color:${Colors.whiteColor};
        padding:5px 20px;
        border-radius:4px;
        font-size:13px;
        transition:all .5s;
        align-items:center;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
            transition:all .5s;
        }

        svg{
            width:16px;
            flex-shrink:0;
            margin-right:5px;
        }
    }
`;

const OrderLink = styled.a`
    color:#0000EE;

    &:hover{
        text-decoration: underline;
    }
`;