import { FaRegImage } from "react-icons/fa";
import styled from "styled-components";
import { Colors } from "../styles/colors";

const AddImageBtnWrapper = styled.div`
    border: 2px dashed #dddddd;
    margin-bottom: 0px;
    border-radius:4px;
    width:100%;
    overflow: hidden;
    
    &:hover{
        background:none;
    }

    button{
        width:100%;
        height:${props => props.layoutHeight ? props.layoutHeight: "250px"};
        background:${Colors.addImageBackgroundColor};
        padding: 20px;
        border:none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color:${Colors.linkTextColor};
        margin:0;

        &:hover{
            background:${Colors.addImageBackgroundHoverColor};
        }
    }

    button svg{
        font-size:40px;
        color: ${Colors.themeColorPrimary+"66"};
        margin-bottom:5px;
    }
    button span{
        font-size:14px;
        margin-bottom:0;
    }
`;

const ImageButtonWrapper = styled.div`

`;

const AddImageButton = ({ showImageLibrary, text, layoutHeight }) => {
    return <ImageButtonWrapper>
        <AddImageBtnWrapper layoutHeight = {layoutHeight}>
            <button onClick={showImageLibrary}>
                <FaRegImage />
                <span>{text}</span>
            </button>
        </AddImageBtnWrapper>
    </ImageButtonWrapper>
}
export default AddImageButton;