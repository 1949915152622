import { Col, Row } from "react-bootstrap";
import RadioButton from "../../components/input/radioButton"
import { ButtonSecondary, FieldGroupWrapper, FormWrapper } from "../../components/styles/addEditFormStyles"
import { useEffect, useState } from "react";
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from "../../utils/utilFunctions";
import { UPDATE_MERCHANT_TAX_DETAILS } from "../../utils/apiEndPoints";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import SnackBar from "../../components/randomComponents/snackBar";

const GstComp = (props) => {
    const { details } = props;
    const { user, logout } = useAuth();

    const [state, setState] = useState({
        isTaxExclusive: "",
        merchantTaxId: "",
        merchantTaxPercentage: "",
        apiErr: ""
    })

    useEffect(() => {
        setState({
            isTaxExclusive: details.isTaxExclusive,
            merchantTaxId: details.merchantTaxId,
            merchantTaxPercentage: details.merchantTaxPercentage,
            apiErr: ""
        })
    }, [])

    const hasOptionsHandleChange = (e) => {
        setState({
            ...state,
            isTaxExclusive: e.target.name === "exclusiveOfTax" ? "Y" : "N"
        })
    }

    const inputChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleDecimalInputChange = (e) => {
        const inputValue = e.target.value;
        // Use regular expression to allow only numbers and dots
        const newValue = inputValue.replace(/[^0-9.]/g, '');

        setState({
            ...state,
            [e.target.name]: newValue
        })
    };


    const closeSnackbar = () => {
        setState({
            ...state,
            showSnackbar: false,
            snackbarMessage: ""
        })
    }

    const onClickSave = async () => {
        let hasErrors = false;
        let errors = {}

        if (isEmptyVariable(state.merchantTaxId)) {
            hasErrors = true;
            errors.merchantTaxId = "Please enter the GST Number"
        }

        if (isEmptyVariable(state.merchantTaxPercentage) || state.merchantTaxPercentage + "" === "0.00") {
            hasErrors = true;
            errors.merchantTaxPercentage = "Please enter the GST Percentage"
        }

        if (!hasErrors) {
            try {
                await callAuthApi(UPDATE_MERCHANT_TAX_DETAILS, {
                    isTaxExclusive: state.isTaxExclusive,
                    merchantTaxPercentage: state.merchantTaxPercentage,
                }, user, logout)

                setState({
                    ...state,
                    showSnackbar: true,
                    snackbarMessage: "GST updated successfully",
                    timeStamp: moment().valueOf()
                })
            } catch (alertErr) {
                setState({
                    ...state,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })
            }
        } else {
            setState({
                ...state,
                errors: { ...errors }
            })
        }
    }

    return <FormWrapper>
        <FieldGroupWrapper>
            <h6>GST</h6>
            <Row>
                <Col md={2}>
                    <label>All product prices are</label>
                    <div className="m-t-10" />
                    <RadioButton
                        radioButtonId={"inclusiveOfTax"}
                        radioButtonLabel={"Inclusive of tax"}
                        handleChange={hasOptionsHandleChange}
                        name="inclusiveOfTax"
                        checked={state.isTaxExclusive === "N"}
                    />
                </Col>
                <Col md={2}>
                    <label>&nbsp;</label>
                    <div className="m-t-10" />
                    <RadioButton
                        radioButtonId={"exclusiveOfTax"}
                        radioButtonLabel={"Exclusive of tax"}
                        handleChange={hasOptionsHandleChange}
                        name="exclusiveOfTax"
                        checked={state.isTaxExclusive === "Y"}
                    />
                </Col>
            </Row>
            <div className="m-b-20" />
            <Row>
                <Col md="6">
                    <label>GST Number*</label>
                    <input
                        placeholder={"Enter GST Number"}
                        value={state.merchantTaxId}
                        name="merchantTaxId"
                        onChange={inputChange}
                        className="m-b-20 input-disabled"
                        disabled
                    />
                    {
                        !isEmptyVariable(state.errors?.merchantTaxId) &&
                        <span className="err-span m-b-10">{state.errors.merchantTaxId}</span>
                    }
                </Col>
                <Col md="6">
                    <label>GST Percentage*</label>
                    <input
                        placeholder={"Enter GST Percentage"}
                        value={state.merchantTaxPercentage}
                        name="merchantTaxPercentage"
                        onChange={handleDecimalInputChange}
                        className="m-b-20"
                    />
                    {
                        !isEmptyVariable(state.errors?.merchantTaxPercentage) &&
                        <span className="err-span m-b-10">{state.errors.merchantTaxPercentage}</span>
                    }
                </Col>
            </Row>
            {
                !isEmptyVariable(state.apiErr) &&
                <span className="err-span m-b-10">{state.apiErr}</span>
            }

            <ButtonSecondary onClick={onClickSave}>Save</ButtonSecondary>
        </FieldGroupWrapper>
        {
            state.showSnackbar &&
            <SnackBar
                showSnackbar={state.showSnackbar}
                message={state.snackbarMessage}
                timeStamp={state.timeStamp}
                closeSnackbar={closeSnackbar}
            />
        }
    </FormWrapper>
}

export default GstComp;