import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { DELETE_PRODUCT_UDF, GET_PRODUCT_UDFS, UPDATE_PRODUCT_UDF_STATUS } from "../../utils/apiEndPoints";
import {
    ACTIVATE_ACTION,
    DEACTIVATE_ACTION,
    DELETE_ACTION,
    EDIT_ACTION,
    RESULT_SIZE_ARRAY
} from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateUdf from "./createUpdateProductUdf";
import { GetReducer } from "./reducer";

const ProductUdfList = () => {

    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [udfState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "customfields", heading: "Custom Fields" })
        getUdfList();
    }, []);

    useEffect(() => {
        if (udfState.refreshFlag) {
            getUdfList();
        }

    }, [udfState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.productUdfId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.productUdfId, "Active");
                break;
            case "Delete":
                await deleteUdf(selectedItem.productUdfId);
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateUdfDialog = () => {
        dispatch({
            type: "SHOW_CREATE_UDF_DIALOG"
        });
    }

    const onClickUdfDialogCancel = () => {
        dispatch({
            type: "RESET_UDF_DIALOG"
        });
    }

    const onClickUdfDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_UDF_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_UDF_DIALOG",
                    createUdfDialog: {
                        showDialog: true,
                        isEdit: true,
                        udfObj: {
                            productUdfName: item.productUdfName,
                            productUdfType: item.productUdfType,
                            productUdfId: item.productUdfId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Custom Field",
                        message: "Are you sure you want to deactivate " + item.productUdfName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Custom Field",
                        message: "Are you sure you want to activate " + item.productUdfName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Delete":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Delete Custom Field",
                        message: "Are you sure you want to delete " + item.productUdfName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Delete",
                        operation: "Delete",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (udfState.search != udfState.searchApiKey) {
            dispatch({ type: "RELOAD_UDF_LIST" })
        }
    }

    const onClickResultSize = (resultSize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultSize: resultSize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, udfState.sort, udfState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getUdfList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: udfState.search,
            sort: udfState.sort,
            sortDir: udfState.sortDir,
            resultSize: udfState.resultSize,
            pageNo: isEmptyVariable(pageNo) ? udfState.pageNo : pageNo
        }

        if (udfState.status !== "All") {
            post.status = udfState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_PRODUCT_UDFS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (productUdfId, status) => {
        try {
            await callAuthApi(UPDATE_PRODUCT_UDF_STATUS, {
                productUdfId: productUdfId,
                productUdfStatus: status
            }, user, logout);

            getUdfList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const deleteUdf = async (productUdfId) => {
        try {
            await callAuthApi(DELETE_PRODUCT_UDF, {
                productUdfId: productUdfId,
            }, user, logout);

            getUdfList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION, { ...DELETE_ACTION, borderTop: "1px solid " + Colors.tableDropdownTopBorder, textColor: Colors.tableDropdownDeleteTextColor }];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type: "SET_STATUS_FILTER",
            status: item.status
        })
    }
    return <ContentSection>
        {
            udfState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <ResultSizeDropdown
                        dropdownLabel={udfState.resultSize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search custom fields..."
                            value={udfState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={udfState.status}
                        label="status"
                        options={[{ status: "All" }, { status: "Active" }, { status: "Inactive" }]}
                        dropdownItemClick={statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateUdfDialog}><BsFillPlusCircleFill />Add Custom Field</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            udfState.firstLoad &&
            <>
                {
                    !isEmptyArray(udfState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                
                                <SortableTableHeader
                                    tableHeader={"Custom Field Name"}
                                    sort={udfState.sort}
                                    sortDir={udfState.sortDir}
                                    tableSortCol={"pu.productUdfName"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Custom Field Type"}
                                    sort={udfState.sort}
                                    sortDir={udfState.sortDir}
                                    tableSortCol={"pu.productUdfType"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Products"}
                                    sort={udfState.sort}
                                    sortDir={udfState.sortDir}
                                    tableSortCol={"pucv.productCount"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={udfState.sort}
                                    sortDir={udfState.sortDir}
                                    tableSortCol={"pu.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={udfState.sort}
                                    sortDir={udfState.sortDir}
                                    tableSortCol={"pu.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th width="60px"></th>
                            </tr>

                            {
                                udfState.response.result.map((item, idx) => {
                                    return <tr>
                                        <td>
                                            {item.productUdfName}
                                            <StatusSpan
                                                status={item.productUdfStatus} />
                                        </td>
                                        <td>{item.productUdfType}</td>
                                        <td>{item.productCount}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.productUdfStatus)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getUdfList(e.selected + 1)}
                            pageRangeDisplayed={udfState.resultSize}
                            pageCount={getPageCount(udfState.response.count, udfState.resultSize)}
                            renderOnZeroPageCount={null}
                            forcePage={udfState.pageNo - 1}
                            totalRowCount = {udfState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(udfState.response.result) &&
                    !udfState.isPageLoading &&
                    !isEmptyVariable(udfState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(udfState.response.result) &&
                    !udfState.isPageLoading &&
                    isEmptyVariable(udfState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...udfState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />
        {
            udfState.createUdfDialog?.showDialog &&
            <CreateUpdateUdf
                productUdfDialog={{
                    ...udfState.createUdfDialog,
                }}
                onClickCancel={onClickUdfDialogCancel}
                onClickSubmit={onClickUdfDialogSubmit}
            />
        }

    </ContentSection>
}

export default ProductUdfList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    width:100%;
    padding:20px 20px;
`;