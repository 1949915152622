import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/useAuth';
import { ADD_TAG, UPDATE_TAG } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from '../../utils/utilFunctions';
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';

const initialState = {
    productTagName: "",
    errors: {
        productTagName: "",
    },
    apiErr: "",
}

const CreateUpdateTag = (props) => {
    const { productTagDialog, onClickSubmit, onClickCancel } = props;

    const [dialogState, setDialogState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (productTagDialog.showDialog) {
            if (productTagDialog.isEdit) {
                setDialogState(productTagDialog.tagObj);
            } else {
                setDialogState(initialState);
            }
            inputRef.current.focus();
        }
    }, [productTagDialog.showDialog])

    const inputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(dialogState.productTagName)) {
            hasErrors = true;
            errors.productTagName = "Please enter the tag name"
        }

        setDialogState({
            ...dialogState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = async () => {
        if (!validateForm()) {
            try {
                if (productTagDialog.isEdit) {
                    await updateProductTag();
                } else {
                    await createNewProductTag();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setDialogState({
                    ...dialogState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alertErr.heading)
                })

                setTimeout(() => {
                    setDialogState({
                        ...dialogState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setDialogState({
                    ...dialogState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createNewProductTag = async () => {
        await callAuthApi(ADD_TAG, {
            productTagName: dialogState.productTagName,
        }, user, logout)
    }

    const updateProductTag = async () => {
        await callAuthApi(UPDATE_TAG, {
            productTagId: productTagDialog.tagObj.productTagId,
            productTagName: dialogState.productTagName,
        }, user, logout);
    }

    return <>
        <CustomModal show={productTagDialog.showDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{productTagDialog.isEdit ? "Update Tag" : "Create Tag"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <input
                            placeholder={productTagDialog.isEdit ? "Update tag Name" : "Enter tag Name"}
                            value={dialogState.productTagName}
                            name="productTagName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.productTagName) &&
                            <span className="err-span m-b-10">{dialogState.errors.productTagName}</span>
                        }
                    </Col>
                </Row>

                {
                    !isEmptyVariable(dialogState.apiErr) &&
                    <span className="err-span m-b-10">{dialogState.apiErr}</span>
                }
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>

    </>
}

export default CreateUpdateTag;